import { gql } from "@apollo/client"
import { RESPONSE_SET_FIELDS } from "../../processes/fragments/responseSetFragment"
import { COMPACT_RESPONSE_SELECTION_OPTION_FIELDS } from "../../processes/fragments/compactResponseSelectionFields"
import { RESPONSE_TEMPERATURE_TIMER_FIELDS } from "../../processes/fragments/responseTemperatureTimerFragment"

const JOB_STEP_FIELDS = gql`
  fragment JobStepFields on JobProcessStep {
    id
    step
    number
    section
    stepCount
    name
    job {
      id
      process
      title
      percentComplete
      completed @client
      totalSteps
      completedSteps
      score
      possibleScore
      notes
      unresolvedActions
    }
    score
    possibleScore
    failed
    description
    responseType
    responseMandatory
    responseSet {
      ...ResponseSetFields
    }
    response
    responseUploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    responseSensors
    responseTemperatureTimer {
      ...ResponseTemperatureTimerFields
    }
    startedAt
    stoppedAt
    compactResponseSelections {
      ...CompactResponseSelectionFields
    }
    responseCustomSelections
    skip
    unresolvedActions
    hasResolvedActions
    completed @client
    completedAt
    completedBy {
      id
      status
      fullName
      firstName
      lastName
      avatar {
        key
      }
      groups {
        id
        name
      }
      locations {
        id
        name
      }
      activeAt
    }
    uploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    format {
      unit
      decimals
    }
    canEnterManually
    canUseDevice
    logic {
      range
      lowerValue
      upperValue
      outcomes {
        type
      }
    }
    selection {
      selectFrom
      allowMultiple
      customItems
    }
    notes {
      id
      text
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      createdAt
      updatedAt
      edited @client
    }
  }
  ${RESPONSE_SET_FIELDS}
  ${COMPACT_RESPONSE_SELECTION_OPTION_FIELDS}
  ${RESPONSE_TEMPERATURE_TIMER_FIELDS}
`

export { JOB_STEP_FIELDS }
