import { useMutation, gql } from "@apollo/client"
import { TIMER_FIELDS } from "./fragments/timerFieldsFragment"

const START_TIMER_MUTATION = gql`
  mutation StartTimer($input: TimerMutationInput!) {
    startTimer(input: $input) {
      ...TimerFields
    }
  }
  ${TIMER_FIELDS}
`

const useMutationStartTimer = () => useMutation(START_TIMER_MUTATION)

export { useMutationStartTimer }
