import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1516_2977)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.00002 27.9691C3.69902 27.9691 3.39502 27.9011 3.11102 27.7601C2.42502 27.4201 1.99902 26.7351 1.99902 25.9691V6.00007C1.99902 5.23407 2.42502 4.54907 3.11102 4.20907C3.79802 3.86807 4.60002 3.94707 5.20902 4.41107L18.313 14.3941C18.813 14.7761 19.101 15.3561 19.101 15.9861C19.101 16.6151 18.813 17.1941 18.312 17.5741L5.20802 27.5581C4.85202 27.8291 4.42802 27.9691 4.00002 27.9691ZM3.99702 6.00207L4.00002 25.9761L17.102 15.9831L3.99702 6.00207Z"
      fill={primary}
    />
    <path
      d="M29 20.9851H21C20.448 20.9851 20 20.5381 20 19.9851C20 19.4321 20.448 18.9851 21 18.9851H29C29.553 18.9851 30 19.4321 30 19.9851C30 20.5381 29.553 20.9851 29 20.9851Z"
      fill={primary}
    />
    <path
      d="M29 12.9851H21C20.448 12.9851 20 12.5381 20 11.9851C20 11.4321 20.448 10.9851 21 10.9851H29C29.553 10.9851 30 11.4321 30 11.9851C30 12.5381 29.553 12.9851 29 12.9851Z"
      fill={primary}
    />
    <path
      d="M29 16.9849H26C25.447 16.9849 25 16.5379 25 15.9849C25 15.4319 25.447 14.9849 26 14.9849H29C29.553 14.9849 30 15.4319 30 15.9849C30 16.5379 29.553 16.9849 29 16.9849Z"
      fill={secondary}
    />
    <path
      d="M29 8.98486H15C14.448 8.98486 14 8.53786 14 7.98486C14 7.43186 14.448 6.98486 15 6.98486H29C29.553 6.98486 30 7.43186 30 7.98486C30 8.53786 29.553 8.98486 29 8.98486Z"
      fill={secondary}
    />
    <path
      d="M29 24.9849H15C14.448 24.9849 14 24.5379 14 23.9849C14 23.4319 14.448 22.9849 15 22.9849H29C29.553 22.9849 30 23.4319 30 23.9849C30 24.5379 29.553 24.9849 29 24.9849Z"
      fill={secondary}
    />
    <defs>
      <linearGradient
        id="paint0_linear_1516_2977"
        x1="30"
        y1="24.9849"
        x2="14"
        y2="24.9849"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
