import { gql } from "@apollo/client"
import { PUBLIC_JOB_SCHEDULE_FIELDS } from "./publicJobScheduleFieldsFragment"
import { RESPONSE_SET_FIELDS } from "../../processes/fragments/responseSetFragment"

const PUBLIC_JOB_FIELDS = gql`
  fragment PublicJobFields on PublicJob {
    id
    type
    name
    steps {
      ... on CommonProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
        format {
          unit
          decimals
        }
        logic {
          range
          lowerValue
          upperValue
          outcomes {
            type
          }
        }
        selection {
          selectFrom
          allowMultiple
          customItems
        }
      }
      ... on NumberProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
        format {
          unit
          decimals
        }
        logic {
          range
          lowerValue
          upperValue
          outcomes {
            type
          }
        }
        canEnterManually
        canUseDevice
      }
      ... on MultipleChoiceProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        responseSet {
          ...ResponseSetFields
        }
        scoring {
          response
          score
          noScore
          failure
        }
        canFail
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        hasScoring
        hasExclusions
        exclusions {
          id
        }
      }
      ... on ProcessProcessStep {
        id
        name
        description
        responseType
        responseMandatory
        process {
          id
          name
          stepCount
          createdAt
          updatedAt
        }
        canFail
        hasScoring
        hasExclusions
        exclusions {
          id
        }
      }
    }
    stepCount
    schedule {
      ...PublicJobScheduleFields
    }
    organisation {
      id
      name
      logo {
        key
      }
    }
    createdAt
    updatedAt
  }
  ${RESPONSE_SET_FIELDS}
  ${PUBLIC_JOB_SCHEDULE_FIELDS}
`

export { PUBLIC_JOB_FIELDS }
