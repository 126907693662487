import React from "react"
import { SystemUpdateAlt as SystemUpdateAltIcon } from "@material-ui/icons"
import Config from "react-global-configuration"
import { DEVICE_PLATFORM_OS, fetchClientVersions } from "../../data"
import { useSnackbar } from "../SnackbarProvider"
import { useDeviceUtils, useLocalStorage, useMountEffect } from "../../utils"
import { WebVersion } from "../../services"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"

const handleVersionCheck = (options) => {
  const { intervalMs, onNewVersionAvailable } = options
  console.log("[APP]", "Running version check")
  fetchClientVersions().then((result) => {
    // run web client version check
    const newVersionAvailable = result.webVersion !== WebVersion
    if (newVersionAvailable) {
      console.log("[APP]", `New version available ${result.webVersion}`)
      onNewVersionAvailable(result)
    }

    // run device client version check
    options?.onDeviceClientVersionCheck?.(result)

    setTimeout(() => handleVersionCheck(options), intervalMs)
  })
}

const handleNewVersionReloading = (snackbar) => {
  console.log("[APP]", "New version, reloading")
  snackbar.showMessage({
    message: "A new version is available, downloading.",
    icon: <SystemUpdateAltIcon />,
    chipProps: { style: { cursor: "pointer", backgroundColor: "#fd9317" } },
    keepOpen: true,
  })
  setTimeout(() => window.location.reload(true), 300)
}

const handleNewVersionAvailable = (snackbar) => {
  snackbar.showMessage({
    message: "A new version is available. Click here to reload.",
    icon: <SystemUpdateAltIcon />,
    onClick: () => window.location.reload(true),
    chipProps: { style: { cursor: "pointer", backgroundColor: "#fd9317" } },
    keepOpen: true,
  })
}

const useVersionCheck = () => {
  const snackbar = useSnackbar()
  const { isLessThanVersion, isOS } = useDeviceUtils()
  const [knownLatestVersion, setKnownLatestVersion, , { checkAvailable }] = useLocalStorage("vc")

  useMountEffect(() => {
    let timeout
    const {
      check: { enabled, intervalMs },
    } = Config.get("version")
    if (enabled) {
      const handleDeviceClientVersionCheck = (result) => {
        if (!isOS([DEVICE_PLATFORM_OS.IOS, DEVICE_PLATFORM_OS.ANDROID])) {
          return
        }

        console.log("[APP]", "Running device client version check")
        const latestDeviceClientVersion = isOS(DEVICE_PLATFORM_OS.IOS) ? result.iosVersion : result.androidVersion
        newDeviceClientAvailableVar(isLessThanVersion(latestDeviceClientVersion))
      }

      const run = async () => {
        console.log("[APP]", "Running first load version check", new Date())

        const result = await fetchClientVersions()

        // ///////////////////////////////////////////////////
        // Web client version & ongoing version check logic
        // ///////////////////////////////////////////////////

        const isLocalStorageAvailable = await checkAvailable()

        // handle backwards compatibility with old versioning
        const knownLatestWebVersion = knownLatestVersion?.latestVersion || knownLatestVersion?.webVersion

        // only attempt opportunistic reload if we have
        // local storage available and a known latest version
        if (
          isLocalStorageAvailable &&
          result.webVersion !== WebVersion &&
          knownLatestWebVersion &&
          knownLatestWebVersion !== result.webVersion
        ) {
          await setKnownLatestVersion(result)
          handleNewVersionReloading(snackbar)
        } else {
          timeout = setTimeout(
            () =>
              handleVersionCheck({
                intervalMs,
                onDeviceClientVersionCheck: handleDeviceClientVersionCheck,
                onNewVersionAvailable: async (polledResult) => {
                  if (knownLatestWebVersion !== result.webVersion) {
                    await setKnownLatestVersion(polledResult)
                  }
                  handleNewVersionAvailable(snackbar)
                },
              }),
            intervalMs,
          ) // start further version checks
        }

        // ///////////////////////////////////////////////////
        // Device client version logic
        // ///////////////////////////////////////////////////

        handleDeviceClientVersionCheck(result)
      }
      run()
    }
    return () => clearTimeout(timeout)
  })
}

export { useVersionCheck }
