import React, { useState } from "react"
import { t, Trans } from "@lingui/macro"
import * as Sentry from "@sentry/browser"
import { useMutationCreateTemporaryTabletPin, USER_STATUS } from "../../data"
import { LinkButton } from "../LinkButton"
import { toId } from "../../utils"
import { RowBox } from "../Boxes"
import { useAuth } from "../../services"
import { LoadingSpinner } from "../LoadingSpinner"
import { useSnackbar } from "../SnackbarProvider"
import { Icon } from "../Icon"

const CreateTemporaryPinLinkButton = ({ user, onConfirming, onConfirmed, onCancel, onError }) => {
  const { hasFeature } = useAuth()
  const [createTemporaryTabletPin, { data, loading }] = useMutationCreateTemporaryTabletPin()
  const [confirming, setConfirming] = useState(false)
  const snackbar = useSnackbar()

  const hasUserPinVisible = hasFeature("user_pin_visible")

  const handleClick = async () => {
    if (!confirming) {
      setConfirming(true)
      onConfirming && onConfirming()
      return
    }

    try {
      const result = await createTemporaryTabletPin({ variables: { id: toId(user) } })
      if (result.errors) {
        onError && onError()
        return
      }

      onConfirmed && onConfirmed()
      snackbar.showMessage({ message: t`Temporary PIN sent to ${user.email}`, icon: <Icon name="email" /> })
    } catch (error) {
      Sentry.captureException(error)
      onError && onError()
      snackbar.showMessage({ message: t`Failed to create temporary tablet PIN`, icon: <Icon name="error" /> })
    } finally {
      setConfirming(false)
    }
  }

  const handleCancel = () => {
    setConfirming(false)
    onCancel && onCancel()
  }

  if (!user || (user && user.status !== USER_STATUS.ACTIVE)) {
    return null
  }

  const temporaryPin = hasUserPinVisible && data?.adminableUser?.createTemporaryTabletPin

  return (
    <RowBox gap={1} alignItems="center">
      {loading && <LoadingSpinner size={20} delay={false} />}
      {!loading && (
        <>
          {temporaryPin && (
            <>
              <Trans>Temporary PIN is</Trans> {temporaryPin}
            </>
          )}
          <LinkButton onClick={handleClick}>
            <Trans>Create temporary PIN</Trans>
          </LinkButton>
          {confirming && (
            <LinkButton color="secondary" onClick={handleCancel}>
              <Trans>Cancel</Trans>
            </LinkButton>
          )}
        </>
      )}
    </RowBox>
  )
}

export { CreateTemporaryPinLinkButton }
