import { gql } from "@apollo/client"

const PUBLIC_JOB_SCHEDULE_FIELDS = gql`
  fragment PublicJobScheduleFields on PublicSchedule {
    id
    name
    locations {
      id
      name
      logo {
        key
      }
    }
  }
`

export { PUBLIC_JOB_SCHEDULE_FIELDS }
