import React, { useState } from "react"
import {
  Button,
  Menu,
  makeStyles,
  MenuItem,
  ListItemIcon,
  Box,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { Icon, RequirePermissions } from ".."
import { useAuth } from "../../services"
import { useQueryLabelTemplates } from "../../data"

const useStyles = makeStyles((theme) => ({
  menu: {
    marginTop: 50,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
  },
}))

const DesktopCreateLabelsButton = ({ onCreateClick }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { hasFeature, permissionGroups } = useAuth()
  const [showCreateOptions, setShowCreateOptions] = useState(false)
  const [showCreateOptionsEl, setShowCreateOptionsEl] = useState(null)
  const sm = useMediaQuery(theme.breakpoints.down("sm"))

  const { data } = useQueryLabelTemplates()

  const handleCreateClick = (type) => {
    setShowCreateOptions(false)
    setShowCreateOptionsEl(null)
    onCreateClick && onCreateClick(type)
  }

  const handleToggleCreateMenu = ({ currentTarget }) => {
    setShowCreateOptionsEl(!showCreateOptions ? currentTarget : null)
    setShowCreateOptions(!showCreateOptions)
  }

  const hasLabels = hasFeature("labels")

  const MenuItemCreateNewLabel = ({ disabled = false }) => {
    return (
      <MenuItem disabled={disabled} onClick={() => handleCreateClick("label")} data-cy="MenuItem-create-label">
        <ListItemIcon className={classes.menuListItemIcon}>
          <Icon name="labels-item" />
        </ListItemIcon>
        <Trans>Create a new label</Trans>
      </MenuItem>
    )
  }
  const MenuItemCreateNewTemplate = ({ disabled = false }) => {
    return (
      <MenuItem disabled={disabled} onClick={() => handleCreateClick("template")} data-cy="MenuItem-create-template">
        <ListItemIcon className={classes.menuListItemIcon}>
          <Icon name="labels-template" />
        </ListItemIcon>
        <Trans>Create a new label template</Trans>
      </MenuItem>
    )
  }

  return (
    <RequirePermissions operator="or" requires={permissionGroups.createLabels}>
      {hasLabels && (
        <Box display="flex" flexDirection="row">
          <Button onClick={handleToggleCreateMenu} variant="contained" color="primary" data-cy="Button-create-label">
            <Trans>Create</Trans>
          </Button>
          <Menu
            anchorEl={showCreateOptionsEl}
            keepMounted
            open={showCreateOptions}
            onClose={handleToggleCreateMenu}
            className={classes.menu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {!data?.labelTemplates.length ? (
              <Tooltip
                title={t`Please create a label template first`}
                enterDelay={500}
                enterNextDelay={500}
                enterTouchDelay={0}
              >
                <div>
                  <MenuItemCreateNewLabel disabled />
                </div>
              </Tooltip>
            ) : (
              <div>
                <MenuItemCreateNewLabel />
              </div>
            )}

            <RequirePermissions requires={permissionGroups.createLabelTemplates}>
              {sm ? (
                <Tooltip
                  title={t`Please create label templates on a larger screen`}
                  enterDelay={500}
                  enterNextDelay={500}
                  enterTouchDelay={0}
                >
                  <div>
                    <MenuItemCreateNewTemplate disabled />
                  </div>
                </Tooltip>
              ) : (
                <div>
                  <MenuItemCreateNewTemplate />
                </div>
              )}
            </RequirePermissions>
          </Menu>
        </Box>
      )}
    </RequirePermissions>
  )
}

export { DesktopCreateLabelsButton }
