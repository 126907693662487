import React, { useState, useEffect, useMemo } from "react"
import { Popover, Divider, Box, List, Collapse, ClickAwayListener } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { NavLink } from "react-router-dom"
import { Trans } from "@lingui/macro"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { useQuerySettings, useLazyQuerySearch, useMutationAddUserSearch } from "../../data"
import { SearchInput } from ".."
import SearchAreaResultList from "./SearchAreaResultList"
import { SearchListItem } from "./SearchListItem"
import { toId } from "../../utils"
import { RequirePermissions } from "../Permissions"
import CategoryLabelList from "./CategoryLabelList"
import { SEARCH_CATEGORY, SEARCH_CATEGORY_LABELS, SEARCH_RESULT } from "../../data/enums"
import { useSearchState } from "../../data/search/searchStateVar"

const categoryOptions = Object.keys(SEARCH_CATEGORY).map((key) => ({
  id: SEARCH_CATEGORY[key],
  label: SEARCH_CATEGORY_LABELS[SEARCH_CATEGORY[key]],
}))

const usePopoverStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, .6)",
    transition: "background-color 0.2s",
  },
  paper: {
    maxWidth: "calc(100vw - 32px)",
    minWidth: ({ isMobile }) => (isMobile ? "auto" : "max-content"),
    width: ({ isMobile }) => (isMobile ? "100%" : "auto"),
    marginTop: ({ isTablet }) => (isTablet ? theme.spacing(1) : theme.spacing(-1)),
    position: ({ isTablet }) => (isTablet ? "fixed" : "absolute"),
    left: ({ isTablet }) => (isTablet ? "50%" : "auto"),
    transform: ({ isTablet }) => (isTablet ? "translate(-50%, 0%) !important" : "none"),
  },
}))

const useStyles = makeStyles((theme) => ({
  container: {
    width: ({ isMobile }) => (isMobile ? "100%" : 600),
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  form: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 4,
    minHeight: 40,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  input: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.primary,
    display: "flex",
    justifySelf: "stretch",
  },
  type: {
    padding: theme.spacing(2),
  },
}))

const defaultActiveFilter = "everything"

const SearchPopover = ({ open, anchorEl, onSearchChange, isTablet, isMobile }) => {
  const classes = useStyles({ isTablet, isMobile })
  const {
    location,
    settings: { searches },
  } = useAuth()
  const { clientKiosk: kiosk } = Config.get()
  const { searchText, setSearchTextImmediate, clearAll } = useSearchState()
  const [load, { called, loading, data }] = useLazyQuerySearch({
    fetchPolicy: "no-cache",
  })

  const [addUserSearch] = useMutationAddUserSearch()
  const popoverClasses = usePopoverStyles({ isTablet, isMobile })
  const settings = useQuerySettings()
  const [globalArea, setGlobalArea] = useState(null)
  const [more, setMore] = useState(null)

  const [activeFilter, setActiveFilter] = useState(defaultActiveFilter)

  useEffect(() => {
    if (searchText.trim()) {
      load({
        variables: { searchText, location: toId(location), limit: 9, filter: { client: kiosk ? "kiosk" : "" } },
      })
    }
  }, [searchText, location, load, kiosk])

  useEffect(() => {
    if (globalArea && more !== globalArea) {
      setMore(globalArea)
    }
    if (globalArea) setGlobalArea("")
  }, [globalArea, setGlobalArea, more, setMore])

  const id = open ? "search-popover" : ""

  const searchResults = useMemo(
    () => ({
      jobs: (data && data.searchAreas.jobs) || {},
      knowledge: (data && data.searchAreas.knowledge) || {},
      posts: (data && data.searchAreas.posts) || {},
      actions: (data && data.searchAreas.actions) || {},
    }),
    [data],
  )

  const handleMoreResultsClick = (area) => {
    if (area) setMore(more ? null : area)
    else setMore(area)
  }

  const handleSearchResultClick = () => {
    clearAll()
    if (
      !searches ||
      !searches.length ||
      !searches.find((search) => search.text.toLowerCase() === searchText.toLowerCase())
    ) {
      addUserSearch({ variables: { searchText } })
    }
  }

  const getLinkBase = (item) => {
    switch (item.type) {
      case SEARCH_RESULT.JOB:
      case SEARCH_RESULT.PROCESS:
        return `/jobs/${moment(item.date).format("YYYY-MM-DD")}/${item.type.toLowerCase()}/`
      case SEARCH_RESULT.KNOWLEDGE:
        return "/knowledge/article/"
      case SEARCH_RESULT.POST:
        return "/hub/post/"
      case SEARCH_RESULT.ACTION:
        return "/hub/action/"
      default:
        return "/"
    }
  }

  const BoundSearchAreaResultList = ({ area, icon, linkBase, results, requires, requiresOperator = "or", type }) => {
    if (more && more?.props?.id !== area?.props?.id) return null
    return (
      <RequirePermissions requires={requires} operator={requiresOperator}>
        <SearchAreaResultList
          area={area}
          linkBase={linkBase}
          searchText={searchText}
          onClick={handleSearchResultClick}
          isBusy={loading}
          results={results}
          icon={icon}
          onMoreResultsClick={handleMoreResultsClick}
          selected={more?.props?.id === area?.props?.id}
          type={type}
        />
        <Divider />
      </RequirePermissions>
    )
  }

  const handleRecentSearchClick = (text) => {
    setSearchTextImmediate(text)
    setActiveFilter(defaultActiveFilter)
  }

  const handleRedirectToFullSearch = () => {
    clearAll()
    setActiveFilter(defaultActiveFilter)
  }

  const handleFilterClick = (filterId) => {
    setMore(null)
    setActiveFilter(filterId)
  }

  const handleClose = () => {
    clearAll()
    setActiveFilter(defaultActiveFilter)
  }

  const filteredResults = useMemo(() => {
    const resultConfigs = {
      everything: {
        requires: [
          "jobprocess_read_self",
          "jobprocess_read_all",
          "category_read",
          "knowledge_read",
          "post_read",
          "action_read",
        ],
        requiresOperator: "or",
        area: () => settings.lang.area.topResults,
        linkBase: (item) => getLinkBase(item),
        results: (searchResult) => {
          const items = [
            ...(searchResult.jobs?.items || []),
            ...(searchResult.knowledge?.items || []),
            ...(searchResult.posts?.items || []),
            ...(searchResult.actions?.items || []),
          ].sort((a, b) => b.score - a.score)

          const total =
            (searchResult.jobs?.total || 0) +
            (searchResult.knowledge?.total || 0) +
            (searchResult.posts?.total || 0) +
            (searchResult.actions?.total || 0)

          return items.length ? { total, items } : { total: 0, items: [] }
        },
        type: "everything",
      },
      activeJobs: {
        requires: ["jobprocess_read_self", "jobprocess_read_all"],
        requiresOperator: "or",
        area: () => settings.lang.area.jobs,
        linkBase: (item) => getLinkBase(item),
        results: (searchResult) => searchResult.jobs,
        type: "job",
      },
      knowledgeBase: {
        requires: ["category_read", "knowledge_read"],
        requiresOperator: "and",
        area: () => settings.lang.area.knowledge,
        linkBase: (item) => getLinkBase(item),
        results: (searchResult) => searchResult.knowledge,
        type: "knowledgeBase",
      },
      commsHub: {
        requires: "post_read",
        area: () => settings.lang.area.hub,
        linkBase: (item) => getLinkBase(item),
        results: (searchResult) => searchResult.posts,
        type: "commsHub",
      },
      actions: {
        requires: "action_read",
        area: () => settings.lang.area.actions,
        linkBase: (item) => getLinkBase(item),
        results: (searchResult) => searchResult.actions,
        type: "actions",
      },
    }

    const config = resultConfigs[activeFilter]

    if (!config) {
      return null
    }

    return (
      <BoundSearchAreaResultList
        requires={config.requires}
        requiresOperator={config.requiresOperator}
        area={config.area(settings)}
        linkBase={config.linkBase}
        results={config.results(searchResults)}
        type={config.type}
      />
    )
  }, [activeFilter, searchResults, settings])

  const showResults = called && searchText

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      classes={popoverClasses}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchorReference={isTablet ? "none" : "anchorEl"}
      hideBackdrop
      BackdropProps={{
        classes: {
          root: popoverClasses.root,
        },
      }}
      data-cy="SearchPopover"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Box className={classes.container}>
          <SearchInput
            autoFocus
            size="small"
            value={searchText}
            onChange={onSearchChange}
            cy="SearchArea-SearchInput-input"
            boxProps={{ mb: 0, px: 2, pb: 0, pt: 2 }}
          />
          <Box>
            <Collapse in={Boolean(showResults)}>
              <CategoryLabelList
                activeFilter={activeFilter}
                onFilterClick={handleFilterClick}
                options={categoryOptions}
                disabled={!searchText}
              />
            </Collapse>
            {!showResults && searches && searches.length > 0 && (
              <Box className={classes.type}>
                <List disablePadding>
                  {searches.map(({ at, text }, index) => (
                    <SearchListItem
                      key={index}
                      date={at}
                      onClick={() => handleRecentSearchClick(text)}
                      style={{ padding: 8 }}
                    >
                      {text}
                    </SearchListItem>
                  ))}
                </List>
              </Box>
            )}
            {showResults && (
              <>
                {filteredResults}
                {searchText && (
                  <Box className={classes.type}>
                    <NavLink
                      to={`/search/${encodeURIComponent(searchText)}`}
                      onClick={handleRedirectToFullSearch}
                      data-cy="SearchPopover-all"
                    >
                      <Trans>
                        Search across all areas for <strong>"{searchText}"</strong>
                      </Trans>
                    </NavLink>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </ClickAwayListener>
    </Popover>
  )
}

export default SearchPopover
