import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router-dom"
import { Box, Grid, Hidden } from "@material-ui/core"
import { ActionCard } from "./ActionCard"
import { MentionOutput, Checkbox } from ".."
import { preventDefault, toId, useJobUtils } from "../../utils"
import {
  HumanTimeActionCardInfo,
  ProcessRepeatActionCardInfo,
  DueTimeActionCardInfo,
  AssigneesActionCardInfo,
} from "./ProcessActionCard"
import { useMakeJobStepOptimistic, useMutationJobStepComplete } from "../../data"
import { RowBox } from "../Boxes"
import { AuditStatusChip } from "../Chips/AuditStatusChip"

const useStyles = makeStyles(() => ({
  stub: {
    marginLeft: -10,
  },
  rightChildren: {
    height: 40,
    overflow: "visible",
  },
}))

const SingleStepProcessActionCard = (props) => {
  const { job, date, compact, navigable = true, onCantNavigate, showCheckbox = true } = props
  const {
    id,
    processName,
    availableFrom,
    availableUntil,
    adhoc,
    status: { completed, steps },
  } = job
  const history = useHistory()
  const classes = useStyles()
  const { getNavigateToLink, isJobAfterToday, isJobOverdue } = useJobUtils()
  const [completeJobStep] = useMutationJobStepComplete(job, job.status.steps[0])
  const { makeJobStepCompleteOptimisticResponse } = useMakeJobStepOptimistic()
  const initRef = useRef(false)
  const updateRef = useRef(false)
  const [complete, setComplete] = useState(false)

  const isScheduled = isJobAfterToday(availableFrom)
  const isOverdue = !complete && isJobOverdue(job)

  useEffect(() => {
    if (!initRef.current) {
      setComplete(completed)
      initRef.current = true
    }
  }, [completed])

  useEffect(() => {
    if (updateRef.current) {
      updateRef.current = false
    } else {
      setComplete(completed)
    }
  }, [completed])

  const handleCompletedChange = async (event) => {
    const state = !complete
    setComplete(state)
    preventDefault(event, async () => {
      const variables = {
        job: id,
        step: toId(job.status.steps[0]),
        skip: false,
        complete: state,
        response: "",
      }
      await completeJobStep({
        variables,
        optimisticResponse: makeJobStepCompleteOptimisticResponse({
          ...variables,
          job,
        }),
      })
    })
  }

  const handleNavigateToJob = () => {
    history.push(getNavigateToLink(job, date))
  }

  const handleCantNavigateClick = () => {
    onCantNavigate && onCantNavigate()
  }

  if (!steps.length) {
    return null
  }

  const stepName = steps[0].name
  const stepDescription = steps[0].description
  const stepSkipped = steps[0].skip

  return (
    <ActionCard
      title={`${processName}${stepName && stepName !== processName ? ` - ${stepName}` : ""}`}
      detail={stepDescription ? <MentionOutput content={stepDescription} /> : null}
      completed={completed}
      overdue={!complete && isOverdue}
      isScheduled={isScheduled}
      cardProps={navigable ? { onClick: handleNavigateToJob } : { onClick: handleCantNavigateClick }}
      stub={
        <>
          {showCheckbox && (
            <Grid item className={classes.stub}>
              <Checkbox
                type={stepSkipped ? "skipped" : "complete"}
                checked={complete}
                onClick={handleCompletedChange}
                disabled={isScheduled}
              />
            </Grid>
          )}
        </>
      }
      rightChildren={
        <RowBox mr="auto" alignSelf="center" alignItems="center" className={classes.rightChildren}>
          <Hidden xsDown>
            <Box>
              <AuditStatusChip job={job} />
            </Box>
          </Hidden>
        </RowBox>
      }
      footer={
        <>
          <RowBox>
            <ProcessRepeatActionCardInfo job={job} />
            <HumanTimeActionCardInfo
              adhoc={adhoc}
              date={availableFrom}
              availableUntil={availableUntil}
              tooltip="Start"
            />
            <DueTimeActionCardInfo job={job} />
          </RowBox>
          <RowBox ml="auto">
            <AssigneesActionCardInfo job={job} compact={compact} />
          </RowBox>
        </>
      }
    />
  )
}

export { SingleStepProcessActionCard }
