import React, { useMemo, useContext } from "react"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"
import { CssBaseline } from "@material-ui/core"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/styles"
import { hexToRgb, generateShade, getContrast } from "./utils/useThemingUtils"
import { ACTION_PRIORITY, TIMESHEET_STATUS } from "./data"
import { ThemeContext } from "./ThemeContext.js"

// eslint-disable-next-line no-unused-vars
const OverrideCssBaseline = ({ classes }) => null
OverrideCssBaseline.propTypes = {
  classes: PropTypes.object.isRequired,
}

const Theme = ({ children }) => {
  const { customPrimaryColor, customSecondaryColor } = useContext(ThemeContext)
  const hasCustomColors = customPrimaryColor || customSecondaryColor || false

  // Primary and secondary colors can be set by the user in their whitelabel settings. Otherwise they will default to Operandio's primary and secondary colors as shown below
  const primaryColor = customPrimaryColor || "#2092FF"
  const textColor = customPrimaryColor ? "#000000" : "#1A1B43"

  // Generate shades as required from either the user's whitelabel colors or the Operandio defaults
  const generatedTheme = useMemo(
    () => ({
      primary: {
        main: primaryColor,
        mainRgb: hexToRgb(primaryColor),
        light: generateShade(primaryColor, 400),
        dark: generateShade(primaryColor, 600),
        backgroundDark: generateShade(primaryColor, 80),
        backgroundMed: generateShade(primaryColor, 30),
        backgroundLight: generateShade(primaryColor, 15),
      },
      text: {
        primary: textColor,
        secondary: generateShade(textColor, 420),
        faint: generateShade(textColor, 300),
      },
    }),
    [primaryColor, textColor],
  )

  // Tokens should follow the MUI theme defaults where possible: https://v4.mui.com/customization/default-theme/#default-theme
  // The current secondary colour is a temporary error color for certain components (Chips and Buttons) that don't support an "error" prop in V4. It's a workaround until V5+ update at which point any components using it for error states should switch to the correct "error" colors below

  const mode = "light" // Dark mode toggle will be added in a future release

  const palette = useMemo(
    () => ({
      type: mode,
      background: {
        default: "#FFFFFF",
        dark: customPrimaryColor ? generateShade("#000000", 470) : "#1A1B43",
      },
      // Primary - used for UI elements and some panel/card styling
      primary: {
        main: generatedTheme.primary.main,
        mainRgb: generatedTheme.primary.mainRgb,
        light: generatedTheme.primary.light,
        dark: generatedTheme.primary.dark,
        backgroundLight: generatedTheme.primary.backgroundLight,
        backgroundMed: generatedTheme.primary.backgroundMed,
        backgroundDark: generatedTheme.primary.backgroundDark,
      },
      // Red (temporary secondary color). Avoid using this unless it's required for MUI V4 components that don't support "error" props
      secondary: {
        main: "#F63B3B",
        mainRgb: "246,59,59",
        light: "#FE6B6B",
        dark: "#E41E1E",
        background: "#FEE7E7",
      },
      // Purple (this is the secondary colour in MUI V5)
      tertiary: {
        main: "#9c27b0",
        mainRgb: "156,39,176",
        light: "#ba68c8",
        dark: "#7b1fa2",
        background: "#fbf7fc",
      },
      // Blue for things in progress, info, etc
      info: {
        main: "#2092ff",
        mainRgb: "32,146,255",
        light: "#28A8FF",
        dark: "#0A72EB",
        background: "#F5FAFF",
      },
      // Green for success states
      success: {
        main: "#18ce99",
        mainRgb: "24,206,153",
        light: "#72E3BB",
        dark: "#11B083",
        background: "#F6FEFC",
      },
      // Orange for important warnings
      warning: {
        main: "#ff9800",
        mainRgb: "255,152,0",
        light: "#ffb74d",
        dark: "#f57c00",
        background: "#FFF5E5",
      },
      // Red for error messages and failure
      error: {
        main: "#F63B3B",
        mainRgb: "246,59,59",
        light: "#FE6B6B",
        dark: "#E41E1E",
        background: "#FEE7E7",
      },
      // Grey tones used for borders, backgrounds etc.
      grey: {
        25: "#FCFCFC",
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
        A100: "#d5d5d5",
        A200: "#aaaaaa",
        A400: "#303030",
        A700: "#616161",
      },
      // Text
      text: {
        primary: generatedTheme.text.primary,
        secondary: generatedTheme.text.secondary,
        faint: generatedTheme.text.faint,
        readonly: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.75)`,
        disabled: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.38)`,
        white: "#ffffff",
        link: customPrimaryColor
          ? getContrast(generatedTheme.primary.main, generatedTheme.text.primary)
            ? generatedTheme.primary.main
            : "#0275E3"
          : generatedTheme.primary.main,
      },
      // Status colours for raised actions, reporting, etc
      status: {
        unresolved: "#f44336",
        resolved: "#18ce99",
        cancelled: "#757575",
        blocked: "#fe9316",
        in_progress: "#2092ff",
        todo: "#E0E0E0",
      },
      // Priority colours for raised actions
      priority: {
        [ACTION_PRIORITY.CRITICAL]: "#f44236",
        [ACTION_PRIORITY.HIGH]: "#f57c01",
        [ACTION_PRIORITY.NORMAL]: "#ffb74d",
        [ACTION_PRIORITY.LOW]: "#ffdfb2",
      },
      // Raised actions (main) and notes (secondary)
      raised: {
        main: "#f63b3b",
        secondary: "#fd9317",
      },
      // This is an MUI value used for hover, focus, selected, disabled states etc, NOT Operandio Actions
      action: {
        hover: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.04)`,
        focus: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.08)`,
        selected: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.08)`,
        disabled: `rgba(${hexToRgb(generatedTheme.text.primary)}, 0.26)`,
        focusOpacity: 0.08,
      },
      invited: {
        main: "#fd9317",
      },
      scheduled: {
        main: "#fd9317",
      },
      pending: {
        main: "#fbc02d",
      },
      quiz: {
        main: "#ffcc00",
      },
      featured: {
        main: "#ffcc00",
        dark: "#edbe02",
      },
      sensor: {
        humidity: "#fd9317",
        temperature: "#057DEF",
        probeTemperature: "#2EB4FF",
      },
      punchClock: {
        [TIMESHEET_STATUS.SCHEDULED]: {
          backgroundColor: "#f5fffc",
        },
        [TIMESHEET_STATUS.NOT_SCHEDULED]: {
          backgroundColor: "#f5fffc",
        },
        [TIMESHEET_STATUS.ACTIVE]: {
          backgroundColor: "#ffefdc",
        },
        [TIMESHEET_STATUS.PAUSED]: {
          backgroundColor: "#fafcff",
        },
        [TIMESHEET_STATUS.ENDED]: {
          backgroundColor: "#fafcff",
        },
      },
      buttons: {
        light: {
          background: "#f3f3f3",
          hover: "#EEEEEE",
        },
      },
      chart: {
        background: `rgba(32,146,255,.15)`,
      },
      gradientOrange: customPrimaryColor
        ? generatedTheme.primary.main
        : "linear-gradient(to right, #f63b3b, #fd9317) no-repeat",
    }),
    [generatedTheme, customPrimaryColor],
  )

  // A base instance of the theme used for referencing spacing, typography, etc.. in muiTheme
  const baseTheme = createTheme()

  // Shared card objects
  const cards = {
    areaCard: {
      color: hasCustomColors ? palette.text.primary : palette.text.primary,
      background: hasCustomColors ? palette.background.default : "linear-gradient(to right, #FFFFFF , #2092FF0D)",
      borderBottom: `1px solid ${palette.grey[300]}`,
    },
    hoverCard: {
      border: `1px solid ${palette.grey[300]}`,
      borderRadius: 8,
      boxShadow: `0px 6px 6px -2px rgba(${customPrimaryColor ? "0, 0, 0" : palette.primary.mainRgb}, 0.15)`,
    },
    navigationCard: {
      borderRadius: 8,
    },
    filterCard: {
      borderRadius: 8,
      background: palette.primary.backgroundLight,
      border: `1px solid rgba(${palette.primary.mainRgb},0.2)`,
    },
    viewerCard: {
      border: `1px solid rgba(${palette.primary.mainRgb},0.2)`,
      borderRadius: 8,
      background: palette.primary.backgroundMed,
    },
    tableCard: {
      border: `1px solid ${palette.grey[300]}`,
      borderRadius: 8,
      boxSizing: "content-box",
      background: palette.background.default,
    },
  }

  // The final Operandio theme object with our MUI component overrides
  const muiTheme = createTheme({
    palette: { ...palette },
    hasCustomColors,
    cards: {
      hoverCard: cards.hoverCard,
      navigationCard: cards.navigationCard,
      filterCard: cards.filterCard,
      viewerCard: cards.viewerCard,
      tableCard: cards.tableCard,
      areaCard: cards.areaCard,
    },
    dimensions: {
      header: {
        height: 60,
      },
      punchClock: {
        height: 68,
      },
      offlineBanner: {
        height: 40,
      },
      leftNavigation: {
        drawer: {
          expanded: 300,
          collapsed: 72,
        },
        item: {
          height: 36,
          width: 36,
          iconSize: 24,
        },
      },
      dialogs: {
        margin: 32,
        alerts: {
          width: 327,
        },
        pickers: {
          width: 327,
          wideWidth: null,
        },
        training: {
          width: 327,
        },
      },
      footer: {
        mobileBottomNav: {
          height: 65,
        },
      },
    },
    typography: {
      fontFamily: [
        "Inter",
        "Roboto",
        "'Helvetica Neue'",
        "Arial",
        "sans-serif",
        "'Apple Color Emoji'",
        "'Segoe UI Emoji'",
        "'Segoe UI Symbol'",
        "'Noto Emoji'",
        "'Noto Color Emoji'",
      ].join(","),
      color: palette.text.primary,
    },
    overrides: {
      MuiAccordion: {
        root: {
          marginBottom: 0,
          "&:before": {
            display: "none",
          },
          "&$expanded": {
            height: "auto",
            minHeight: "auto",
            marginTop: 0,
          },
        },
      },
      MuiAccordionDetails: {
        root: {
          border: "none",
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      MuiAccordionSummary: {
        root: {
          border: "none",
          margin: 0,
          padding: 0,
        },
      },
      MuiAlert: {
        root: {
          width: "100%",
          border: "none",
        },
        message: {
          width: "100%",
          "&& a, && button": {
            color: "inherit",
            textDecoration: "underline",
          },
        },
      },
      MuiAppBar: {
        root: {
          boxShadow: "none",
        },
      },
      MuiAutocomplete: {
        paper: {
          border: `1px solid ${palette.grey[300]}`,
          boxShadow: cards.hoverCard.boxShadow,
          "&:has(.MuiBox-root:empty)": {
            border: "none",
          },
        },
      },
      MuiAvatar: {
        root: {
          fontSize: "0.9rem",
          fontWeight: "600",
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: "rgba(8, 8, 33, 0.6)",
        },
      },
      MuiBottomNavigation: {
        root: {
          boxShadow: "none",
        },
      },
      MuiBottomNavigationAction: {
        label: {
          paddingTop: 5,
          fontSize: 11,
          color: palette.text.primary,
          "&$selected": {
            color: palette.text.primary,
            fontSize: 11,
            background: "none !important",
          },
        },
      },
      MuiBreadcrumbs: {
        li: {
          fontSize: 14,
          lineHeight: "16px",
          padding: baseTheme.spacing(0.5),
          borderRadius: baseTheme.spacing(0.5),
          "&& a": {
            fontWeight: "600",
          },
          "&:hover, &:focus, &:focus-visible": {
            backgroundColor: `rgba(${hexToRgb(palette.text.link)}, 0.1)`,
          },
          "&:last-child": {
            background: "none",
          },
        },
      },
      MuiButton: {
        root: {
          boxShadow: "none",
          textTransform: "none",
          fontWeight: "600",
          outline: `2px solid transparent`,
        },
        text: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${hexToRgb(palette.grey[500])}, 0.5)`,
          },
        },
        textPrimary: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.primary.mainRgb},0.5)`,
          },
        },
        textSecondary: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.error.mainRgb},0.5)`,
          },
        },
        outlined: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${hexToRgb(palette.grey[500])}, 0.5)`,
          },
        },
        outlinedPrimary: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.primary.mainRgb},0.5)`,
          },
        },
        outlinedSecondary: {
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.error.mainRgb},0.5)`,
          },
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${hexToRgb(palette.grey[500])}, 0.5)`,
          },
        },
        containedPrimary: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.primary.mainRgb},0.5)`,
          },
        },
        containedSecondary: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&:focus": {
            boxShadow: `0px 0px 0px 3px rgba(${palette.error.mainRgb},0.5)`,
          },
        },
      },
      MuiButtonGroup: {
        contained: {
          boxShadow: "none",
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          "&.Mui-focusVisible": {
            backgroundColor: `rgba(${palette.primary.mainRgb},.1)`,
          },
        },
      },
      MuiChip: {
        root: {
          fontWeight: 500,
          color: palette.text.primary,
        },
      },
      MuiDialog: {
        paperFullScreen: {
          marginTop: 60,
          paddingBottom: 240,
        },
        paper: {
          position: "fixed",
          top: 0,
        },
        paperWidthMd: {
          maxWidth: "768px",
        },
      },
      MuiDialogTitle: {
        root: {
          paddingTop: baseTheme.spacing(3),
          paddingRight: baseTheme.spacing(7),
          "&& h2": {
            fontSize: 20,
            fontWeight: "600 !important",
            lineHeight: "28px",
          },
        },
      },
      MuiDialogContentText: {
        root: {
          color: palette.text.primary,
        },
      },
      MuiDialogActions: {
        root: {
          fontSize: 14,
          fontWeight: "600",
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: palette.grey[200],
        },
        light: {
          backgroundColor: palette.grey[100],
        },
      },
      MuiIconButton: {
        root: {
          color: palette.text.primary,
          "&:hover": {
            backgroundColor: palette.action.hover,
          },
          "&:focus, &:focus-visible": {
            backgroundColor: palette.action.focus,
          },
        },
        colorPrimary: {
          "&:hover, &:focus, &:focus-visible": {
            backgroundColor: `rgba(${palette.primary.mainRgb}, 0.1)`,
          },
        },
      },
      MuiInputBase: {
        root: {
          color: palette.text.primary,
        },
      },
      MuiLinearProgress: {
        colorPrimary: {
          background: palette.info.background,
        },
      },
      MuiLink: {
        button: {
          fontSize: 14,
          fontWeight: "600",
        },
      },
      MuiListItem: {
        gutters: {
          paddingTop: baseTheme.spacing(1),
          paddingBottom: baseTheme.spacing(1),
        },
        divider: {
          borderBottom: `1px solid ${palette.grey[200]}`,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: "32px",
        },
      },
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: palette.grey[400],
        },
      },
      MuiPaper: {
        root: {
          color: palette.text.primary,
        },
        rounded: {
          borderRadius: baseTheme.spacing(1),
        },
        elevation0: {
          border: `1px solid ${palette.grey[300]}`,
          boxShadow: "none",
        },
        elevation8: {
          border: `1px solid ${palette.grey[300]}`,
          boxShadow: cards.hoverCard.boxShadow,
        },
      },
      MuiRadio: {
        colorPrimary: {
          "&.Mui-focusVisible": {
            backgroundColor: `rgba(${palette.primary.mainRgb},.1)`,
          },
        },
      },
      MuiSelect: {
        root: {
          backgroundColor: palette.background.default,
        },
      },
      MuiSnackbar: {
        root: {
          "& .MuiChip-root": {
            backgroundColor: palette.info.main,
          },
        },
      },
      MuiSwitch: {
        colorPrimary: {
          "&.Mui-focusVisible": {
            backgroundColor: `rgba(${palette.primary.mainRgb},.1)`,
          },
        },
      },
      MuiTableBody: {
        root: {
          "& .MuiTableRow-root:last-child .MuiTableCell-root": {
            borderBottom: "none",
          },
        },
      },
      MuiTableCell: {
        root: {
          color: palette.text.primary,
        },
        head: {
          color: palette.text.primary,
        },
      },
      MuiTab: {
        wrapper: {
          textTransform: "none",
          color: palette.text.primary,
          fontSize: 14,
          fontWeight: "600",
          lineHeight: "16px",
        },
        root: {
          minWidth: "130px",
          "@media (min-width: 600px)": {
            minWidth: "130px",
          },
          "&&.Mui-disabled > .MuiTab-wrapper": {
            color: palette.text.disabled,
          },
          "&:focus-visible": {
            backgroundColor: palette.action.focus,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          },
        },
      },
      MuiTabs: {
        indicator: {
          height: 3,
          borderRadius: "3px 3px 0px 0px",
        },
      },
    },
    props: {
      MuiButton: {
        disableElevation: false,
      },
      MuiButtonBase: {
        disableRipple: true,
      },
      MuiButtonGroup: {
        disableRipple: true,
      },
      MuiCheckbox: {
        disableRipple: true,
      },
    },
  })

  // cssbaseline styles override
  const styles = (theme) => ({
    "@global": {
      html: {},
      body: {
        height: "100%",
        color: theme.palette.text.primary,
      },
      "#root": {
        height: "100%",
        minHeight: "100vh",
        display: "flex",
        flexFlow: "column",
        flexGrow: 1,
      },
      a: {
        textDecoration: "none",
        color: theme.palette.text.link,
      },
      "@keyframes successGlow": {
        "0%": {
          boxShadow: `0 0 5px rgba(${theme.palette.success.mainRgb},.2), inset 0 0 5px rgba(${theme.palette.success.mainRgb},.1), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
        },
        "70%": {
          boxShadow: `0 0 20px rgba(${theme.palette.success.mainRgb},.9), inset 0 0 10px rgba(${theme.palette.success.mainRgb},.6), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
        },
        "100%": {
          boxShadow: `0 0 5px rgba(${theme.palette.success.mainRgb},.2), inset 0 0 5px rgba(${theme.palette.success.mainRgb},.1), 0 2px 0 rgba(${theme.palette.success.mainRgb},.12)`,
        },
      },
      "@keyframes slide": {
        "0%": {
          transform: "translateX(-100%)",
        },
        "100%": {
          transform: "translateX(100%)",
        },
      },
      "@keyframes fadeInOpacity": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        },
      },
      "@keyframes fadeInOpacityLight": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 0.3,
        },
      },
      "@keyframes opacityPulse": {
        "0%": { opacity: 1 },
        "50%": { opacity: 0 },
        "100%": { opacity: 1 },
      },
      "@keyframes beacon": {
        "0%": {
          opacity: 0,
        },
        "50%": {
          opacity: 0.3,
        },
        "100%": {
          opacity: 0,
        },
      },
      "@media (max-width: 1024px)": {
        "#hubspot-messages-iframe-container": {
          display: "none",
          visibility: "hidden",
        },
      },
      ".kiosk #hubspot-messages-iframe-container": {
        display: "none",
        visibility: "hidden",
      },
      ".device #hubspot-messages-iframe-container": {
        display: "none",
        visibility: "hidden",
      },
      ".BeaconContainer": {
        zIndex: "999999 !important",
      },
      ".announcekit-frame-wrapper": {
        width: "90vw !important",
        maxWidth: "750px !important",
        borderRadius: "8px !important",
      },
      ".announcekit-widget-iframe-backdrop": {
        backgroundColor: "rgba(8, 8, 33, 0.6) !important",
        zIndex: "10000 !important",
      },
      'body [class*="OverlayBase-popper"]': {
        zIndex: "1500",
      },
    },
  })

  const WithStylesOverrideCssBaseline = withStyles(styles)(OverrideCssBaseline)

  return (
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />
      <WithStylesOverrideCssBaseline />
      {children}
    </ThemeProvider>
  )
}

export default Theme
