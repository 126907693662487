import React from "react"
import { makeStyles, useTheme } from "@material-ui/styles"
import { Box, useMediaQuery } from "@material-ui/core"
import InfiniteScroll from "react-infinite-scroll-component"
import { Trans } from "@lingui/macro"
import { NoItemsMessage, LoadingSpinner } from ".."
import { ModuleHeading } from "../Headings"
import { Action } from "./Action"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  postList: {
    marginBottom: theme.spacing(1),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
  },
}))

const ActionList = ({
  title,
  loading,
  error,
  actions,
  loadMore,
  onEdit,
  onDelete,
  onCommentCreated,
  onStatusChange,
  onOpenSubNav,
  onOpenFilter,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = useStyles({ xs })

  const heading = (
    <>
      {!actions && loading && <Trans>Loading...</Trans>}
      {!actions && error && <Trans>Error</Trans>}
      {actions && title}
    </>
  )

  const items = actions || []

  return (
    <>
      <ModuleHeading subNav onSubnav={onOpenSubNav} filter onFilter={onOpenFilter}>
        {heading}
      </ModuleHeading>
      <div className={classes.postList}>
        {!!items.length && (
          <InfiniteScroll
            dataLength={items.length}
            next={loadMore}
            hasMore
            endMessage={
              !loading && (
                <NoItemsMessage>
                  {items.length || "No"} matching item{items.length > 1 ? "s" : ""}
                </NoItemsMessage>
              )
            }
            style={{ overflow: "visible" }}
          >
            <Box data-cy="Box-actions">
              {items.map((action) => (
                <Action
                  key={toId(action)}
                  action={action}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  onCommentCreated={onCommentCreated}
                  onStatusChange={onStatusChange}
                />
              ))}
            </Box>
          </InfiniteScroll>
        )}
        {loading && (
          <Box display="flex" justifyContent="center">
            <LoadingSpinner size={60} />
          </Box>
        )}
        {!loading && !items.length && (
          <NoItemsMessage>
            <Trans>No matching items</Trans>
          </NoItemsMessage>
        )}
      </div>
    </>
  )
}

export { ActionList }
