import React, { useMemo } from "react"
import { Box, ListItem, makeStyles } from "@material-ui/core"
import { FormatDate } from "../index"
import { JOB_STATUS_LABELS } from "../../data"

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    cursor: "pointer",
    margin: 0,
    gap: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: theme.palette.primary.backgroundDark,
    },
  },
  rightBox: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  type: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textAlign: "right",
    width: 80,
    textTransform: "capitalize",
  },
  separator: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textAlign: "right",
    width: 8,
    userSelect: "none",
  },
  date: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textAlign: "right",
    width: 60,
    textTransform: "none",
  },
}))

const SearchListItem = ({ children, date, type, areaType, result, priority, jobStatus, ...rest }) => {
  const classes = useStyles()

  const extraInfo = useMemo(() => {
    let content = null
    switch (areaType) {
      case "job":
        content = JOB_STATUS_LABELS[jobStatus]
        break
      case "actions":
        content = priority
        break
      case "everything":
        content = type
        break
      default:
        content = ""
    }

    return content ? <span className={classes.type}>{`${content}`}</span> : null
  }, [areaType, jobStatus, priority, type, classes.type])

  return (
    <ListItem {...rest} className={classes.listItem}>
      {children}

      <Box className={classes.rightBox}>
        {extraInfo || <span />}
        {date && (
          <>
            {extraInfo && <span className={classes.separator}>|</span>}
            {!extraInfo && <span />}
            <div className={classes.date}>
              <FormatDate value={date} format="DD MMM YY" />
            </div>
          </>
        )}
      </Box>
    </ListItem>
  )
}

export { SearchListItem }
