import React from "react"
import { Button } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { RequirePermissions } from ".."
import { useAuth } from "../../services"

const DesktopCreateCommsButton = ({ onCreate }) => {
  const { permissionGroups } = useAuth()

  const handleCreate = () => {
    onCreate && onCreate()
  }

  return (
    <RequirePermissions operator="or" requires={permissionGroups.createPost}>
      <Button onClick={handleCreate} variant="contained" color="primary" data-cy="Button-create-comms">
        <Trans>Create</Trans>
      </Button>
    </RequirePermissions>
  )
}

export { DesktopCreateCommsButton }
