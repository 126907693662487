import { useQuery, gql } from "@apollo/client"
import { TIMER_FIELDS } from "./fragments/timerFieldsFragment"

const TIMERS_QUERY = gql`
  query Timers($filter: TimerFilterInput) {
    timers {
      list(filter: $filter) {
        ...TimerFields
      }
      count(filter: $filter)
    }
  }
  ${TIMER_FIELDS}
`

const TIMER_QUERY = gql`
  query Timer($id: ID!) {
    timer(id: $id) {
      ...TimerFields
    }
  }
  ${TIMER_FIELDS}
`

const TIMER_CREATED_SUBSCRIPTION = gql`
  subscription TimerCreated {
    timerCreated {
      ...TimerFields
    }
  }
  ${TIMER_FIELDS}
`

const TIMER_UPDATED_SUBSCRIPTION = gql`
  subscription TimerUpdated {
    timerUpdated {
      ...TimerFields
    }
  }
  ${TIMER_FIELDS}
`

const timersTypePolicies = {
  scoped: {
    TimersQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
    },
  },
}

const subscribe =
  ({ result }) =>
  () => [
    result.subscribeToMore({
      document: TIMER_CREATED_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData?.data) {
          return prev
        }

        const newItem = subscriptionData.data.timerCreated
        if (!newItem) {
          return prev
        }

        // skip if already exists
        if (prev.timers.list.some((p) => p.id === newItem.id)) {
          return prev
        }

        return {
          ...prev,
          timers: { ...prev.timers, list: [newItem, ...prev.timers.list], count: prev.timers.count + 1 },
        }
      },
      onError: (err) => console.log("[useQueryTimers][TIMER_CREATED_SUBSCRIPTION]", err),
    }),
    result.subscribeToMore({
      document: TIMER_UPDATED_SUBSCRIPTION,
      onError: (err) => console.log("[useQueryTimers][TIMER_PAUSED_SUBSCRIPTION]", err),
    }),
  ]

const subscribeSingle = (result) => () => [
  result.subscribeToMore({
    document: TIMER_UPDATED_SUBSCRIPTION,
    onError: (err) => console.log("[useQueryTimers][TIMER_UPDATED_SUBSCRIPTION(2)]", err),
  }),
]

const useQueryTimers = (options) => {
  const result = useQuery(TIMERS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe({ result }),
  }
}

const useQueryTimer = (options) => {
  const result = useQuery(TIMER_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribeSingle(result),
  }
}

export { useQueryTimers, useQueryTimer, TIMERS_QUERY, timersTypePolicies }
