import React from "react"
import { Box, ListItem } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import NavLinkItem from "../NavLinkItem"

const useStyles = makeStyles((theme) => ({
  navLinkListItem: {
    padding: 0,
    display: "block",
    background: ({ active, variant }) =>
      active && variant === "horizontal"
        ? theme.palette.primary.main
        : !active && variant === "horizontal"
          ? theme.palette.grey[200]
          : "transparent",
  },
  navLinkItem: {
    display: "flex",
    flexGrow: 1,
    fontSize: 16,
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1, 0.5, 1),
    "&:hover": {
      background: theme.palette.action.hover,
      borderRadius: 4,
      textDecoration: "none",
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.action.focus,
      borderRadius: 4,
      textDecoration: "none",
      outline: "none",
    },
  },
  navLinkItemText: {
    color: ({ active, variant }) =>
      active && variant === "horizontal"
        ? theme.palette.text.white
        : active && variant !== "horizontal"
          ? theme.palette.text.primary
          : theme.palette.text.primary,
    borderLeft: ({ active, color, variant }) =>
      active && variant !== "horizontal" ? `solid 3px ${color || theme.palette.primary.main}` : null,
    paddingLeft: ({ active, variant }) => (active && variant !== "horizontal" ? theme.spacing(1.25) : 0),
    whiteSpace: "nowrap",
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))

const SubNavigationListItem = ({
  variant,
  nested,
  active,
  color,
  badgeVariant = "badge",
  badgeCount,
  badgeShowZero = true,
  text: textInput,
  ...props
}) => {
  const classes = useStyles({ active, color, variant })
  const theme = useTheme()

  const text =
    badgeVariant === "text" && (badgeCount || (badgeShowZero && typeof badgeCount !== "undefined"))
      ? `${textInput} (${badgeCount})`
      : textInput

  return (
    <ListItem component={nested ? Box : ListItem} classes={{ root: classes.navLinkListItem }} disableGutters>
      <NavLinkItem
        className={classes.navLinkItem}
        badgeStyleProps={{
          color: theme.palette.text.white,
          backgroundColor: theme.palette.primary.main,
        }}
        text={<span className={classes.navLinkItemText}>{text}</span>}
        badgeCount={badgeVariant === "badge" ? badgeCount : null}
        badgeShowZero={badgeShowZero}
        {...props}
      />
    </ListItem>
  )
}

export { SubNavigationListItem, useStyles as useSubNavigationListItemStyles }
