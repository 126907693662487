import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Box, Drawer, useMediaQuery, useTheme } from "@material-ui/core"
import { DrawerHeading } from ".."

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& .MuiDrawer-paper": {
      borderBottomRightRadius: 16,
      borderTopRightRadius: 16,
    },
    minWidth: ({ xs }) => (xs ? "60vw" : "40vw"),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  drawerContent: {
    padding: theme.spacing(1),
  },
}))

const MobileSubNavigation = ({ title, children, open, onClose }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const classes = useStyles({ xs })

  const handleClose = () => {
    onClose && onClose()
  }

  if (!md) {
    return null
  }

  return (
    <Drawer open={open} onClose={handleClose} className={classes.drawer}>
      <Box className={classes.drawer}>
        <DrawerHeading>{title}</DrawerHeading>
        <Box className={classes.drawerContent}>{children}</Box>
      </Box>
    </Drawer>
  )
}

export { MobileSubNavigation }
