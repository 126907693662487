import { useMutation, gql } from "@apollo/client"

const PUBLIC_JOB_SUBMIT_MUTATION = gql`
  mutation SubmitPublicJob($process: ID!, $schedule: ID!, $steps: [StepSubmissionInput!]!, $recaptchaToken: String!) {
    publicJob(process: $process, schedule: $schedule) {
      submit(input: { steps: $steps }, recaptchaToken: $recaptchaToken) {
        message
      }
    }
  }
`

const useMutationPublicJobSubmit = () =>
  useMutation(PUBLIC_JOB_SUBMIT_MUTATION, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  })

export { useMutationPublicJobSubmit }
