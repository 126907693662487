import React, { useEffect, useMemo, useRef, useState } from "react"
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  MenuItem,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  IconButton,
  Tabs,
  Tab,
  useTheme,
} from "@material-ui/core"
import { t, Trans } from "@lingui/macro"
import { Autocomplete } from "@material-ui/lab"
import { CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons"
import { CreatorActions, CreatorMaster } from "../Creators"
import { deepRemoveTypenames, mapToIds, toId, useFormUtils } from "../../utils"
import { MentionTextInput } from "../Mentions"
import { LocationsAssigner } from "../Assigners"
import { useMutationCreateFoodItem, useMutationUpdateFoodItem } from "../../data/food-items/useMutationFoodItem"
import { PROCESS_STEP_FORMAT_UNIT, useQueryFoodItem } from "../../data"
import { processFormats } from "../../data/processes/processFormats"
import { Icon, OutlinedSelect, UploaderDropZone, DisplayImage } from ".."
import { FoodItemLimitCreator } from "./FoodItemLimitCreator"
import { ColumnBox, RowBox } from "../Boxes"
import { CircularProgress } from "../CircularProgress"

// Move to backend in the future as a template
const standardCoolingProfileItems = [
  {
    elapsedTime: {
      hour: 0,
      minute: 0,
    },
    minTemperature: "-100",
    maxTemperature: "100",
  },
  {
    elapsedTime: {
      hour: 1,
      minute: 0,
    },
    minTemperature: "-100",
    maxTemperature: "60",
  },
  {
    elapsedTime: {
      hour: 2,
      minute: 0,
    },
    minTemperature: "-100",
    maxTemperature: "21",
  },
  {
    elapsedTime: {
      hour: 4,
      minute: 0,
    },
    minTemperature: "-100",
    maxTemperature: "5",
  },
]

const initialState = {
  name: "",
  description: "",
  useStandardCoolingProfile: false,
  coolingProfile: {
    format: {
      unit: "temperature_celsius",
      decimalPlaces: "",
    },
    items: [
      {
        elapsedTime: {
          hour: 0,
          minute: 0,
        },
        minTemperature: "",
        maxTemperature: "",
      },
    ],
  },
  locations: [],
}

const useStyles = makeStyles((theme) => ({
  uploadFrame: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  uploadDisplay: {
    width: 200,
    height: 200,
  },
  imageFrame: {
    padding: theme.spacing(1),
    background: theme.palette.grey[50],
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    width: 200,
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  imageDisplay: {
    width: 200,
    objectFit: "cover",
    minHeight: "100%",
  },
  backing: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: "8px",
    alignItems: "center",
  },
  remove: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: theme.palette.common.white,
    padding: 2,
    zIndex: 999,
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  progress: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "100%",
  },
  imageContainer: {
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
}))

const FoodItemCreator = ({ open, id, isInline, onClose }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { isValid, touched } = useFormUtils()
  const { data } = useQueryFoodItem({ variables: { id: toId(id) }, skip: !id })
  const descriptionRef = useRef(null)
  const initRef = useRef(false)

  const [createFoodItem] = useMutationCreateFoodItem()
  const [updateFoodItem] = useMutationUpdateFoodItem()
  const [loading, setLoading] = useState(!!id)
  const [isUploading, setIsUploading] = useState(false)
  const [, setUploading] = useState([])
  const [upload] = useState([])

  const [tab, setTab] = useState("details")

  const [name, setName] = useState(initialState.name)
  const [description, setDescription] = useState(initialState.description)
  const [image, setImage] = useState(initialState.image)
  const [coolingProfile, setCoolingProfile] = useState(initialState.coolingProfile)
  const [limits, setLimits] = useState(initialState.coolingProfile.items)
  const [locations, setLocations] = useState(initialState.locations)
  const [useStandardCoolingProfile, setUseStandardCoolingProfile] = useState(true)

  const optionValue = useMemo(() => {
    return processFormats.find((item) => item.name === coolingProfile.format?.unit) || null
  }, [coolingProfile.format?.unit])

  useEffect(() => {
    if (!data?.foodItem) return

    initRef.current = true
    const source = data.foodItem
    setName(source.name)
    setLocations(source.locations)
    setLimits(source.coolingProfile.items)
    setCoolingProfile(source.coolingProfile)
    setDescription(source.description)
    setUseStandardCoolingProfile(source.useStandardCoolingProfile || false)
    setImage(source.image)
    setLoading(false)
  }, [data])

  useEffect(() => {
    if (useStandardCoolingProfile) {
      setCoolingProfile({
        format: {
          unit: "temperature_celsius",
          decimalPlaces: null,
        },
        items: standardCoolingProfileItems,
      })
      setLimits(standardCoolingProfileItems)
    }

    if (!useStandardCoolingProfile) {
      const source = data?.foodItem
      setCoolingProfile(source?.coolingProfile || initialState?.coolingProfile)
      setLimits(source?.coolingProfile?.items || initialState?.coolingProfile?.items)
    }
  }, [useStandardCoolingProfile, data])

  const handleSubmit = async () => {
    if (formValid()) {
      setLoading(true)
      const { format } = coolingProfile

      // Default value for format
      if (!id) {
        if (!format?.unit) format.unit = PROCESS_STEP_FORMAT_UNIT.TEMPERATURE_CELSIUS
        if (!format?.decimalPlaces) format.decimalPlaces = null
      }

      const items = limits.map(({ elapsedTime, minTemperature, maxTemperature }) => ({
        elapsedTime,
        minTemperature: Number(minTemperature),
        maxTemperature: Number(maxTemperature),
      }))
      const variables = {
        input: {
          name,
          description,
          useStandardCoolingProfile,
          coolingProfile: { format: deepRemoveTypenames(format), items: deepRemoveTypenames(items) },
          locations: mapToIds(locations).filter((locId) => locId !== "all"),
          image: toId(image, true),
        },
      }

      try {
        if (id) {
          await updateFoodItem({ variables: { id: toId(id), ...variables } })
          setLoading(false)
        } else {
          await createFoodItem({ variables })
          setLoading(false)
        }
        handleClose()
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const handleLocationsPickedChanged = (picked) => {
    const pickedLocationIds = mapToIds(picked)

    setLocations([...pickedLocationIds])
  }

  const handleAddLimit = () => {
    setLimits([...limits, ...initialState.coolingProfile.items])
  }

  const handleLimitRemove = (index) => {
    setLimits((prev) => prev.filter((_, i) => i !== index))
  }

  const handleFormatUpdate = (propName, value) => {
    const newFormat = { ...coolingProfile.format, [propName]: value }
    setCoolingProfile((prev) => ({ ...prev, format: newFormat }))
  }

  const handleLimitChange = (index, key, val) => {
    setLimits((prev) => {
      const newLimits = [...prev]
      newLimits[index] = {
        ...newLimits[index],
        [key]: val,
      }
      return newLimits
    })
  }

  const handleUseStandardCoolingProfile = (value) => {
    setUseStandardCoolingProfile(value === "true")
    if (value === "true") {
      // Set cooling profile to standard one
      setCoolingProfile({
        format: {
          unit: "temperature_celsius",
          decimalPlaces: null,
        },
        items: standardCoolingProfileItems,
      })
      setLimits(standardCoolingProfileItems)
    }

    if (value === "false") {
      const source = data?.foodItem
      console.log("source", source)
      setCoolingProfile(source?.coolingProfile || initialState.coolingProfile)
      setLimits(source?.coolingProfile?.items || initialState.coolingProfile.items)
    }
  }

  const isValidLimits = (listLimit) =>
    listLimit?.every(({ minTemperature, maxTemperature }) => {
      return minTemperature !== "" && maxTemperature !== ""
    })

  const convertToMinutes = ({ hour, minute }) => hour * 60 + minute

  const isValidElapsedTime = (listLimit) =>
    listLimit?.reduce((isAscending, { elapsedTime }, index, array) => {
      if (index < array.length - 1) {
        const minutes = convertToMinutes({ ...elapsedTime })
        const nextMinutes = convertToMinutes({ ...array[index + 1].elapsedTime })

        return isAscending && minutes < nextMinutes
      }
      return isAscending
    }, true)

  const formValid = () => isValid(name, limits, coolingProfile) && isValidLimits(limits) && isValidElapsedTime(limits)

  const isFormValid = formValid()

  const unitTemperatures = processFormats.filter((item) => item.type === "Temperature")

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }

  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }

  const handleRemoveUpload = () => {
    setImage(null)
  }

  const handleAfterUpload = (items) => {
    setImage(items[0].upload)
    setIsUploading(false)
  }

  const handleNotUploading = () => {
    setIsUploading(false)
  }

  const { loaded, total } = upload

  const form = (
    <>
      <Tabs
        variant="standard"
        value={tab}
        onChange={(_, newTab) => setTab(newTab)}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Select food item editor tab"
      >
        <Tab label={<Trans>Details</Trans>} value="details" data-cy="Tab-details" />
        <Tab label={<Trans>Cooling Profile</Trans>} value="cooling-profile" data-cy="Tab-cooling-profile" />
      </Tabs>

      <Divider />

      <Box py={2} sx={{ gap: theme.spacing(2) }}>
        {tab === "details" && (
          <>
            <ColumnBox mb={2} sx={{ gap: theme.spacing(2) }}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                label={t`Name`}
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
                required
                data-cy="TextField-name"
                disabled={loading}
              />

              <RowBox className={classes.imageContainer} width="100%">
                <Box className={classes.imageFrame}>
                  {image ? (
                    <ColumnBox className={classes.backing}>
                      {!!handleRemoveUpload && (
                        <IconButton onClick={handleRemoveUpload} disabled={!image} className={classes.remove}>
                          <CancelOutlinedIcon />
                        </IconButton>
                      )}
                      {!image && (
                        <ColumnBox className={classes.progress} alignItems="center" justifyContent="center">
                          <CircularProgress value={Math.round((loaded / total) * 100)} size={32} />
                        </ColumnBox>
                      )}
                      {image && (
                        <DisplayImage
                          upload={image}
                          width={300}
                          fit="cover"
                          alt="Label image"
                          className={classes.imageDisplay}
                        />
                      )}
                    </ColumnBox>
                  ) : (
                    <ColumnBox className={classes.uploadFrame}>
                      <UploaderDropZone
                        onBeforeUpload={handleBeforeUpload}
                        onProgress={handleUploadProgress}
                        onAfterUpload={handleAfterUpload}
                        onError={handleNotUploading}
                        documents={false}
                        prompt={t`Drag & drop image here or click to select`}
                        className={classes.uploadDisplay}
                        disabled={isUploading}
                      />
                    </ColumnBox>
                  )}
                </Box>

                <MentionTextInput
                  inputRef={descriptionRef}
                  label={t`Description`}
                  value={description}
                  locations={locations}
                  onChange={(event) => setDescription(event.target.value)}
                  fullWidth
                  height={145}
                  multiline
                  onBlur={() => touched(description, setDescription)}
                  cy="FoodItemCreator-content"
                  className={classes.description}
                />
              </RowBox>
            </ColumnBox>

            {(initRef.current || !id) && (
              <LocationsAssigner
                initialPicked={locations}
                onPickedChanged={handleLocationsPickedChanged}
                requireTimeZoneMatch={false}
                cy="FoodItemCreator-locations"
              />
            )}
          </>
        )}

        {tab === "cooling-profile" && (
          <>
            <Box pb={2}>
              <RadioGroup
                value={useStandardCoolingProfile}
                onChange={(e) => handleUseStandardCoolingProfile(e.target.value)}
                style={{ flexDirection: "row" }}
              >
                <FormControlLabel value control={<Radio color="primary" />} label={t`Use standard cooling profile`} />
                <FormControlLabel
                  value={false}
                  control={<Radio color="primary" />}
                  label={t`Use custom cooling profile`}
                />
              </RadioGroup>
            </Box>

            <Box mb={2}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="responseFormatUnit"
                    value={optionValue}
                    getOptionLabel={(option) => option?.display || ""}
                    getOptionSelected={(option) => option.name === optionValue.name}
                    options={unitTemperatures || []}
                    onChange={(event, value) => handleFormatUpdate("unit", value?.name || null)}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Unit" />}
                    disabled={useStandardCoolingProfile}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <OutlinedSelect
                    label="Decimal places"
                    native={false}
                    id="responseFormatDecimals"
                    value={coolingProfile.format?.decimalPlaces}
                    onChange={(e) => handleFormatUpdate("decimalPlaces", e.target.value === "" ? null : e.target.value)}
                    disabled={useStandardCoolingProfile}
                  >
                    <MenuItem value="">No limit</MenuItem>
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </OutlinedSelect>
                </Grid>
              </Grid>
            </Box>

            <List component="div">
              {limits?.map((limit, index) => (
                <FoodItemLimitCreator
                  key={toId(index)}
                  loading={loading}
                  limit={limit}
                  onChange={(prop, val) => handleLimitChange(index, prop, val)}
                  onDelete={() => handleLimitRemove(index)}
                  timePickerProps={{ disabled: index === 0 || useStandardCoolingProfile }}
                  useStandardCoolingProfile={useStandardCoolingProfile}
                />
              ))}
            </List>

            <Box mb={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleAddLimit}
                disabled={loading || useStandardCoolingProfile}
                data-cy="Button-add-limit"
              >
                <Icon name="add" /> Add limit
              </Button>
            </Box>
          </>
        )}
      </Box>

      <CreatorActions
        subject={t`Food Item`}
        submitLoading={loading}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid}
      />
    </>
  )

  return (
    <CreatorMaster
      open={open}
      subject={t`Food Item`}
      form={form}
      isEdit={Boolean(id)}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { FoodItemCreator }
