import { Container, Button, Box, Checkbox, useMediaQuery } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useParams } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import React, { useState, Suspense, useEffect } from "react"
import moment from "moment"
import ReCAPTCHA from "react-google-recaptcha"
import { useQueryPublicJob } from "../../data/publicJobs/userQueryPublicJob"
import {
  LoadingSpinner,
  ColumnBox,
  Avatar,
  RowBox,
  ActionCard,
  MentionOutput,
  OutlinedInputStep,
  useSnackbar,
} from "../../components"
import { PROCESS_STEP_RESPONSE_TYPE, PROCESS_STEP_SELECTION_SOURCES } from "../../data"
import { preventDefault, mapToIds } from "../../utils"
import { DateTimeStep } from "../../components/JobViewer/DateTimeStep"
import { MultipleChoiceButtonsStep } from "../../components/JobViewer/MultipleChoiceButtonsStep"
import SelectionStep from "../../components/ActionCards/SelectionStep"
import SignatureStep from "../../components/JobViewer/SignatureStep"
import { useMutationPublicJobSubmit } from "../../data/publicJobs"
import config from "../../config/Base"
import { useRecaptcha } from "../../utils/useRecaptcha"
import { Icon } from "../../components/Icon"

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette.grey[25],
    minHeight: "100vh",
  },
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5) + 64,
  },
  progressBar: {
    height: 8,
    backgroundColor: theme.palette.grey[200],
    width: "100%",
    position: "fixed",
    top: 0,
    zIndex: 1000,
  },
  progressFill: {
    height: "100%",
    backgroundColor: ({ isSubmitted }) => (isSubmitted ? theme.palette.success.main : theme.palette.primary.main),
    transition: "width 0.3s ease-in-out",
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    paddingTop: 8,
    backgroundColor: "white",
    minHeight: 80,
  },
  nav: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  avatar: {
    width: 48,
    height: 48,
  },
  thankyouAvatar: {
    width: 120,
    height: 120,
    backgroundColor: "white",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
  },
  description: {
    fontSize: 16,
    fontWeight: 400,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  stepTitle: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    marginTop: 19,
  },
  stepDescription: {
    fontSize: 14,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  stepNumber: {
    width: 48,
    height: 48,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.text.primary,
    fontSize: 20,
    fontWeight: "600",
  },
  footer: {
    padding: ({ xs }) => (xs ? theme.spacing(1, 1) : theme.spacing(1, 1.5)),
  },
  circle: {
    backgroundColor: ({ completed }) => (completed ? "transparent" : "#f5fffc"),
    borderRadius: 21,
    width: 42,
    height: 42,
  },
  icon: {
    color: ({ completed }) => (completed ? theme.palette.success.main : theme.palette.grey[500]),
    padding: 8,
    fontSize: 42,
  },
  resizedIcon: {
    color: ({ completed }) => (completed ? theme.palette.success.main : theme.palette.grey[500]),
    marginLeft: 10,
    marginTop: 9,
  },
  divider: {
    marginLeft: ({ completed, skip }) => (completed || skip ? 0 : -2),
    marginRight: ({ completed, skip }) => (completed || skip ? 0 : -2),
    width: "100%",
    backgroundColor: theme.palette.grey[200],
  },
  completedText: {
    color: "rgba(0,0,0,0.87)",
    "&.Mui-disabled": {
      color: "rgba(0,0,0,0.87)",
    },
  },
  adornments: {
    "& p": {
      color: "rgba(0,0,0,0.87)",
    },
  },
  completedButton: {
    backgroundColor: theme.palette.primary.background,
  },
  incompletedButton: {
    backgroundColor: theme.palette.primary.background,
  },
  button: {
    lineHeight: "14px",
    height: 36,
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: "auto",
    "&:not(.MuiButton-containedPrimary):not(.Mui-disabled)": {
      backgroundColor: theme.palette.primary.background,
    },
    "&:hover:not(.MuiButton-containedPrimary):not(.Mui-disabled)": {
      backgroundColor: theme.palette.primary.background,
    },
  },
  checkbox: {
    "&.Mui-checked": {
      color: theme.palette.primary.main,
    },
  },
  bottomBar: {
    backgroundColor: "white",
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 64,
    position: "fixed",
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
  recaptcha: {
    marginBottom: theme.spacing(2),
    "& > div": {
      margin: "0 auto",
    },
  },
}))

const { CHECKBOX, TEXT, LONG_TEXT, DATE_TIME, MULTIPLE_CHOICE, SIGNATURE, SELECTION } = PROCESS_STEP_RESPONSE_TYPE

export const PublicJob = () => {
  const theme = useTheme()
  const snackbar = useSnackbar()
  const { process, schedule } = useParams()
  const [page, setPage] = useState("form") // "form" or "success"
  const classes = useStyles({ isSubmitted: page === "success" })
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { recaptchaToken, recaptchaError, handleRecaptchaChange, handleRecaptchaReset, handleRecaptchaError } =
    useRecaptcha()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [formResponse, setFormResponse] = useState(() => {
    const storedFormResponse = localStorage.getItem(`publicJob-${process}-${schedule}`)
    return storedFormResponse ? JSON.parse(storedFormResponse) : []
  })
  const [countdown, setCountdown] = useState(10)

  const { data, loading, error } = useQueryPublicJob({
    variables: {
      process,
      schedule,
    },
  })
  const [submitPublicJob] = useMutationPublicJobSubmit()

  const publicJob = data?.publicJob
  const locationLogo = publicJob?.schedule?.locations[0]?.logo
  const organisationLogo = publicJob?.organisation?.logo
  const locationsNames = publicJob?.schedule?.locations.map((location) => location.name).join(", ")

  const isDisabled = Boolean(error)

  useEffect(() => {
    if (isDisabled) {
      const timer = setInterval(() => {
        if (countdown === 0) return
        setCountdown((prevCountdown) => prevCountdown - 1)
      }, 1000)

      if (countdown === 0) {
        window.location.href = "https://www.operandio.com"
      }

      return () => clearInterval(timer)
    }
  }, [isDisabled, countdown])

  // Persist form response in local storage
  useEffect(() => {
    handleRecaptchaReset()
    localStorage.setItem(`publicJob-${process}-${schedule}`, JSON.stringify(formResponse))
  }, [formResponse, process, schedule, handleRecaptchaReset])

  // Retrieve form response from local storage when page is loaded
  useEffect(() => {
    const storedFormResponse = localStorage.getItem(`publicJob-${process}-${schedule}`)
    if (storedFormResponse) {
      setFormResponse(JSON.parse(storedFormResponse))
    }
  }, [process, schedule])

  const handleResponseChange = (event, { stepId, response, type = "text", step = null }) => {
    let value = response

    if (type === "dateTime") {
      value = moment(response).format()
    }

    const variables = {
      step: stepId,
      skip: false,
      complete: false,
      response: String(value),
      completedAt: new Date().toISOString(),
    }

    if (type === "selection") {
      const isFromCustomItemsSource = step?.selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS
      const allowMultiple = step?.selection?.allowMultiple
      const optionIds = allowMultiple ? mapToIds(response) : response
      if (isFromCustomItemsSource) {
        variables.responseCustomSelections = step?.selection?.allowMultiple
          ? response.map((option) => option)
          : [response]
      }

      if (!isFromCustomItemsSource) {
        variables.responseSelections = {
          ids: optionIds,
          selectedFrom: step?.selection?.selectFrom?.slice(0, -1),
        }
      }
    }

    const existingIndex = formResponse.findIndex((item) => item.step === stepId)

    if (existingIndex !== -1) {
      if (response === null || response === "") {
        const filteredResponse = formResponse.filter((item) => item.step !== stepId)
        setFormResponse(filteredResponse)
        return
      }

      const updatedResponses = [...formResponse]
      updatedResponses[existingIndex] = variables
      setFormResponse(updatedResponses)
      return
    }

    setFormResponse([...formResponse, variables])
  }

  const handleCompletedChange = async (event, { stepId, response, completed = true }) => {
    preventDefault(event, async () => {
      const variables = {
        step: stepId,
        skip: false,
        complete: completed,
        response: String(response),
        completedAt: new Date().toISOString(),
      }
      const existingIndex = formResponse.findIndex((item) => item.step === stepId)

      if (existingIndex !== -1) {
        const updatedResponses = [...formResponse]
        updatedResponses[existingIndex] = variables
        setFormResponse(updatedResponses)
        return
      }

      if (existingIndex === -1) {
        setFormResponse([...formResponse, variables])
      }
    })
  }

  const handleClear = () => {
    // Clear local storage
    localStorage.removeItem(`publicJob-${process}-${schedule}`)
    setFormResponse([])
  }

  const handleSubmit = async () => {
    if (isSubmitting) return

    try {
      setIsSubmitting(true)

      // Validate reCAPTCHA
      if (!recaptchaToken) {
        snackbar.showMessage({
          message: t`Please complete the reCAPTCHA verification`,
        })
        return
      }

      // Check if all required steps are completed
      const requiredSteps = publicJob?.steps.filter((step) => step.responseMandatory)
      const missingSteps = requiredSteps.filter((step) => !formResponse.some((response) => response.step === step.id))
      if (missingSteps.length > 0) {
        snackbar.showMessage({
          message: t`Please complete the following steps: ${missingSteps.map((step) => step.name).join(", ")}`,
        })
        return
      }

      // Submit the form with recaptcha token
      const { data: submitData, errors } = await submitPublicJob({
        variables: {
          process,
          schedule,
          steps: formResponse,
          recaptchaToken,
        },
      })

      if (errors) {
        throw new Error(errors[0]?.message || "Form submission failed")
      }

      if (submitData) {
        handleClear()
        setPage("success")
      }
    } catch (error) {
      console.error("Form submission error:", error)
      snackbar.showMessage({
        message: t`Sorry, an error occurred while submitting the form`,
      })
      handleRecaptchaError()
    } finally {
      setIsSubmitting(false)
    }
  }

  const getSelectionResponse = (currentStep, isFromCustomItemsSource, allowMultiple) => {
    const selections = isFromCustomItemsSource
      ? currentStep?.responseCustomSelections
      : currentStep?.responseSelections?.ids

    return allowMultiple ? selections || [] : selections?.[0] || null
  }

  const renderDisabled = (error) => (
    <ColumnBox alignItems="center" justifyContent="center" gap={3}>
      <Box className={classes.title}>
        <Trans>Sorry</Trans>
      </Box>
      <Box className={classes.description}>
        <Trans>
          This form is not currently available. You will be redirected to the Operandio website in {countdown}{" "}
          seconds...
        </Trans>
      </Box>
    </ColumnBox>
  )

  const renderSuccess = () => (
    <Container component="main" className={classes.root}>
      <ColumnBox alignItems="center" justifyContent="center" gap={3}>
        <Avatar avatar={organisationLogo} className={classes.thankyouAvatar} fullName={publicJob?.organisation?.name} />

        <Box className={classes.title}>
          <Trans>Thank you</Trans>
        </Box>
        <Box className={classes.description}>
          <Trans>Your response has been submitted.</Trans>
        </Box>
      </ColumnBox>
    </Container>
  )

  // Calculate progress
  const calculateProgress = () => {
    if (page === "success") return 100
    if (!publicJob?.steps?.length) return 0
    const answeredQuestions = formResponse.length
    return Math.round((answeredQuestions / publicJob.steps.length) * 100)
  }

  return (
    <Box className={classes.main}>
      {/* Process Bar */}
      <Box className={classes.progressBar}>
        <Box className={classes.progressFill} style={{ width: `${calculateProgress()}%` }} />
      </Box>

      {/* Header */}
      {data && (
        <Box className={classes.header}>
          <Container className={classes.nav}>
            <RowBox alignItems="center" gap={2}>
              <Avatar
                avatar={locationLogo || organisationLogo}
                className={classes.avatar}
                fullName={publicJob?.schedule?.locations[0]?.name}
              />
              <Box>
                <Box className={classes.title}>
                  {publicJob?.schedule?.locations?.length === 1 ? locationsNames : publicJob?.organisation?.name}
                </Box>
                <Box className={classes.subtitle}>{publicJob?.schedule?.name}</Box>
              </Box>
            </RowBox>
          </Container>
        </Box>
      )}

      {page === "success" && renderSuccess()}

      {page === "form" && (
        <Container component="main" className={classes.root}>
          {/* Loading */}
          {loading && <LoadingSpinner />}
          {/* Error */}
          {isDisabled && renderDisabled()}
          {/* Data */}
          {data &&
            publicJob?.steps &&
            publicJob?.steps.map((step, index) => {
              const { responseType, responseMandatory } = step
              const currentStep = formResponse.find((response) => response.step === step.id)
              const completed = formResponse.some((response) => response.step === step.id && response.complete)
              let newResponse = currentStep?.response
              if (step.responseType === SELECTION) {
                const isFromCustomItemsSource =
                  step?.selection?.selectFrom === PROCESS_STEP_SELECTION_SOURCES.CUSTOM_ITEMS
                const allowMultiple = step?.selection?.allowMultiple
                newResponse = getSelectionResponse(currentStep, isFromCustomItemsSource, allowMultiple)
              }

              const isSection = step.responseType === PROCESS_STEP_RESPONSE_TYPE.SECTION
              const isInstruction = step.responseType === PROCESS_STEP_RESPONSE_TYPE.INSTRUCTION

              return (
                <Box key={index} display="flex" flexDirection="row" flexGrow={1}>
                  {!xs && !isInstruction && !isSection && (
                    <Box mr={3}>
                      <Avatar number={step.number || index + 1} className={classes.stepNumber} />
                    </Box>
                  )}

                  {isInstruction && (
                    <Box mr={3}>
                      <Avatar icon={<Icon name="instruction" />} className={classes.stepNumber} />
                    </Box>
                  )}

                  <Box flexGrow={1}>
                    <ActionCard
                      p={0}
                      title={
                        <ColumnBox>
                          <Box className={classes.stepTitle} mb={2}>
                            {step.name}
                            {responseMandatory && "*"}
                          </Box>
                          {step.description && (
                            <Box mt={1} mb={2} mr={2} className={classes.stepDescription}>
                              <MentionOutput content={step.description} />
                            </Box>
                          )}
                        </ColumnBox>
                      }
                      titleProps={{
                        alignSelf: "flex-start",
                      }}
                      cursor="default"
                      contentMinHeight="auto"
                      classes={{ footer: classes.footer }}
                      stub={
                        <Box p={1} alignSelf="flex-start">
                          {responseType === CHECKBOX && (
                            <Box className={classes.circle}>
                              <Checkbox
                                type="complete"
                                checked={formResponse.some(
                                  (response) => response.step === step.id && response.complete,
                                )}
                                onClick={(event) =>
                                  handleCompletedChange(event, {
                                    stepId: step.id,
                                    response: true,
                                    completed: !formResponse.some(
                                      (response) => response.step === step.id && response.complete,
                                    ),
                                  })
                                }
                                className={classes.checkbox}
                                data-cy={`ProcessStep-field-${responseType}`}
                              />
                            </Box>
                          )}
                        </Box>
                      }
                    >
                      {[TEXT, LONG_TEXT].includes(responseType) && (
                        <OutlinedInputStep
                          expand
                          complete={completed}
                          step={step}
                          onResponseChange={(result) =>
                            handleResponseChange(null, { stepId: step.id, response: result })
                          }
                          value={newResponse}
                          classes={{
                            divider: classes.divider,
                            completedText: classes.completedText,
                            incompletedButton: classes.incompletedButton,
                            completedButton: classes.completedButton,
                            adornments: classes.adornments,
                          }}
                          hideEditAndSaveButtons
                        />
                      )}
                      {DATE_TIME === responseType && (
                        <DateTimeStep
                          expand
                          complete={completed}
                          value={newResponse}
                          onChange={(result) =>
                            handleResponseChange(null, { stepId: step.id, response: result, type: "dateTime" })
                          }
                          classes={{
                            divider: classes.divider,
                            completedButton: classes.completedButton,
                          }}
                        />
                      )}
                      {MULTIPLE_CHOICE === responseType && (
                        <MultipleChoiceButtonsStep
                          expand
                          value={newResponse}
                          step={step}
                          responses={step.responseSet?.responses || []}
                          onClick={(e, result) =>
                            handleResponseChange(e, { stepId: step.id, response: result, type: "selection", step })
                          }
                          buttonClass={classes.button}
                          classes={{
                            divider: classes.divider,
                          }}
                        />
                      )}
                      {SELECTION === responseType && (
                        <SelectionStep
                          step={step}
                          expand
                          completed={completed}
                          value={newResponse}
                          onChange={(e, result) =>
                            handleResponseChange(e, { stepId: step.id, response: result, type: "selection", step })
                          }
                          classes={{
                            divider: classes.divider,
                          }}
                          isPublicJob
                        />
                      )}
                      {SIGNATURE === responseType && (
                        <Suspense
                          fallback={
                            <Box>
                              <Trans>Loading...</Trans>
                            </Box>
                          }
                        >
                          <SignatureStep
                            expand
                            value={newResponse}
                            complete={completed}
                            onChange={(result) =>
                              handleResponseChange(null, { stepId: step.id, response: result, type: "signature" })
                            }
                            buttonClass={classes.button}
                            classes={{
                              divider: classes.divider,
                            }}
                          />
                        </Suspense>
                      )}
                    </ActionCard>
                  </Box>
                </Box>
              )
            })}

          {/* Submit section */}
          {data && (
            <Box mt={3} display="flex" flexDirection="column" gap={2} alignItems="flex-end">
              {/* Add reCAPTCHA */}
              <Box className={classes.recaptcha}>
                <ReCAPTCHA
                  sitekey={config.google.recaptchaSiteKey}
                  onChange={handleRecaptchaChange}
                  onExpired={handleRecaptchaReset}
                  onErrored={handleRecaptchaError}
                  hl="en"
                />
              </Box>
              <RowBox gap={1} justifyContent="flex-end" flexGrow={1}>
                <Button variant="text" onClick={handleClear} disabled={isSubmitting}>
                  <Trans>Clear</Trans>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!recaptchaToken || recaptchaError || isSubmitting}
                >
                  {isSubmitting ? <LoadingSpinner size={24} /> : <Trans>Submit</Trans>}
                </Button>
              </RowBox>
            </Box>
          )}
        </Container>
      )}

      {/* Bottom Bar */}
      <Box className={classes.bottomBar}>
        <Container>
          <Trans>{publicJob?.organisation?.name}</Trans>
        </Container>
      </Box>
    </Box>
  )
}
