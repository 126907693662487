import React, { useRef, useState } from "react"
import { Button, IconButton, Input } from "@material-ui/core"
import { AttachFile } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import * as Sentry from "@sentry/browser"
import { v4 as uuid } from "uuid"
import Axios from "axios"
import Config from "react-global-configuration"
import { getMobileOperatingSystem, useDeviceUtils, useMountEffect } from "../../utils"
import { DEVICE_POSTMESSAGE_TYPE, useMutationRegisterUpload } from "../../data"
import { LinkButton } from ".."
import { useAuth } from "../../services"

const useStyles = makeStyles(() => ({
  fileInput: {
    left: 0,
    bottom: 0,
    width: 1,
    height: 1,
    opacity: 0,
    position: "fixed",
  },
}))

const Uploader = ({
  icon,
  reference,
  onBeforeUpload,
  onProgress,
  onItemUploaded,
  onAfterUpload,
  onDeviceUploaded,
  onError,
  images = true,
  cameraOnly = false,
  documents = true,
  multiple = true,
  disabled,
  variant = "icon",
  linkText,
  buttonText,
  buttonProps = {},
  deviceOptions = {},
  title,
  size = "medium",
}) => {
  const classes = useStyles()
  const fileRef = useRef(null)
  const { hasFeature } = useAuth()
  const { canPostMessage, postTakePhotosMessage } = useDeviceUtils()
  const [registerUpload] = useMutationRegisterUpload()
  const [uploading, setUploading] = useState(false)
  const {
    clientKiosk,
    clientDevice,
    uploader: { acceptImages, acceptDocuments },
  } = Config.get()

  useMountEffect(() => {
    function handleTakenPhotosMessage(event) {
      const message = event.detail
      try {
        if (!message?.type || !message?.payload || !message?.reference) {
          return
        }

        if (message.type === DEVICE_POSTMESSAGE_TYPE.TAKEN_PHOTOS && message.reference === reference) {
          onDeviceUploaded && onDeviceUploaded([...message.payload])
          setUploading(false)
        }
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    // register listener
    window.addEventListener("deviceMessage", handleTakenPhotosMessage)

    // remove listener
    return () => window.removeEventListener("deviceMessage", handleTakenPhotosMessage)
  })

  const handleClick = () => {
    if (hasFeature("device_native_camera") && cameraOnly && (clientKiosk || clientDevice) && canPostMessage()) {
      setUploading(true)
      postTakePhotosMessage({
        reference,
        options: {
          multiple: true,
          maximum: 10,
          ...deviceOptions,
        },
      })
      setTimeout(() => {
        setUploading(false)
      }, 3000)
      return
    }

    fileRef.current.click()
  }

  const uploadsToProgress = (uploads) =>
    uploads.filter((u) => u).map(({ upload, order, __upload }) => ({ ...upload, order, __upload }))

  const handleUpload = async (e) => {
    const files = Object.values(e.target.files)
    if (files && files.length > 0) {
      try {
        setUploading(true)
        const uploads = [
          ...files.map((file, index) => ({
            fileName: file.name,
            fileGroup: "upload",
            loaded: 0,
            total: file.size,
            order: index,
            __upload: uuid(),
          })),
        ]
        if (onBeforeUpload) onBeforeUpload([...uploads])

        await Promise.all(
          files.map(async (file, index) => {
            const { data } = await registerUpload({ variables: { fileName: file.name, fileSize: file.size } })
            if (!data) {
              throw Error("Request for upload failed")
            } else {
              const { order, __upload } = uploads[index]
              uploads[index] = { ...data.registerUpload, order, __upload }
              if (onProgress) onProgress(uploadsToProgress(uploads))
            }
          }),
        )

        await Promise.all(
          uploads.map(async (upload, index) => {
            try {
              await Axios.put(upload.signedUploadUrl, files[index], {
                headers: {
                  "Content-Type": upload.upload.contentType,
                },
                onUploadProgress: ({ loaded, total }) => {
                  upload.upload.loaded = loaded
                  upload.upload.total = total
                  if (onProgress) onProgress(uploadsToProgress(uploads))
                },
              })
              const { order, __upload } = upload
              if (onItemUploaded) onItemUploaded({ ...upload.upload, order, __upload })
            } catch (errUpload) {
              console.log(errUpload)
              throw errUpload
            }
          }),
        )

        if (onAfterUpload) onAfterUpload([...uploads])
      } catch (err) {
        if (onError) onError(err)
      } finally {
        setUploading(false)
      }
    }
  }

  const accept = []

  if (cameraOnly) {
    if (getMobileOperatingSystem() === "ANDROID") {
      accept.push("image/capture")
    } else {
      accept.push("image/*")
    }
  }

  if (images) accept.push(acceptImages)
  if (documents) accept.push(acceptDocuments)

  return (
    <>
      <Input
        type="file"
        inputRef={fileRef}
        onChange={handleUpload}
        className={classes.fileInput}
        inputProps={{
          multiple,
          ...(cameraOnly ? { capture: "environment" } : {}),
          accept: accept.join(","),
        }}
      />
      {variant === "icon" && (
        <IconButton size={size} onClick={handleClick} disabled={uploading || disabled} title={title}>
          {icon || <AttachFile />}
        </IconButton>
      )}
      {variant === "link" && (
        <LinkButton size={size} onClick={handleClick} disabled={uploading || disabled} title={title}>
          {linkText}
        </LinkButton>
      )}
      {variant === "button" && (
        <Button size={size} onClick={handleClick} disabled={uploading || disabled} title={title} {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </>
  )
}

export { Uploader }
