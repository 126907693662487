import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { toId } from "../../utils"

const INTEGRATION_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      attributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationLocationAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      locationAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationLocationGroupAttributeValues($id: ID!, $location: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      locationGroupAttributeValues(location: $location, key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationGroupAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      groupAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY = gql`
  query IntegrationUserAttributeValues($id: ID!, $key: String!, $searchText: String) {
    integration(id: $id) {
      userAttributeValues(key: $key, searchText: $searchText) {
        id
        key
        value
      }
    }
  }
`

const useQueryIntegrationAttributeValues = (options) => useQuery(INTEGRATION_ATTRIBUTE_VALUES_QUERY, options)

const useQueryIntegrationLocationAttributeValues = (options) =>
  useQuery(INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationLocationAttributeValues = () => useLazyQuery(INTEGRATION_LOCATION_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationLocationGroupAttributeValues = (options) =>
  useQuery(INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationLocationGroupAttributeValues = () =>
  useLazyQuery(INTEGRATION_LOCATION_GROUP_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationGroupAttributeValues = (options) => useQuery(INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationGroupAttributeValues = () => useLazyQuery(INTEGRATION_GROUP_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationUserAttributeValues = (options) => useQuery(INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY, options)

const useLazyQueryIntegrationUserAttributeValues = () => useLazyQuery(INTEGRATION_USER_ATTRIBUTE_VALUES_QUERY)

const useQueryIntegrationDataSourceValues = (config, queryOptions) => {
  const { integration, location, attribute, type } = config

  const inputSkip = queryOptions?.skip
  const finalQueryOptions = {
    ...(queryOptions || {}),
    skip: !attribute.dataSource,
  }
  delete finalQueryOptions.skip

  const integrationAttributeResult = useQueryIntegrationAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: inputSkip || type !== "integration" || !attribute.dataSource,
    ...finalQueryOptions,
  })
  const locationAttributeResult = useQueryIntegrationLocationAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: inputSkip || type !== "location" || !attribute.dataSource,
    ...finalQueryOptions,
  })
  const locationGroupAttributeResult = useQueryIntegrationLocationGroupAttributeValues({
    variables: { id: toId(integration), location: toId(location) || "unset", key: attribute.key },
    skip: inputSkip || type !== "locationGroup" || !attribute.dataSource,
    ...finalQueryOptions,
  })
  const groupAttributeResult = useQueryIntegrationGroupAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: inputSkip || type !== "group" || !attribute.dataSource,
    ...finalQueryOptions,
  })
  const userAttributeResult = useQueryIntegrationUserAttributeValues({
    variables: { id: toId(integration), key: attribute.key },
    skip: inputSkip || type !== "user" || !attribute.dataSource,
    ...finalQueryOptions,
  })

  if (type === "locationGroup" && !location) {
    throw new Error("Location is required for location group attribute values")
  }

  let result = null
  let values = null

  switch (type) {
    case "integration":
      result = integrationAttributeResult
      values = integrationAttributeResult?.data?.integration?.attributeValues || null
      break
    case "location":
      result = locationAttributeResult
      values = locationAttributeResult?.data?.integration?.locationAttributeValues || null
      break
    case "locationGroup":
      result = locationGroupAttributeResult
      values = locationGroupAttributeResult?.data?.integration?.locationGroupAttributeValues || null
      break
    case "group":
      result = groupAttributeResult
      values = groupAttributeResult?.data?.integration?.groupAttributeValues || null
      break
    case "user":
      result = userAttributeResult
      values = userAttributeResult?.data?.integration?.userAttributeValues || null
      break
    default:
      throw new Error(`Unknown type: ${type}`)
  }

  return {
    ...result,
    values,
  }
}

export {
  useQueryIntegrationAttributeValues,
  useQueryIntegrationLocationAttributeValues,
  useLazyQueryIntegrationLocationAttributeValues,
  useQueryIntegrationLocationGroupAttributeValues,
  useLazyQueryIntegrationLocationGroupAttributeValues,
  useQueryIntegrationGroupAttributeValues,
  useLazyQueryIntegrationGroupAttributeValues,
  useQueryIntegrationUserAttributeValues,
  useLazyQueryIntegrationUserAttributeValues,
  useQueryIntegrationDataSourceValues,
}
