import React from "react"
import ReactPlayer from "react-player/lazy"
import Config from "react-global-configuration"
import { Trans } from "@lingui/macro"
import { Box, Button, makeStyles, Tooltip } from "@material-ui/core"
import { Icon } from "../Icon"
import { ColumnBox } from "../Boxes"
import { useDeviceUtils } from "../../utils"

const useStyles = makeStyles((theme) => ({
  externalLink: {
    flexGrow: 1,
    background: theme.palette.grey[25],
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[300]}`,
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
    textAlign: "center",
    padding: theme.spacing(3),
  },
  videoWrapper: {
    position: "relative",
    paddingTop: "56.25%", // 16:9 aspect ratio (9/16 = 0.5625 or 56.25%)
    width: "100%",
    maxWidth: "100%",
  },
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
  },
}))

const ReactLoomPlayer = ({ src }) => {
  const classes = useStyles()
  return (
    <div className={classes.videoWrapper}>
      <iframe allowFullScreen title="Loom iframe" src={src} className={classes.responsiveIframe} />
    </div>
  )
}

const tryGetUrl = (value) => {
  try {
    return new URL(value)
  } catch {
    return null
  }
}

const MediaPlayer = ({ value, controls = true, ...rest }) => {
  const classes = useStyles()
  const {
    media: { sources },
  } = Config.get()
  const { isDeviceClient } = useDeviceUtils()

  const supported = sources.flatMap((source) => source.hosts)

  const url = tryGetUrl(value)

  const fallback = (
    <ColumnBox className={classes.externalLink} gap={1}>
      <Trans>This media can't be embedded</Trans>
      <Tooltip title={value}>
        <Button href={value} target="_blank" rel="noopener" variant="contained">
          <Icon name="external-link" />
          <Box ml={1}>
            {isDeviceClient && <Trans>Play media in web browser</Trans>}
            {!isDeviceClient && <Trans>Play media in new tab</Trans>}
          </Box>
        </Button>
      </Tooltip>
    </ColumnBox>
  )

  if (!url) {
    return <Trans>Unable to display video player, media URL is invalid.</Trans>
  }

  // Special case for Loom
  if (url.host.endsWith("loom.com")) {
    const embedUrl = value.replace("share", "embed")
    return <ReactLoomPlayer src={embedUrl} />
  }

  // Special case for YouTube Clips that are not currently embeddable
  if (url.host.endsWith("youtube.com") && url.pathname.startsWith("/clip")) {
    return fallback
  }

  // Other supported sources
  if (supported.includes(url.host)) {
    return (
      <div className={classes.videoWrapper}>
        <ReactPlayer
          url={value}
          controls={controls}
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
          {...rest}
        />
      </div>
    )
  }

  // Fallback to open link
  return fallback
}

export { MediaPlayer }
