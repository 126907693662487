import React, { useState, useEffect, useRef } from "react"
import { Box, useMediaQuery, InputAdornment, TextField, IconButton, Tooltip } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/styles"
import SearchIcon from "@material-ui/icons/Search"
import { Trans, t } from "@lingui/macro"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { useLazyQuerySearch } from "../../data"
import { toId } from "../../utils"
import SearchPopover from "../SearchPopover"
import { useSearchState } from "../../data/search/searchStateVar"

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  form: {
    borderRadius: 4,
    minHeight: 40,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: ({ isTablet }) => (isTablet ? 250 : 400),
  },
  input: {
    color: theme.palette.text.primary,
    display: "flex",
    justifySelf: "stretch",
  },
  type: {
    padding: theme.spacing(2),
  },
  results: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    width: ({ isTablet }) => (isTablet ? "100%" : 800),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    height: "fit-content",
    borderRadius: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  trayIconButton: {
    marginRight: 0,
    padding: ({ smDown }) => (smDown ? theme.spacing(0.5) : theme.spacing(1)),
    backgroundColor: theme.palette.grey[50],
    "&:hover": {
      background: theme.palette.action.hover,
      transition: "all 0.2s ease",
    },
    "&:focus": {
      background: theme.palette.action.focus,
      transition: "all 0.2s ease",
    },
  },
}))

const SearchInput = ({ onChange, cy = "SearchInput-input" }) => {
  const theme = useTheme()
  const { searchText: externalSearchText } = useSearchState()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles({ isTablet })
  const inputRef = useRef(null)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    // Clear the search text
    setSearchText("")
  }, [externalSearchText])

  const handleChange = (e) => {
    setSearchText(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <>
      {!isMobile ? (
        <Box className={classes.form}>
          <TextField
            inputRef={inputRef}
            placeholder={t`Search...`}
            value={searchText}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            className={classes.input}
            fullWidth
            data-cy={cy}
            variant="outlined"
            size="small"
          />
        </Box>
      ) : (
        <Box>
          <Tooltip title={<Trans>Search</Trans>}>
            <IconButton onClick={handleChange} className={classes.trayIconButton}>
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  )
}

const SearchArea = () => {
  const { location } = useAuth()
  const { clientKiosk: kiosk } = Config.get()
  const { searchText, setSearchText, setSearchTextImmediate, focused, clearSearch } = useSearchState()
  const [load] = useLazyQuerySearch({
    fetchPolicy: "no-cache",
  })
  const searchAreaRef = useRef(null)
  const [globalArea, setGlobalArea] = useState(null)
  const [more, setMore] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isTablet = useMediaQuery(theme.breakpoints.down("sm"))

  useEffect(() => {
    if (searchText.trim()) {
      load({
        variables: { searchText, location: toId(location), limit: 9, filter: { client: kiosk ? "kiosk" : "" } },
      })
    }
  }, [searchText, location, load, kiosk])

  useEffect(() => {
    if (globalArea && more !== globalArea) {
      setMore(globalArea)
    }
    if (globalArea) setGlobalArea("")
  }, [globalArea, setGlobalArea, more, setMore])

  const handleSearchChange = (value, immediate) => {
    // Keep popover open and show historic searches
    if (!value) {
      clearSearch()
      return
    }

    // Otherwise carry on with search text update
    if (value.trim() && value !== searchText) {
      if (immediate) {
        setSearchTextImmediate(value)
      } else {
        setSearchText(value)
      }
    }
  }

  const handleClose = () => {
    setSearchText("")
  }

  const open = Boolean(searchText) || focused

  return (
    <Box ref={searchAreaRef} position="relative">
      <SearchInput onChange={handleSearchChange} cy="SearchArea-SearchInput-input" />

      <SearchPopover
        open={open}
        anchorEl={searchAreaRef.current}
        searchText={searchText}
        onSearchChange={handleSearchChange}
        onClose={handleClose}
        isTablet={isTablet}
        isMobile={isMobile}
      />
    </Box>
  )
}

export default SearchArea
