import { useLazyQuery, gql } from "@apollo/client"
import { SEARCH_FIELDS, JOB_SEARCH_FIELDS, ACTION_SEARCH_FIELDS } from "./fragments/searchFieldsFragment"
import { getJobStatus } from "../../utils/useJobUtils"

const SEARCH_LIMIT = 20

const SEARCH_QUERY = gql`
  query SearchAreas($limit: Int!, $searchText: String!, $location: ID!, $filter: SearchFilter) {
    searchAreas(limit: $limit, searchText: $searchText, location: $location, filter: $filter) {
      keywords
      jobs {
        total
        items {
          ...JobSearchFields
        }
      }
      knowledge {
        total
        items {
          ...SearchFields
        }
      }
      posts {
        total
        items {
          ...SearchFields
        }
      }
      actions {
        total
        items {
          ...ActionSearchFields
        }
      }
    }
  }
  ${SEARCH_FIELDS}
  ${JOB_SEARCH_FIELDS}
  ${ACTION_SEARCH_FIELDS}
`

const useLazyQuerySearch = (options) => {
  const [load, result] = useLazyQuery(SEARCH_QUERY, {
    variables: {
      limit: SEARCH_LIMIT,
    },
    ...options,
  })

  const transformedResult = {
    ...result,
    data: result.data && {
      ...result.data,
      searchAreas: {
        ...result.data.searchAreas,
        jobs: result.data.searchAreas?.jobs && {
          ...result.data.searchAreas.jobs,
          items: result.data.searchAreas.jobs.items.map((job) => ({
            ...job,
            jobStatus: getJobStatus(job),
          })),
        },
      },
    },
  }

  return [load, transformedResult]
}

export { useLazyQuerySearch }
