import { useEffect, useState } from "react"
import { Box, makeStyles } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { DOWNLOAD_STATUS, useSubscriptionDownloadUpdated } from "../../data"
import { Icon, useSnackbar, LinkButton } from ".."
import { toId } from "../../utils"
import { useDownload } from "../../utils/useDownload"

const useStyles = makeStyles((theme) => ({
  iconButtonWrapper: {
    position: "relative",
  },
  circularProgress: {
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    cursor: "pointer",
  },
  badge: {
    "& .MuiBadge-badge": {
      right: 2,
      top: 4,
      fontWeight: "600",
      border: `2px solid ${theme.palette.background.paper}`,
      padding: theme.spacing(0.5, 1),
    },
  },
  snackbarLink: {
    color: theme.palette.text.white,
    marginLeft: 10,
  },
}))

const useDownloadReadySnackbar = () => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { downloadFile } = useDownload()
  const { data: downloadUpdatedData } = useSubscriptionDownloadUpdated()
  const [downloadNotified, setDownloadNotified] = useState(null)

  useEffect(() => {
    if (!downloadUpdatedData?.downloadUpdated) {
      return
    }

    const download = downloadUpdatedData.downloadUpdated
    if (download.status !== DOWNLOAD_STATUS.COMPLETED || toId(download) === downloadNotified) {
      return
    }

    setDownloadNotified(toId(download))

    snackbar.showMessage({
      message: (
        <Box>
          <Trans>Download ready</Trans>{" "}
          <LinkButton
            onClick={() =>
              downloadFile({
                ...download,
                url: download?.path,
              })
            }
            className={classes.snackbarLink}
          >
            Download
          </LinkButton>
        </Box>
      ),
      icon: <Icon name="download" />,
    })
  }, [classes.snackbarLink, downloadFile, downloadNotified, downloadUpdatedData, snackbar])
}

export { useDownloadReadySnackbar }
