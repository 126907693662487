import moment from "moment-timezone"
import { t } from "@lingui/macro"
import { repeatProcessData, PROCESS_TYPE } from "../data"
import { PROCESS_REPEAT, PROCESS_SCHEDULE_UNTIL } from "../data/enums"
import { useAuth } from "../services"
import { toId } from "./data"
import { useDateUtils } from "./useDateUtils"

const { NEVER, DAILY, WEEKLY, ADHOC, UNSCHEDULED } = PROCESS_REPEAT

const getRepeatProcessTypeData = (type) => repeatProcessData.find((item) => item.id === type)

const executeOrReturn = (value) => (typeof value === "function" ? value() : value)

const getRepeatDisplayText = (repeat) => {
  if (repeat) {
    const type = getRepeatProcessTypeData(repeat.type)
    const days = type.id === WEEKLY ? type.data.filter((item) => repeat.weekdays[item.id]) : ""
    const frequency = repeat.frequency
    const showFrequency = frequency > 1

    return `${showFrequency ? `${frequency} ` : ""}${showFrequency ? type.plural : type.label}${
      days && ` on ${days.map((day) => (days.length > 3 ? day.label.substring(0, 3) : day.label)).join(", ")}`
    }`
  }
}

const getRepeatDisplayShortText = (repeat) => {
  if (repeat) {
    const type = getRepeatProcessTypeData(repeat.type)
    const days = type.id === WEEKLY ? type.data.filter((item) => repeat.weekdays[item.id]) : ""
    const frequency = repeat.frequency
    const showFrequency = frequency > 1

    if (type.id === WEEKLY && days?.length === 1) {
      return `${days[0].label}s`
    }
    if (type.id === WEEKLY && days?.length === 2) {
      return days.map(({ shortLabel }) => shortLabel).join(" & ")
    }
    if (type.id === WEEKLY && days?.length === 7) {
      return getRepeatProcessTypeData(DAILY).label
    }

    return `${showFrequency ? `${frequency} ` : ""}${showFrequency ? type.plural : executeOrReturn(type.label)}${
      days.length ? ` (${days.length} day${days.length > 1 ? "s" : ""})` : ""
    }`
  }
}

const getFriendlySetName = (set) => {
  if (set === "any") return t`Any jobs`
  if (set === "active") return t`Active jobs`
  if (set === "active-recurring") return t`Active recurring jobs`
  if (set === "active-oneoff") return t`Active one-off jobs`
  if (set === "active-adhoc") return t`Active ad hoc jobs`
  if (set === "active-unscheduled") return t`Active unscheduled jobs`
  if (set === "inactive") return t`Inactive jobs`
  if (set === "inactive-recurring") return t`Inactive recurring jobs`
  if (set === "inactive-oneoff") return t`Inactive one-off jobs`
  if (set === "inactive-adhoc") return t`Inactive ad hoc jobs`
  if (set === "inactive-unscheduled") return t`Inactive unscheduled jobs`
  return t`Unknown set`
}

const canScheduleHaveNextInstanceUntilType = (type) => ![UNSCHEDULED, NEVER, DAILY, ADHOC].includes(type)

const isScheduleValid = (schedule, processType) => {
  const { repeat, after, hasEveryone, users, groups } = schedule

  const isAssignToValid =
    processType !== PROCESS_TYPE.PUBLIC ? hasEveryone || users.length > 0 || groups.length > 0 : true

  const isRepeatValid = Boolean(repeat) && (repeat.type === PROCESS_REPEAT.ADHOC || after)

  return isRepeatValid && isAssignToValid
}

const isPeriodProcessUntilType = (type) =>
  [PROCESS_SCHEDULE_UNTIL.MINUTES, PROCESS_SCHEDULE_UNTIL.HOURS, PROCESS_SCHEDULE_UNTIL.DAYS].includes(type)

const getProcessUntilTypeDescription = ({ until }) => {
  if (!until) {
    return null
  }
  const { type, period } = until

  switch (type) {
    case PROCESS_SCHEDULE_UNTIL.COMPLETED:
      return "Until completed"
    case PROCESS_SCHEDULE_UNTIL.DAYS:
      return `${period} days`
    case PROCESS_SCHEDULE_UNTIL.HOURS:
      return `${period} hours`
    case PROCESS_SCHEDULE_UNTIL.MINUTES:
      return `${period} minutes`
    case PROCESS_SCHEDULE_UNTIL.NEXT_INSTANCE:
      return "Until next instance"
    default:
      return null
  }
}

const useProcessUtils = () => {
  const { hasTime } = useDateUtils()
  const { hasFeature } = useAuth()

  const canScheduleOnlyPinPeriodTypes = (type) => hasFeature("process_pinuntil") && [DAILY, WEEKLY].includes(type)

  const getScheduleDisplayText = (process) => {
    if (process && process.repeat) {
      const type = getRepeatProcessTypeData(process.repeat.type)
      if (process.repeat.type === ADHOC) return type.label

      let { frequency, weekdays, months } = process.repeat

      months = Object.keys(months).filter((month) => month !== "__typename" && months[month])

      const after = moment(process.after)
      const days = type.id === WEEKLY ? type.data.filter((item) => weekdays[item.id]) : ""
      const allDay = !hasTime(process.after)

      const forStart = `Starts ${after.format("dddd Do MMM YYYY")}${!allDay ? " at " : ""}${
        !allDay ? after.format("h:mmA") : ""
      }`

      const forFrequency = frequency > 1 ? `every ${frequency} ${type.plural.toLowerCase()}` : type.label.toLowerCase()
      const forRepeat = `${type.id !== NEVER ? "then " : ""}${forFrequency}`

      const forDays = days
        ? `${days && ` on ${days.map((day) => (days.length > 3 ? day.label.substring(0, 3) : day.label)).join(", ")}`}`
        : ""

      const forMonths = months.length
        ? ` in ${months.map((month) => `${month.substring(0, 1).toUpperCase()}${month.substring(1, 3)}`).join(", ")}`
        : ""

      return `${forStart}, ${forRepeat}${forDays}${forMonths}`
    }
  }

  const getNavigateToLink = (template, set, action) => `/templates/${set}/${toId(template)}/${action}`

  const getNavigateToTemplateLink = (template, set, category, action) => {
    if (category) return `/templates/${set || template.statusSet}/${category}/${toId(template)}/${action}`
    return `/templates/${set || template.statusSet}/${toId(template)}/${action}`
  }

  return {
    canScheduleOnlyPinPeriodTypes,
    canScheduleHaveNextInstanceUntilType,
    isPeriodProcessUntilType,
    isScheduleValid,

    getNavigateToLink,
    getNavigateToTemplateLink,
    getRepeatProcessTypeData,
    getRepeatDisplayText,
    getRepeatDisplayShortText,
    getScheduleDisplayText,
    getProcessUntilTypeDescription,
    getFriendlySetName,
  }
}

export { useProcessUtils, getRepeatDisplayText, getRepeatDisplayShortText, isScheduleValid }
