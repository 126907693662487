import { gql } from "@apollo/client"
import { JOB_FIELDS } from "../../jobs/fragments/jobFieldsFragment"
import { KNOWLEDGE_FIELDS } from "../../knowledge"

const POST_FIELDS = gql`
  fragment PostFields on Post {
    id
    title
    content
    hasConfirm
    hasSentConfirms
    hasComments
    visibleFrom
    mediaUrl
    locations {
      id
      name
    }
    users {
      id
      fullName
    }
    groups {
      id
      name
    }
    uploads {
      id
      key
      fileName
      fileGroup
      fileSize
    }
    job {
      ...JobFields
    }
    knowledge {
      ...KnowledgeFields
    }
    confirmed {
      id
      confirmed
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          key
        }
        locations {
          id
          name
        }
      }
    }
    unconfirmed {
      id
      confirmed
      user {
        id
        firstName
        lastName
        fullName
        avatar {
          key
        }
        locations {
          id
          name
        }
      }
    }
    author {
      id
      firstName
      lastName
      fullName
      avatar {
        key
      }
    }
    comments {
      id
      content
      uploads {
        id
        key
        fileName
        fileGroup
        fileSize
      }
      author {
        id
        firstName
        lastName
        avatar {
          key
        }
      }
      createdAt
    }
    createdAt
    updatedAt
  }
  ${JOB_FIELDS}
  ${KNOWLEDGE_FIELDS}
`

export { POST_FIELDS }
