import React from "react"
import { Button } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { RequirePermissions } from ".."
import { useAuth } from "../../services"

const DesktopCreateProcessButton = ({ onCreateClick }) => {
  const { permissionGroups } = useAuth()

  const handleCreateClick = (type) => {
    onCreateClick(type)
  }

  return (
    <RequirePermissions requires={permissionGroups.createAudits}>
      <Button
        onClick={() => handleCreateClick("audit")}
        variant="contained"
        color="primary"
        data-cy="Button-create-audit"
      >
        <Trans>Create job</Trans>
      </Button>
    </RequirePermissions>
  )
}

export { DesktopCreateProcessButton }
