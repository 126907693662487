import { gql } from "@apollo/client"

const TIMER_FIELDS = gql`
  fragment TimerFields on Timer {
    id
    name
    foodItem {
      id
      name
      image {
        id
        key
        fileName
        fileGroup
        fileSize
      }
    }
    status
    startedAt
    breaks {
      pausedAt
      resumedAt
    }
    totalSecondsElapsed
    locations {
      id
    }
  }
`

const TIMER_CHANGED_FIELDS = gql`
  fragment TimerChangedFields on Timer {
    id
    status
    startedAt
    breaks {
      pausedAt
      resumedAt
    }
    totalSecondsElapsed
  }
`
export { TIMER_FIELDS, TIMER_CHANGED_FIELDS }
