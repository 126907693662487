import moment from "moment"
import Config from "react-global-configuration"
import { OidcClient } from "oidc-client"
import { useLocation } from "react-router-dom"
import * as Sentry from "@sentry/browser"
import mixpanel from "mixpanel-browser"
import { useState } from "react"
import {
  queryLoginWithUsernameAndPassword,
  mutationRequestPasscode,
  queryVerifyPasscode,
  mutationResetPassword,
  queryRefreshToken,
  queryLogout,
  queryLoginWithGoogle,
  queryLoginWithMicrosoft,
  useMutationUpdateSelf,
  fetchIdentityProvider,
  queryLoginWithExternal,
  OPENID_PROMPT,
  authStateVar,
  authedVar,
  useAuthState,
  resetStateVars,
  useMutationUpdateTabletPin,
} from "../data"
import { mapToIds, toId, useLocalStorage, useMountAwareReactiveVar } from "../utils"
import { isOperandioSupportUser } from "../utils/identification"

const permissionGroups = {
  readJobs: ["jobprocess_read_self", "jobprocess_read_all"],

  readKnowledge: ["category_read", "knowledge_read"],

  readTemplates: ["process_read_all", "process_read_locations"],

  readReports: ["process_read_all", "process_read_locations"],

  readUsers: ["user_read_admin", "user_read_admin_locations"],

  adminTraining: ["training_create", "training_update_all", "training_update_author"],

  adminAccreditations: ["accreditation_create", "accreditation_update"],

  adminAccount: [
    "organisation_update",
    "billing_read",
    "billing_update",
    "devices_update_locations",
    "devices_update_all",
    "devices_delete",
  ],

  createActions: ["action_create"],

  createAssets: ["asset_create"],

  createAudits: ["process_create"],

  createFoodItems: ["food_item_create"],

  createJobs: ["process_create", "jobprocess_create_adhoc"],

  createKnowledge: ["knowledge_create"],

  createLabels: ["label_update", "label_update_all"],

  createLabelTemplates: ["label_template_update", "label_template_update_all"],

  createPost: ["post_create"],

  createPrep: ["prep_update"],

  createSuppliers: ["supplier_create"],

  createTimers: ["timer_create"],

  createTraining: ["training_create"],
}

const featureDependencies = {
  prep: ["prep", "food"],
  timers: ["timers", "food"],
}

const externalAuthingPaths = ["/google", "/signup/google", "/microsoft", "/signup/microsoft"]

let refreshInterval

const MFA_ERROR_CODE = "MULTI_FACTOR_AUTHENTICATION_REQUIRED"

const useAuth = (client) => {
  const { setAuthed, setPrincipal, updatePrincipal, setLocation, reset, clearFirstLogin, getAuthToken } = useAuthState()
  const [updateTabletPin] = useMutationUpdateTabletPin()
  const authed = useMountAwareReactiveVar(authedVar)
  const authState = useMountAwareReactiveVar(authStateVar)
  const { principal, settings, location, reason, firstLogin } = authState
  const [updateSelf] = useMutationUpdateSelf()
  const windowLocation = useLocation()
  const [storedIdp, setStoredIdp, removeStoredIdp] = useLocalStorage("idp", null, false)
  const [mfaRequired, setMfaRequired] = useState(false)

  const config = Config.get()
  const {
    clientBaseUri,
    deviceBaseUri,
    clientKiosk,
    clientDevice,
    links: {
      kiosk: { logout: kioskLogoutLink, login: kioskLoginLink },
      device: { logout: deviceLogoutLink, login: deviceLoginLink },
    },
    authProviders: {
      external: { redirect_uri: externalRedirectUri },
    },
  } = config

  const loginWithUsernameAndPassword = async ({ credentials }) => {
    resetStateVars()
    try {
      const response = await queryLoginWithUsernameAndPassword(credentials, client)

      // Check if MFA is required
      if (response && response.errors?.find((error) => error.extensions?.code === MFA_ERROR_CODE)) {
        setMfaRequired(true)
        return { mfaRequired: true }
      }

      // Check if there are any errors
      if (response?.errors && response.errors.length > 0) {
        throw new Error(response.errors.map((err) => err.message).join(". "))
      }

      // Check if there is no data
      if (!response.data) {
        throw new Error("Connectivity issue, unable to log you in")
      }

      const { loginWithUsernameAndPassword: loginData } = response.data

      completeLogin(credentials, loginData)
    } catch (error) {
      stopRefreshing()
      logout()
      throw error
    }
  }

  const completeLogin = (credentials, loginData) => {
    setPrincipal({ username: credentials.username }, loginData)
    setAuthed(true)
    startRefreshing(client, loginData.ticket.expiry)
    setMfaRequired(false)
  }

  const createOidcSigninRequest = async (provider, redirectUriPrefix) => {
    const providerConfig = Config.get(`authProviders.${provider}`)
    const { login_redirect_uri, signup_redirect_uri, ...rest } = providerConfig
    const redirect_uri = providerConfig[`${redirectUriPrefix}_redirect_uri`]
    const oidcClient = new OidcClient({ redirect_uri, ...rest })
    const request = await oidcClient.createSigninRequest()
    window.location = request.url
  }

  const processOidcSigninResponse = async (provider) => {
    const providerConfig = Config.get(`authProviders.${provider}`)
    const { login_redirect_uri, signup_redirect_uri, ...rest } = providerConfig
    const redirect_uri = signup_redirect_uri
    const oidcClient = new OidcClient({ redirect_uri, ...rest })
    return oidcClient.processSigninResponse(window.location.href)
  }

  const loginWithExternal = async (unique) => {
    await logout()
    const { data, error } = await fetchIdentityProvider(unique)
    if (error) {
      if (storedIdp && unique === storedIdp.unique) {
        await removeStoredIdp()
      }
      throw Error(error)
    }
    const { browserPrompt: prompt, devicePrompt, ...identityProviderConfig } = data
    await setStoredIdp({ unique, ...data })
    const providerConfig = Config.get("authProviders.external")
    const oidcClient = new OidcClient({
      ...identityProviderConfig,
      ...providerConfig,
      prompt: prompt !== OPENID_PROMPT.DEFAULT ? prompt : null,
      redirect_uri: `${providerConfig.redirect_uri}/${unique}`,
    })
    const request = await oidcClient.createSigninRequest()
    window.location = request.url
  }

  const processExternalSigninResponse = async (identityProvider) => {
    const { id_token: idToken } = await processOidcSigninResponse("external")
    const response = await queryLoginWithExternal({ identityProvider, idToken }, client)
    if (response && response.errors && response.errors.length) {
      throw new Error(response.errors.map((err) => err.message).join(". "))
    } else if (!response.data) {
      throw new Error("Connectivity issue, unable to log you in")
    } else {
      const { loginWithExternal: loginData } = response.data
      setPrincipal({ username: loginData.ticket.username }, loginData)
      setAuthed(true)
      startRefreshing(client, loginData.ticket.expiry)
      return response
    }
  }

  const loginWithGoogle = async () => {
    createOidcSigninRequest("google", "login")
  }

  const signupWithGoogle = async () => {
    createOidcSigninRequest("google", "signup")
  }

  const processGoogleSigninResponse = async () => {
    const google = Config.get("authProviders.google")
    const googleClient = new OidcClient(google)
    const { id_token: idToken } = await googleClient.processSigninResponse(window.location.href)
    const response = await queryLoginWithGoogle({ idToken, client: "google" }, client)
    if (response && response.errors && response.errors.length) {
      throw new Error(response.errors.map((err) => err.message).join(". "))
    } else if (!response.data) {
      throw new Error("Connectivity issue, unable to log you in")
    } else {
      const { loginWithGoogle: loginData } = response.data
      setPrincipal({ username: loginData.ticket.username }, loginData)
      setAuthed(true)
      startRefreshing(client, loginData.ticket.expiry)
      return response
    }
  }

  const loginWithMicrosoft = async () => {
    createOidcSigninRequest("microsoft", "login")
  }

  const signupWithMicrosoft = async () => {
    createOidcSigninRequest("microsoft", "signup")
  }

  const processMicrosoftSigninResponse = async () => {
    const microsoft = Config.get("authProviders.microsoft")
    const microsoftClient = new OidcClient(microsoft)
    const { id_token: idToken } = await microsoftClient.processSigninResponse(window.location.href)
    const response = await queryLoginWithMicrosoft({ idToken }, client)
    if (response && response.errors && response.errors.length) {
      throw new Error(response.errors.map((err) => err.message).join(". "))
    } else if (!response.data) {
      throw new Error("Connectivity issue, unable to log you in")
    } else {
      const { loginWithMicrosoft: loginData } = response.data
      setPrincipal({ username: loginData.ticket.username }, loginData)
      setAuthed(true)
      startRefreshing(client, loginData.ticket.expiry)
      return response
    }
  }

  const processSignupResponse = async (provider) => processOidcSigninResponse(provider)

  const refresh = async (refreshClient, silent = true) => {
    console.log("[AUTH]", "Token refreshing")
    stopRefreshing()
    try {
      const response = await queryRefreshToken(refreshClient)
      if (response?.errors?.length) {
        if (response.errors[0].message === "No refresh token supplied") {
          console.log("[AUTH]", "No refresh token present")
          return
        }
        throw new Error(response.errors.map((err) => err.message).join(". "))
      } else if (response && !response.data) {
        throw new Error("Connectivity issue, unable to log you in")
      } else {
        const { refresh: loginData } = response.data
        setPrincipal({ username: loginData.ticket.username }, loginData, silent)
        setAuthed(true)
        startRefreshing(refreshClient, loginData.ticket.expiry)
        console.log("[AUTH]", `Token refreshed ${moment().format()}`)
        return true
      }
    } catch (err) {
      console.log("[AUTH]", "Token refresh error", err)
      Sentry.captureException(err)
      stopRefreshing()
      if (!isExternalAuthing()) logout()
    }
    return false
  }

  const isExternalAuthing = () => externalAuthingPaths.includes(windowLocation.pathname)

  const startRefreshing = async (refreshClient, expiry) => {
    if (!refreshClient || !expiry) throw new Error("Client and expiry must be supplied")

    const { auth } = Config.get()
    const diff = moment(expiry).diff(moment(), "milliseconds")
    const calculatedExpiryMs = Math.round(diff - Math.min(auth.refreshOffset || 180000, diff / 2))
    const expiryMs = Math.max(calculatedExpiryMs, auth.refreshMinimumMs, 1000 * 60 * 2)
    if (!calculatedExpiryMs || calculatedExpiryMs < 0) {
      const message = `No calculated or negative expiry time, falling back to default of ${expiryMs / 1000}sec`
      console.log("[AUTH]", message)
      Sentry.withScope((scope) => {
        scope.setExtras({ expiry, diff, calculatedExpiryMs })
        Sentry.captureMessage(message)
      })
    }
    refreshInterval = setInterval(async () => {
      refresh(refreshClient, true)
    }, expiryMs)
    console.log("[AUTH]", `Started token refresh interval for ${expiryMs / 1000}sec`)
  }

  const stopRefreshing = () => {
    clearInterval(refreshInterval)
  }

  const handleSetLocation = (newLocation) => {
    if (newLocation) {
      const settingLocation = settings.locations.find((item) => toId(item) === toId(newLocation))
      if (settingLocation) {
        setLocation(settingLocation)
        updateSelf({ variables: { location: toId(newLocation) } })
      }
    }
  }

  const handleUpdatePin = async (pin) => {
    await updateTabletPin({ variables: { input: { pin } } })
    await refresh(client, true)
  }

  const logout = async (logoutReason) => {
    if (!authed) return
    reset(logoutReason)
    if (client) {
      if (!clientDevice) {
        stopRefreshing()
        await client.clearStore()
        await queryLogout(client)
        resetStateVars()
      }
      mixpanel.reset()
      if (window.Beacon) window.Beacon("close")
      window.localStorage.setItem("logout", Date.now())
      if (clientKiosk) window.location = kioskLogoutLink
      if (clientDevice) window.location = deviceLogoutLink
    } else {
      stopRefreshing()
      throw Error("Client must be supplied to useAuth when you intend to call logout.")
    }
  }

  const syncLogout = () => {
    stopRefreshing()
    reset("Logged out in another window")
    client.clearStore()
    resetStateVars()
  }

  const addSyncLogoutListener = (onSyncLogout) => {
    window.addEventListener("storage", onSyncLogout)
    console.log("[AUTH]", "Added sync logout listener")
  }

  const removeSyncLogoutListener = (onSyncLogout) => {
    window.removeEventListener("storage", onSyncLogout)
    console.log("[AUTH]", "Removed sync logout listener")
  }

  const buildError = (error) =>
    new Error(
      error.graphQLErrors && error.graphQLErrors.length > 0
        ? error.graphQLErrors[0].message
        : "Unable to contact server. Please try again.",
    )

  const requestPasscode = async ({ username }) => {
    try {
      const response = await mutationRequestPasscode({ username }, client)
      return response
    } catch (error) {
      throw buildError(error)
    }
  }

  const verifyPasscode = async ({ username, passcode }) => {
    try {
      const response = await queryVerifyPasscode({ username, passcode }, client)
      return response
    } catch (error) {
      throw buildError(error)
    }
  }

  const resetPassword = async ({ username, passcode, password }) => {
    // try {
    const response = await mutationResetPassword({ username, passcode, password }, client)
    return response
    // } catch (error) {
    //   throw buildError(error)
    // }
  }

  const isCurrentUser = (user) => authed && toId(user, true) === principal.userID

  const isInRole = (roles, role) => roles.includes(role.toLowerCase())

  const isCurrentUserInRole = (role) => authed && isInRole(principal.roles, role)

  const requiredLambda = (required) => principal && principal.permissions && principal.permissions[required]

  const hasPermission = (requires, operator = "or") => {
    if (!authed) {
      return false
    }

    if (
      Array.isArray(requires) &&
      (operator === "or" ? requires.some(requiredLambda) : requires.every(requiredLambda))
    ) {
      return true
    }

    if (!Array.isArray(requires) && !!principal?.permissions?.[requires]) {
      return true
    }

    return false
  }

  const getUserLocation = (locationId) => {
    if (!authed) {
      return null
    }

    return settings?.locations?.find((userLocation) => toId(userLocation) === toId(locationId)) || null
  }

  const hasEveryLocation = (locations) => {
    if (!authed) {
      return false
    }

    const locationIds = mapToIds(settings.locations)

    return locations.every((l) => locationIds.includes(toId(l)))
  }

  const hasFeature = (features) =>
    authed &&
    (Array.isArray(features)
      ? features.every((feature) => !!settings.features[feature])
      : !!settings.features[features])

  const canCreateRoles = (roles) => {
    if (!authed || !roles) {
      return false
    }

    if (principal.permissions.user_update_all) {
      return true
    }

    return roles.every((role) => principal.createsRoles.includes(toId(role)))
  }

  const getIdentityProviderBrowserLoginLink = ({ unique }) => `${clientBaseUri}/login/${unique}`

  const getIdentityProviderBrowserLogoutLink = () => `${clientBaseUri}/logout`

  const getIdentityProviderDeviceLoginLink = ({ unique }) => `${deviceBaseUri}login-external/${unique}`

  const getIdentityProviderBrowserRedirectLink = ({ unique }) => `${externalRedirectUri}/${unique}`

  const getIdentityProviderDeviceRedirectLink = () => `${deviceBaseUri}oauth2redirect/external`

  const getDeviceLoginLink = () => (clientKiosk ? kioskLoginLink : deviceLoginLink)

  const token = getAuthToken()

  const cancelMfaRequired = async () => {
    setMfaRequired(false)
    await logout()
  }

  const isOperandioSupport = isOperandioSupportUser(principal?.email)

  return {
    authed,
    principal,
    updatePrincipal,
    settings,
    location,
    setLocation: handleSetLocation,
    loginWithUsernameAndPassword,
    loginWithExternal,
    loginWithGoogle,
    loginWithMicrosoft,
    firstLogin,
    clearFirstLogin,
    processGoogleSigninResponse,
    processMicrosoftSigninResponse,
    signupWithGoogle,
    signupWithMicrosoft,
    processExternalSigninResponse,
    processSignupResponse,
    logout,
    syncLogout,
    addSyncLogoutListener,
    removeSyncLogoutListener,
    refresh,
    requestPasscode,
    verifyPasscode,
    resetPassword,
    token,
    reason,
    isCurrentUser,
    isCurrentUserInRole,
    isInRole,
    isOperandioSupport,
    hasPermission,
    hasEveryLocation,
    getUserLocation,
    hasFeature,
    canCreateRoles,
    updatePin: handleUpdatePin,
    forceSetPin: authed && principal?.pinRequired,

    getIdentityProviderBrowserLoginLink,
    getIdentityProviderBrowserLogoutLink,
    getIdentityProviderDeviceLoginLink,
    getIdentityProviderBrowserRedirectLink,
    getIdentityProviderDeviceRedirectLink,
    getDeviceLoginLink,

    permissionGroups,
    featureDependencies,

    mfaRequired,
    cancelMfaRequired,
  }
}

export { useAuth, MFA_ERROR_CODE }
