import React from "react"
import { List } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import moment from "moment"
import { Trans } from "@lingui/macro"
import { ErrorBoundary, ColumnBox, LoadingSpinner, NoItemsMessage } from ".."
import { useMutationReadNotification } from "../../data"
import { toId, useMountAwareReactiveVar } from "../../utils"
import { newDeviceClientAvailableVar } from "../../data/newDeviceClientAvailableVar"
import { NotificationUpdateVersion } from "./NotificationUpdateVersion"
import { NotificationListItem } from "./NotificationListItem"

const useStyles = makeStyles((theme) => ({
  root: theme.cards.tableCard,
}))

const useItemStyles = makeStyles((theme) => ({
  item: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    "&:last-child": {
      borderBottom: "none",
    },
    "&:hover": {
      background: theme.palette.action.hover,
      outline: "none",
    },
    "&:focus": {
      background: theme.palette.action.focus,
      outline: "none",
    },
  },
  itemAvatar: {
    minWidth: 0,
  },
  itemIcon: {
    minWidth: 0,
  },
  itemMessage: {
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  itemLinkIcon: {
    minWidth: 0,
    color: theme.palette.text.primary,
  },
  age: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    fontWeight: "normal",
  },
  avatar: {
    width: 40,
    height: 40,
  },
}))

const NotificationList = ({ popover = true, loading, data, onNavigate, limit = 10, spinnerSize = 30 }) => {
  const classes = useStyles()
  const itemClasses = useItemStyles()
  const [readNotifications] = useMutationReadNotification()
  const hasNewDeviceClientVersion = useMountAwareReactiveVar(newDeviceClientAvailableVar)

  if (loading)
    return (
      <ColumnBox justifyContent="center" alignItems="center" height="100%">
        <LoadingSpinner size={spinnerSize} />
      </ColumnBox>
    )
  if (!data || (data && !data.notifications)) return null

  if (data && data.notifications && data.notifications.items && !data.notifications.items.length)
    return (
      <NoItemsMessage>
        <Trans>You don't currently have any notifications</Trans>
      </NoItemsMessage>
    )

  const handleClick = (notification) => {
    if (!notification.read) {
      readNotifications({
        variables: { ids: [notification.id] },
      })
    }
    if (onNavigate) onNavigate()
  }

  return (
    <ErrorBoundary>
      <List disablePadding className={!popover ? classes.root : null}>
        {hasNewDeviceClientVersion && <NotificationUpdateVersion classes={itemClasses} />}
        {(limit ? [...data.notifications.items].slice(0, limit) : [...data.notifications.items])
          .sort((a, b) => (moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1))
          .map((notification) => (
            <NotificationListItem
              key={toId(notification)}
              notification={notification}
              onClick={handleClick}
              classes={itemClasses}
            />
          ))}
      </List>
    </ErrorBoundary>
  )
}

export default NotificationList
