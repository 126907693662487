import React from "react"
import { Box, Collapse } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

const ProcessSuccessBlockquote = ({ show = false, mb = 3 }) => (
  <Collapse in={show}>
    <Box mb={mb}>
      <Alert severity="info">
        <AlertTitle>Running a job via a link</AlertTitle>
        Members of the public can complete this job with their mobile device using the QR codes or links below.
      </Alert>
    </Box>
  </Collapse>
)

export { ProcessSuccessBlockquote }
