import React, { useLayoutEffect, useRef } from "react"
import { Box, Button, Divider, Grid, makeStyles, useTheme } from "@material-ui/core"
import SignatureCanvas from "react-signature-canvas"
import { Trans } from "@lingui/macro"
import { ProcessCollapse } from "./ProcessCollapse"

const initialPanelWidth = 614
const panelHeight = 180

const useStyles = makeStyles((theme) => ({
  parent: {
    overflow: "hidden",
    position: "relative",
    height: panelHeight,
    backgroundColor: theme.palette.grey[50],
  },
  canvas: {
    position: "absolute",
    width: "100%",
  },
  image: {
    width: "100%",
  },
}))

const SignatureStep = ({
  expand,
  value,
  complete,
  onChange,
  buttonClass,
  isScheduled,
  isPreview,
  classes,
  collapseTimeout = "auto",
}) => {
  const theme = useTheme()
  const signatureCanvas = useRef()
  const widthRef = useRef(initialPanelWidth)
  const parentRef = useRef()
  const signatureClasses = useStyles()

  useLayoutEffect(() => {
    widthRef.current = parentRef.current?.offsetWidth ?? initialPanelWidth
  }, [])

  const handleChange = () => {
    const data = signatureCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    if (!data) {
      return
    }

    onChange && onChange(data)
  }

  const handleClear = () => {
    signatureCanvas.current.clear()
    onChange && onChange(null)
  }

  const handleRemove = () => {
    onChange && onChange(null)
  }

  const valid = true

  return (
    <ProcessCollapse in={expand} timeout={collapseTimeout}>
      <Divider className={classes.divider} />
      <Box py={1} px={2}>
        <Box ref={parentRef} mb={1} className={signatureClasses.parent}>
          {!complete && (
            <SignatureCanvas
              ref={signatureCanvas}
              penColor={theme.palette.text.primary}
              backgroundColor={theme.palette.grey[50]}
              canvasProps={{ width: widthRef.current, height: panelHeight, className: signatureClasses.canvas }}
            />
          )}
          {complete && <img src={value} alt="Signature" className={signatureClasses.image} />}
        </Box>
        {!complete && (
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button variant="contained" color="default" fullWidth className={buttonClass} onClick={handleClear}>
                <Trans context="Erase or remove">Clear</Trans>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="default"
                fullWidth
                className={buttonClass}
                disabled={!valid || isScheduled || isPreview}
                onClick={handleChange}
              >
                <Trans>Save</Trans>
              </Button>
            </Grid>
          </Grid>
        )}
        {complete && (
          <Button variant="contained" color="default" fullWidth className={buttonClass} onClick={handleRemove}>
            <Trans>Remove</Trans>
          </Button>
        )}
      </Box>
    </ProcessCollapse>
  )
}

export default SignatureStep
