import React from "react"
import { Box, makeStyles, useTheme, useMediaQuery, Button } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Icon, NavLinkItem } from "../../components"

const usePublicStyles = makeStyles((theme) => ({
  public: {
    height: "100vh",
    backgroundColor: theme.palette.grey[25],
    paddingTop: ({ xs }) => (xs ? theme.spacing(2) : theme.spacing(5)),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cancel: {
    color: theme.palette.text.primary,
  },
  cancelIcon: {
    marginRight: theme.spacing(1),
  },
}))

const PublicContainer = ({
  withCancel = false,
  cancelText = <Trans>Cancel</Trans>,
  cancelIcon = "cancel",
  cancelType = "navigate",
  cancelTo = "/",
  onCancel,
  children,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const classes = usePublicStyles({ xs })

  return (
    <Box display="flex" flexDirection="column" alignItems="center" className={classes.public}>
      {children}
      {withCancel && (
        <Box display="flex" justifyContent="center" mt={6}>
          {cancelType === "navigate" && (
            <NavLinkItem
              to={cancelTo}
              text={cancelText}
              icon={{ name: cancelIcon, className: classes.cancelIcon }}
              className={classes.cancel}
              onClick={onCancel}
              cy="cancel"
            />
          )}
          {cancelType === "button" && (
            <Button onClick={onCancel} className={classes.cancel} startIcon={<Icon name={cancelIcon} />}>
              {cancelText}
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}
export { PublicContainer }
