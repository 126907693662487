import React from "react"
import { makeStyles } from "@material-ui/styles"
import { JOB_STATUS, ACTION_STATUS } from "../../data"

const STATUS_COLORS = {
  // job status
  [JOB_STATUS.COMPLETED_ON_TIME]: (theme) => theme.palette.status.resolved,
  [JOB_STATUS.COMPLETED_LATE]: (theme) => theme.palette.status.resolved,
  [JOB_STATUS.IN_PROGRESS]: (theme) => theme.palette.status.in_progress,
  [JOB_STATUS.AWAITING_SUBMISSION]: (theme) => theme.palette.status.blocked,
  [JOB_STATUS.OVERDUE]: (theme) => theme.palette.status.unresolved,
  [JOB_STATUS.TODO]: (theme) => theme.palette.status.todo,
  //   action status
  [ACTION_STATUS.UNRESOLVED]: (theme) => theme.palette.status.unresolved,
  [ACTION_STATUS.BLOCKED]: (theme) => theme.palette.status.blocked,
  [ACTION_STATUS.RESOLVED]: (theme) => theme.palette.status.resolved,
  [ACTION_STATUS.CANCELLED]: (theme) => theme.palette.status.cancelled,
}

const allowedTypes = ["job", "actions"]

const useStyles = makeStyles((theme) => ({
  statusCircle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    display: "inline-block",
    marginRight: 4,
    flexShrink: 0,
  },
  statusColor: ({ status }) => {
    const colorFn = status && STATUS_COLORS[status.toLowerCase()]
    return {
      backgroundColor: colorFn ? colorFn(theme) : theme.palette.status.unresolved,
    }
  },
}))

const StatusCircle = ({ status, type }) => {
  const classes = useStyles({ status })

  if (!allowedTypes.includes(type)) return null

  return <div className={`${classes.statusCircle} ${classes.statusColor}`} />
}

export default StatusCircle
