import { useMutation, gql } from "@apollo/client"

const CREATE_TEMPORARY_TABLET_PIN_MUTATION = gql`
  mutation CreateTemporaryTabletPin($id: ID!) {
    adminableUser(id: $id) {
      createTemporaryTabletPin
    }
  }
`

const useMutationCreateTemporaryTabletPin = (options) => useMutation(CREATE_TEMPORARY_TABLET_PIN_MUTATION, options)

export { useMutationCreateTemporaryTabletPin }
