import { useMutation, gql } from "@apollo/client"
import { TIMER_CHANGED_FIELDS } from "./fragments/timerFieldsFragment"

const TIMER_PAUSE_MUTATION = gql`
  mutation TimerPause($id: ID!) {
    timer(id: $id) {
      pause {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const TIMER_RESUME_MUTATION = gql`
  mutation TimerResume($id: ID!) {
    timer(id: $id) {
      resume {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const TIMER_USED_UP_MUTATION = gql`
  mutation TimerUsedUp($id: ID!) {
    timer(id: $id) {
      usedUp {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const TIMER_LEFT_OUT_MUTATION = gql`
  mutation TimerLeftOut($id: ID!) {
    timer(id: $id) {
      leftOut {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const TIMER_DISMISS_MUTATION = gql`
  mutation TimerDismiss($id: ID!) {
    timer(id: $id) {
      dismiss {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const TIMER_END_MUTATION = gql`
  mutation TimerEnd($id: ID!) {
    timer(id: $id) {
      end {
        ...TimerChangedFields
      }
    }
  }
  ${TIMER_CHANGED_FIELDS}
`

const useMutationTimerPause = () => useMutation(TIMER_PAUSE_MUTATION)

const useMutationTimerResume = () => useMutation(TIMER_RESUME_MUTATION)

const useMutationTimerUsedUp = () => useMutation(TIMER_USED_UP_MUTATION)

const useMutationTimerLeftOut = () => useMutation(TIMER_LEFT_OUT_MUTATION)

const useMutationTimerDismiss = () => useMutation(TIMER_DISMISS_MUTATION)

const useMutationTimerEnd = () => useMutation(TIMER_END_MUTATION)

export {
  useMutationTimerPause,
  useMutationTimerResume,
  useMutationTimerLeftOut,
  useMutationTimerUsedUp,
  useMutationTimerDismiss,
  useMutationTimerEnd,
}
