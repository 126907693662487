import React, { useEffect, useState } from "react"
import { t, Trans } from "@lingui/macro"
import { Box, Button, makeStyles, Typography, useTheme } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { FieldSectionHeading } from "../Headings"
import { CreatorMaster } from "../Creators"
import {
  TIMER_STATUS,
  useMutationTimerEnd,
  useMutationTimerPause,
  useMutationTimerResume,
  useMutationTimerUsedUp,
  useQueryTimer,
} from "../../data"
import { useDateUtils, useTimerUtils } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { IntervalRenderer } from "../IntervalRenderer"
import { PulsingTimeSeparator } from "./PulsingTimeSeparator"

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 326,
  },
  readout: {
    color: ({ display: { color } }) => color || theme.palette.info.main,
    textAlign: "center",
    padding: theme.spacing(2),
    backgroundColor: ({ display: { backgroundColor } }) => backgroundColor || theme.palette.info.background,
    borderRadius: 8,
    border: ({ display: { color } }) => `2px solid ${color || theme.palette.info.main}`,
  },
  value: {
    fontSize: 56,
    fontWeight: 700,
    lineHeight: "100%",
  },
  message: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "25px",
  },
  jumboButtons: {
    width: "100%",
    height: "56px",
    display: "flex",
    flexShrink: 0,
  },
}))

const secondsOf4hrs = 60 * 60 * 4
const secondsOf2hrs = 60 * 60 * 2

const TimersItemEditor = ({ open, onClose, isInline }) => {
  const theme = useTheme()
  const { id } = useParams()
  const { formatShortTime, formatSecondsToH, formatSecondsToRemainderMM } = useDateUtils()
  const { totalSecondsElapsed } = useTimerUtils()

  const [display, setDisplay] = useState({
    message: t`Timer running`,
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.background,
  })
  const classes = useStyles({ display })

  const {
    data,
    loading: timerLoading,
    subscribe,
  } = useQueryTimer({
    variables: {
      id,
    },
  })

  const [pauseTimer, { loading: pauseTimerLoading }] = useMutationTimerPause()
  const [resumeTimer, { loading: resumeTimerLoading }] = useMutationTimerResume()
  const [usedUpTimer, { loading: usedUpTimerLoading }] = useMutationTimerUsedUp()
  const [endTimer, { loading: endTimerLoading }] = useMutationTimerEnd()

  const [unsubscribes, setUnsubscribes] = useState(null)

  useEffect(() => {
    if (!data?.timer) {
      return
    }

    if (data.timer.status === TIMER_STATUS.PAUSED) {
      setDisplay({
        message: t`Timer paused`,
        color: theme.palette.success.main,
        backgroundColor: theme.palette.success.background,
      })
    }

    if (data.timer.status === TIMER_STATUS.ACTIVE) {
      if (data.timer.totalSecondsElapsed > secondsOf2hrs) {
        setDisplay({
          message: t`Timer running`,
          color: theme.palette.scheduled.main,
          backgroundColor: theme.palette.warning.background,
        })
      }
      if (data.timer.totalSecondsElapsed > secondsOf4hrs) {
        setDisplay({
          message: t`Timer running`,
          color: theme.palette.error.main,
          backgroundColor: theme.palette.error.background,
        })
      }
    }

    if ([TIMER_STATUS.USED_UP, TIMER_STATUS.ENDED].includes(data.timer.status)) {
      handleClose()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data])

  useEffect(() => {
    if (!unsubscribes) {
      setUnsubscribes(subscribe())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [subscribe, unsubscribes, setUnsubscribes])

  const handleClose = () => {
    onClose && onClose()
  }

  const handlePauseTimer = async () => {
    await pauseTimer({
      variables: {
        id,
      },
    })

    handleClose()
  }

  const handleResumeTimer = async () => {
    await resumeTimer({
      variables: {
        id,
      },
    })

    handleClose()
  }

  const handleUsedUpTimer = async () => {
    await usedUpTimer({
      variables: {
        id,
      },
    })

    handleClose()
  }

  const handleEndTimer = async () => {
    await endTimer({
      variables: {
        id,
      },
    })

    handleClose()
  }

  const submitLoading = pauseTimerLoading || resumeTimerLoading || endTimerLoading || usedUpTimerLoading

  const title = t`Timer at ${data?.timer?.startedAt ? formatShortTime(data?.timer?.startedAt) : "..."}`

  const activeTimer = data?.timer?.status === TIMER_STATUS.ACTIVE

  const form = (
    <IntervalRenderer delay={activeTimer ? 1000 : 0}>
      {() => {
        const secondsElapsed = totalSecondsElapsed(data?.timer)

        return (
          <>
            <FieldSectionHeading>
              {data?.timer?.foodItem && (
                <>
                  {data.timer.foodItem.name} - {data.timer.name}
                  {data.name && ` - ${data.name}`}
                </>
              )}
            </FieldSectionHeading>
            <Box className={classes.readout} mb={2}>
              <Box className={classes.value}>
                {formatSecondsToH(secondsElapsed)}
                <PulsingTimeSeparator />
                {formatSecondsToRemainderMM(secondsElapsed)}
              </Box>
              <Box className={classes.message}>{display.message}</Box>
            </Box>
            <Box mb={2}>
              <Typography>
                <Trans>Select the action being taken with this food item.</Trans>
              </Typography>
            </Box>
            <Box mb={2} display="flex" flexDirection="column" gridGap={8}>
              {data?.timer?.status === TIMER_STATUS.PAUSED && (
                <Button
                  disabled={submitLoading}
                  variant="contained"
                  color="primary"
                  className={classes.jumboButtons}
                  onClick={handleResumeTimer}
                >
                  {!submitLoading && <Trans>Removed from fridge</Trans>}
                  {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              )}
              {data?.timer?.status === TIMER_STATUS.ACTIVE && data?.timer?.totalSecondsElapsed < secondsOf2hrs && (
                <Button
                  disabled={submitLoading}
                  variant="contained"
                  color="primary"
                  className={classes.jumboButtons}
                  onClick={handlePauseTimer}
                >
                  {!submitLoading && <Trans>Returned to fridge</Trans>}
                  {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              )}
              {data?.timer?.status === TIMER_STATUS.ACTIVE && data?.timer?.totalSecondsElapsed < secondsOf4hrs && (
                <Button
                  disabled={submitLoading}
                  variant="contained"
                  color="primary"
                  className={classes.jumboButtons}
                  onClick={handleUsedUpTimer}
                >
                  {!submitLoading && <Trans>Used up</Trans>}
                  {submitLoading && <LoadingSpinner size="24px" delay={false} />}
                </Button>
              )}
              <Button
                disabled={submitLoading}
                variant="contained"
                color="primary"
                className={classes.jumboButtons}
                onClick={handleEndTimer}
              >
                {!submitLoading && <Trans>Discarded</Trans>}
                {submitLoading && <LoadingSpinner size="24px" delay={false} />}
              </Button>
              <Button color="primary" className={classes.jumboButtons} onClick={handleClose}>
                <Trans>Cancel</Trans>
              </Button>
            </Box>
          </>
        )
      }}
    </IntervalRenderer>
  )

  return (
    <CreatorMaster
      loading={timerLoading}
      maxWidth="xs"
      id="TimersItemEditor"
      open={open}
      title={title}
      form={form}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { TimersItemEditor }
