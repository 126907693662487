import React, { useState } from "react"
import { Box, Button, makeStyles } from "@material-ui/core"
import { useHistory, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { Icon } from "../Icon"
import { NewActionMenu } from "./NewActionMenu"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    marginBottom: theme.spacing(1),
    width: "100%",
    position: ({ mobile }) => (mobile ? "fixed" : "relative"),
    bottom: ({ mobile }) => (mobile ? 80 : "auto"),
    paddingRight: ({ mobile }) => (mobile ? 40 : "auto"),
    zIndex: ({ mobile, anchorEl }) => (anchorEl ? 1500 : mobile ? 200 : "auto"),
    alignItems: ({ mobile }) => (mobile ? "flex-end" : "flex-start"),
    justifyContent: ({ mobile }) => (mobile ? "flex-end" : "flex-start"),
  },
  newAction: {
    display: "flex",
    flexGap: theme.spacing(1),
  },
  newActionExpanded: {
    transition: "0.2s all",
  },
  newActionCollapsed: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
    borderRadius: "50%",
    padding: "8px 21px",
    width: ({ mobile }) => (mobile ? 56 : 42),
    height: ({ mobile }) => (mobile ? 56 : 42),
    marginLeft: ({ mobile }) => (mobile ? 0 : "-2px"),
    minWidth: 0,
    transition: "0.2s all",
    transitionDelay: "0.1s",
  },
  icon: {
    marginLeft: ({ expanded }) => (expanded ? theme.spacing(0.5) : 0),
    transform: ({ anchorEl, mobile }) => (anchorEl && mobile ? "rotate(45deg)" : "rotate(0deg)"),
    transition: "0.2s all",
  },
}))

export const NewActionButton = ({ expanded, mobile, pinItems, requires }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles({ expanded, mobile, anchorEl })
  const history = useHistory()
  const location = useLocation()
  const { hasPermission } = useAuth()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (href) => {
    href && history.push(href)
    handleClose()
  }

  // Check if current path ends with '/edit' or '/new' and is displaying in the mobile view
  const isEditOrNewPage = (location.pathname.endsWith("/edit") || location.pathname.endsWith("/new")) && mobile

  // Don't render the button on edit or new pages
  if (isEditOrNewPage || (requires && !hasPermission(requires))) {
    return null
  }

  return (
    <>
      <Box className={classes.container}>
        <Button
          onClick={!anchorEl ? handleClick : handleClose}
          className={!expanded ? classes.newActionCollapsed : classes.newActionExpanded}
          fullWidth={expanded}
          size="large"
          variant="contained"
          color="primary"
          data-cy="Button-create-new"
        >
          {expanded && <Trans>New</Trans>}
          <Icon name="add" className={classes.icon} />
        </Button>
      </Box>

      <NewActionMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onItemClick={handleMenuItemClick}
        mobile={mobile}
        pinItems={pinItems}
      />
    </>
  )
}
