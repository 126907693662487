import React, { useEffect, useState } from "react"
import { HasActiveJobsChangedBlockquote } from ".."
import { useLazyQueryActiveProcessJobs } from "../../data"
import { useMountEffect, toId } from "../../utils"

const HasActiveJobsChanged = ({ edit, subject, hidden }) => {
  const [{ show, count }, setState] = useState({ show: false, count: 0 })
  const [load, { data }] = useLazyQueryActiveProcessJobs()

  useMountEffect(() => {
    if (edit) {
      load({ variables: { process: toId(edit) } })
    }
  })

  useEffect(() => {
    setState({
      show: data?.activeProcessJobs.length > 0 && !hidden && edit?.visibility !== "public",
      count: data?.activeProcessJobs.length || 0,
    })
  }, [data, hidden, edit])

  return <HasActiveJobsChangedBlockquote show={show} subject={subject} count={count} />
}

export { HasActiveJobsChanged }
