import React, { useState, useRef, useEffect, Fragment, useImperativeHandle } from "react"
import {
  makeStyles,
  TextField,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
} from "@material-ui/core"
import { Security, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"
import { useMountEffect } from "../../utils"
import { useLazyQueryCheckPinComplexity } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { ColumnBox, RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  input: {
    width: 40,
    marginRight: theme.spacing(1),
    background: theme.palette.background.default,
    "& input": {
      textAlign: "center",
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  separator: {
    width: theme.spacing(2),
    fontSize: 18,
    fontWeight: 800,
    color: theme.palette.grey[600],
  },
  errorText: {
    fontSize: 8,
    color: theme.palette.error.main,
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.light,
  },
}))

const PinInput = ({
  inputRef,
  alignItems = "left",
  length = 6,
  password = false,
  allowShow = false,
  onChange,
  clear = false,
  showLoading = true,
  autoFocus = false,
  disabled = false,
  disableComplexityCheck = false,
  cy,
}) => {
  const classes = useStyles()
  const [checkPinComplexity, { data, loading }] = useLazyQueryCheckPinComplexity()
  const [values, setValues] = useState([])
  const [show, setShow] = useState(false)
  const [errors, setErrors] = useState(null)
  const [focusedIndex, setFocusedIndex] = useState(null)
  const refs = useRef([])

  useImperativeHandle(inputRef, () => ({
    focus: () => {
      if (!refs.current?.input0) {
        return
      }

      refs.current.input0.focus()
    },
  }))

  useMountEffect(() => {
    setValues([...Array(length).keys()].map(() => ""))
  })

  useEffect(() => {
    if (!disableComplexityCheck) {
      const timer = setTimeout(() => {
        if (values.join("").length === length) checkPinComplexity({ variables: { pin: values.join("") } })
      }, 600)
      return () => clearTimeout(timer)
    }
  }, [checkPinComplexity, disableComplexityCheck, length, values])

  useEffect(() => {
    if (data) {
      onChange && onChange(data.checkPinComplexity.passed ? values.join("") : "")
    }
  }, [data, onChange, values])

  useEffect(() => {
    if (data) setErrors(data.checkPinComplexity.passed ? null : data.checkPinComplexity.errors)
  }, [data])

  useEffect(() => {
    if (clear) {
      setValues([...Array(length).keys()].map(() => ""))
    }
  }, [clear, length, values])

  const handleChange = ({ target: { value } }, index) => {
    if (value) {
      setValues((prev) => {
        prev[index] = value
        return [...prev]
      })
      if (refs.current[`input${index + 1}`]) refs.current[`input${index + 1}`].focus()
      else refs.current[`input${index}`].blur()

      if (disableComplexityCheck && values.join("").length === length) {
        onChange && onChange(values.join(""))
      }
    } else {
      setValues([...Array(length).keys()].map(() => ""))
      if (refs.current.input0) refs.current.input0.focus()
      onChange && onChange("")
    }
  }

  const handlePaste = (event) => {
    event.preventDefault()
    const paste = event.clipboardData.getData("text")
    if (paste.length === length) {
      setValues(paste.split(""))
      onChange && onChange(paste)
    }
  }

  const handleToggleShow = () => setShow(!show)

  const handleFocus = (index) => {
    setFocusedIndex(index)
  }

  const handleBlur = () => {
    setFocusedIndex(null)
  }

  const handleKeyDown = (event, index) => {
    if (event.key !== "Backspace") {
      return
    }

    refs.current[`input${Math.max(index - 1, 0)}`].focus()
  }

  const displayFields = () => {
    return [...Array(length).keys()].map((_, index) => {
      const currentItem = index + 1
      const isLastItem = currentItem === length
      const hasSeparator = length > 4 && index > 0 && !isLastItem && currentItem > 0 && currentItem % 3 === 0

      return (
        <Fragment key={index}>
          <TextField
            inputRef={(el) => (refs.current = { ...refs.current, [`input${index}`]: el })}
            type={password && !show ? "password" : "text"}
            variant="outlined"
            className={classes.input}
            placeholder={focusedIndex === index ? "" : "0"}
            inputProps={{
              pattern: "[0-9]*",
              inputMode: "numeric",
              maxLength: 1,
              "data-cy": `${cy}-${index}`,
            }}
            autoFocus={autoFocus && index === 0}
            value={values[index]}
            onChange={(event) => handleChange(event, index)}
            onPaste={(event) => handlePaste(event, index)}
            onFocus={() => handleFocus(index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onBlur={handleBlur}
            disabled={disabled}
          />
          {hasSeparator && <Box className={classes.separator} />}
        </Fragment>
      )
    })
  }

  if (values.length === 0) return null

  return (
    <form autoComplete="no-thanks">
      <ColumnBox alignItems={alignItems}>
        <Box mb={1}>
          <RowBox>
            {displayFields()}

            {allowShow && password && (
              <Box ml={-1}>
                <IconButton aria-label="toggle password visibility" onClick={handleToggleShow} disabled={disabled}>
                  {show ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                </IconButton>
              </Box>
            )}
            {showLoading && loading && (
              <Box>
                <LoadingSpinner size={24} />
              </Box>
            )}
          </RowBox>
        </Box>

        <Collapse in={errors !== null} timeout={1000}>
          <List dense disablePadding>
            {errors?.map((error, index) => (
              <ListItem key={index} className={classes.error} disableGutters>
                <ListItemAvatar>
                  <Avatar variant="rounded" className={classes.errorAvatar}>
                    <Security />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={error} className={classes.errorText} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </ColumnBox>
    </form>
  )
}

export { PinInput }
