import { ApolloClient, InMemoryCache, split, HttpLink, ApolloLink } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { setContext } from "@apollo/client/link/context"
import { RetryLink } from "@apollo/client/link/retry"
import { onError } from "@apollo/client/link/error"
import QueueLink from "apollo-link-queue"
import SerializeLink from "apollo-link-serialize"
import { WebSocketLink } from "@apollo/client/link/ws"
import * as Sentry from "@sentry/browser"
import version from "./Version"
import { typePolicies, possibleTypes, getAuthToken, resetAuth } from "../data"

class DataClient {
  onError = []

  registerErrorHandler = (key, handler) => {
    this.onError.push({ key, handler })
  }

  removeErrorHandler = (key) => {
    this.onError = this.onError.filter((item) => item.key !== key)
  }

  constructor(config) {
    const {
      httpServiceBaseUri,
      wsServiceBaseUri,
      dataClient: { retry, throwIfNoOperationName, webClientHeader, deviceClientHeader },
    } = config

    const cache = new InMemoryCache({
      possibleTypes,
      typePolicies,
    })

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: "cache-and-network",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "network-only",
        errorPolicy: "all",
      },
      mutate: {
        errorPolicy: "all",
      },
    }

    // assemble version request headers
    const getVersionHeaders = () => {
      const versionHeaders = {
        [webClientHeader]: version.webVersion,
      }
      if (window.deviceClientInfo) {
        versionHeaders[deviceClientHeader] = window.deviceClientInfo.app?.version || "unset"
      }
      return versionHeaders
    }

    // queue link & toggles
    const queueLink = new QueueLink()
    this.openQueue = () => queueLink.open()
    this.closeQueue = () => queueLink.close()

    // serialize link
    const serializeLink = new SerializeLink()

    // http link
    const httpLink = new HttpLink({
      uri: ({ operationName, ...context }) => {
        if (!operationName) {
          if (throwIfNoOperationName) {
            throw new Error("An operation name must be included for the query or mutation")
          }
          Sentry.withScope((scope) => {
            scope.setExtra("context", context)
            Sentry.captureMessage("An operation name must be included for the query or mutation")
          })
        }
        return `${httpServiceBaseUri}?operationName=${operationName}`
      },
      credentials: "include",
    })

    // http auth link
    const httpAuthLink = setContext(({ operationName }, { headers }) => {
      if (operationName === "Refresh") {
        return {
          headers: {
            ...headers,
            ...getVersionHeaders(),
          },
        }
      }
      const token = getAuthToken() // get auth token if present
      return {
        headers: {
          ...headers,
          ...getVersionHeaders(),
          Authorization: token ? `Bearer ${token}` : "",
        },
        fetchOptions: {
          credentials: "include",
        },
      }
    })

    // retry link
    const retryLink = new RetryLink({
      attempts: {
        retryIf: () => retry.enabled,
      },
    })

    // error link
    const errorLink = onError(({ graphQLErrors, networkError, operation, ...rest }) => {
      let isUnauthError = false
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              // unauth -> sign out
              isUnauthError = true
              if (getAuthToken()) {
                console.log("[DataClient]", "UNAUTHENTICATED", "Signing out")
                resetAuth("expired")
              }
              break
            case "NOT_FOUND":
            case "EXPIRED":
              // ignore
              break
            default:
              Sentry.captureException(err)
              break
          }
        }
      }

      // report network error to sentry
      if (!isUnauthError && networkError) Sentry.captureException(networkError)

      if ((graphQLErrors || networkError) && this.onError.length)
        this.onError.forEach((item) => item.handler({ graphQLErrors, networkError, operation, ...rest }))
    })

    // websocket link
    this.wsLink = new WebSocketLink({
      uri: wsServiceBaseUri,
      options: {
        reconnect: true,
        lazy: true,
        connectionCallback: (error) => {
          if (error) console.log("[WebSocketLink]", error)
        },
      },
    })

    // create my middleware using the applyMiddleware method from subscriptions-transport-ws
    const subscriptionMiddleware = {
      applyMiddleware: async (options, next) => {
        options.authToken = getAuthToken()
        next()
      },
    }

    // add the middleware to the web socket link via the Subscription Transport client
    this.wsLink.subscriptionClient.use([subscriptionMiddleware])

    // concat request links
    const requestLinks = ApolloLink.from([errorLink, queueLink, serializeLink, retryLink, httpAuthLink, httpLink])

    // split for ws and request links
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === "OperationDefinition" && definition.operation === "subscription"
      },
      this.wsLink,
      requestLinks,
    )

    // create apollo client
    this.client = new ApolloClient({ cache, link, defaultOptions })

    // concat offline transition links
    const offlineLinks = ApolloLink.from([errorLink, retryLink, httpAuthLink, httpLink])

    // create offline transition client
    this.offlineClient = new ApolloClient({ cache, link: offlineLinks, defaultOptions })
  }

  reset = () => {
    // clear store cache
    this.client.clearStore()

    // clear ws auth, disconnect and reconnect (will retry until login)
    const wsClient = this.wsLink.subscriptionClient
    wsClient.close()
    wsClient.connect()

    console.log("[OPSRV]", "Cleared / unsubscribed.")
  }
}

export default DataClient
