import { gql } from "@apollo/client"

const ADMINABLE_USERS_FIELDS = gql`
  fragment AdminableUsersFields on AdminableUser {
    id
    status
    invite {
      sentAt
      activatedAt
      expiresAt
    }
    usernameChange {
      sentAt
      expiresAt
    }
    firstName
    lastName
    fullName
    email
    isOperandioSupport @client
    avatar {
      key
    }
    locations {
      id
      name
    }
    groups {
      id
      name
    }
    roles {
      id
      friendlyName
    }
    settings {
      notifications {
        simple {
          midday
          evening
          action
          reminder
          overdue
          training
          posts
        }
        custom {
          id
          type
          name
          enabled
          cron
          locations {
            id
          }
        }
      }
    }
    security {
      totp {
        enabled
      }
    }
    attributes {
      id
      integration
      key
      value
    }
    notes {
      id
      text
      author {
        id
        fullName
        firstName
        lastName
        avatar {
          key
        }
      }
      createdAt
    }
    training {
      percentComplete
      status
      dueAt
      lastActiveAt
      assessments {
        id
        module {
          id
        }
        status
        trainedAt
        trainedBy {
          id
          fullName
        }
        comments
      }
    }
    accreditation {
      status
      statusAt
      accreditations {
        id
        accreditation {
          id
          name
          icon
        }
        uploads {
          id
          key
          fileName
          fileGroup
          fileSize
        }
        warningAt
        expiresAt
        status
        statusAt
        createdAt
      }
    }
    external
    identityProvider {
      id
      name
    }
    unique
    activeAt
  }
`

export { ADMINABLE_USERS_FIELDS }
