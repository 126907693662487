import React, { useState, useRef } from "react"
import { makeStyles, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { usePostUtils } from "../../utils"
import { Icon } from ".."
import { useSnackbar } from "../SnackbarProvider"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  shareButton: {
    textTransform: "none",
    flex: 1,
    justifyContent: "center",
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    color: theme.palette.text.main,
  },
  menuListItemIcon: {
    minWidth: "auto",
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  hiddenInput: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: 1,
    height: 1,
    opacity: 0,
  },
  whatsappIcon: {
    color: "#25D366",
  },
}))

const ShareButton = ({ post }) => {
  const classes = useStyles()
  const {
    settings: { organisation: organisationSettings },
  } = useAuth()
  const { getFullNavigateToLink } = usePostUtils()
  const { showMessage } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState(null)
  const linkInputRef = useRef(null)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleCopyLink = () => {
    const postUrl = getFullNavigateToLink(post)

    if (navigator?.clipboard && navigator?.clipboard?.writeText) {
      navigator?.clipboard
        .writeText(postUrl)
        .then(() => {
          showMessage({ message: <Trans>Link copied to your clipboard</Trans>, icon: <Icon name="clipboard" /> })
        })
        .catch(() => {
          showMessage({ message: <Trans>Failed to copy link</Trans>, icon: <Icon name="error" /> })
        })
    } else {
      showMessage({ message: <Trans>Failed to copy link</Trans>, icon: <Icon name="error" /> })
    }

    handleCloseMenu()
  }

  const getWhatsAppUrl = () => {
    const postUrl = getFullNavigateToLink(post)
    const MAX_WHATSAPP_LENGTH = 4096
    const urlLength = postUrl.length
    const titleLength = (post.title || "Check out this post!").length
    // Reserve space for title, URL, newlines and ellipsis
    const maxContentLength = Math.min(MAX_WHATSAPP_LENGTH - urlLength - titleLength - 8, 1000)

    let content = post?.content || ""
    if (content.length > maxContentLength) {
      content = `${content.substring(0, maxContentLength)}...`
    }

    let customText = ""
    if (post?.hasConfirm) {
      customText = t`CONFIRMATION REQUIRED on a post at`
    } else {
      customText = t`New post available at`
    }

    const message = `${customText} ${organisationSettings?.name}:\n\n*${post.title || "Check out this post!"}*\n\n${content}\n\n${postUrl}`

    return `https://wa.me/?text=${encodeURIComponent(message)}`
  }

  const whatsAppUrl = getWhatsAppUrl()

  // Option to share on whatsapp or copy link
  return (
    <>
      <IconButton onClick={handleOpenMenu} aria-label="share options">
        <Icon name="share" className={classes.icon} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem component="a" href={whatsAppUrl} target="_blank" onClick={handleCloseMenu}>
          <ListItemIcon className={classes.menuListItemIcon}>
            <Icon name="whatsapp" className={classes.whatsappIcon} />
          </ListItemIcon>
          <ListItemText primary={<Trans>Share to WhatsApp</Trans>} />
        </MenuItem>
        <MenuItem onClick={handleCopyLink}>
          <ListItemIcon className={classes.menuListItemIcon}>
            <Icon name="link" />
          </ListItemIcon>
          <ListItemText primary={<Trans>Copy link</Trans>} />
        </MenuItem>
      </Menu>

      <input
        ref={linkInputRef}
        type="text"
        defaultValue=""
        className={classes.hiddenInput}
        readOnly
        aria-hidden="true"
      />
    </>
  )
}

export { ShareButton }
