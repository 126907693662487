import { useQuery, gql, useLazyQuery } from "@apollo/client"
import { TRAINING_MODULE_FIELDS } from "./useQueryTrainingModules"

const TRAINING_COURSE_FIELDS = `
  id
  name
  dueDays
  modules {
    ${TRAINING_MODULE_FIELDS}
  }
  tags{
    id
    name
  }
  showUserScore
`

const TRAINING_COURSES_QUERY = gql`
  query TrainingCourses {
    trainingCourses {
      ${TRAINING_COURSE_FIELDS}
    }
  }
`

const trainingCourseTypePolicies = {
  TrainingCourse: {
    fields: {
      modules: {
        merge(prev, next) {
          return [...next]
        },
      },
    },
  },
}

const useQueryTrainingCourses = (options) => useQuery(TRAINING_COURSES_QUERY, options)

const useLazyQueryTrainingCourses = (options) => useLazyQuery(TRAINING_COURSES_QUERY, options)

export { useQueryTrainingCourses, useLazyQueryTrainingCourses, TRAINING_COURSE_FIELDS, trainingCourseTypePolicies }
