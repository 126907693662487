import React, { useState, useEffect } from "react"
import moment from "moment-timezone"
import Clock from "react-live-clock"
import Config from "react-global-configuration"
import { makeStyles } from "@material-ui/styles"
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Event as EventIcon,
} from "@material-ui/icons"
import { Container, Box, IconButton, Button } from "@material-ui/core"
import { Trans, t } from "@lingui/macro"
import { DatePicker, FormatDate, FormatDateCompact } from ".."
import { RowBox } from "../Boxes"
import { useQueryActionCounts, useQueryNotifications } from "../../data"
import { useAuth } from "../../services"
import { useDateUtils } from "../../utils"
import { useOffline } from "../Offline/useOffline"

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: `1px solid rgba(${theme.palette.primary.mainRgb},0.2)`,
    borderBottom: `1px solid rgba(${theme.palette.primary.mainRgb},0.2)`,
    backgroundColor: theme.palette.primary.backgroundLight,
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    height: 48,
    fontSize: 14,
    fontWeight: "600",
    lineHeight: "16px",
    position: "sticky",
    top: 0,
    zIndex: 99,
  },
  unconfirmed: {
    // // disabled for now
    // backgroundColor: theme.palette.error.dark,
  },
  time: {
    marginLeft: theme.spacing(1),
    opacity: 0.6,
  },
  icon: {
    marginRight: 12,
  },
}))

const DateNavigatorBar = ({ date, mode = "day", onDateNavigate }) => {
  const { offline } = useOffline()
  const { clientKiosk: kiosk } = Config.get()
  const { location, hasFeature } = useAuth()
  const hasTimesheets = hasFeature("timesheets")
  const classes = useStyles({ offline, punchClock: kiosk && hasTimesheets })
  const [open, setOpen] = useState(false)

  const { hasTimeOffsetFromUser, hasTimeOffsetFromMoment } = useDateUtils()

  const [isToday, setIsToday] = useState(false)
  const [isDifferentTimezone, setIsDifferentTimezone] = useState(false)
  const [unsubscribes, setUnsubscribes] = useState(null)

  const isDayMode = mode === "day"
  const isMonthMode = mode === "month"
  const isWeekMode = mode === "week"
  const momentDate = moment.tz(date, location.timeZone)

  const startOfThisWeek = moment().startOf("isoWeek")
  const endOfThisWeek = moment().endOf("isoWeek")
  const isThisWeek = isWeekMode && moment(momentDate).isBetween(startOfThisWeek, endOfThisWeek)

  const {
    called: calledNotifications,
    data: dataNotifications,
    subscribe: subscribeNotifications,
  } = useQueryNotifications()
  const { data: dataActionCounts } = useQueryActionCounts()

  useEffect(() => {
    setIsToday(moment.tz(date, location.timeZone).isSame(moment.tz(location.timeZone), "day"))
    setIsDifferentTimezone(hasTimeOffsetFromUser(location.timeZone))
  }, [date, hasTimeOffsetFromUser, location])

  useEffect(() => {
    if (calledNotifications && !unsubscribes) {
      setUnsubscribes(subscribeNotifications())
      return () => {
        if (unsubscribes) {
          unsubscribes.map((unsub) => unsub())
        }
      }
    }
  }, [calledNotifications, subscribeNotifications, unsubscribes, setUnsubscribes])

  const handlePickedDate = (value) => {
    if (!value || (hasTimeOffsetFromMoment(value, location.timeZone) && moment(value).isSame(moment(), "day"))) {
      // handle today
      onDateNavigate(moment().tz(location.timeZone).startOf("day"))
    } else {
      onDateNavigate(moment.tz(moment(value).format("YYYY-MM-DD"), location.timeZone))
    }
    setOpen(false)
  }

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      setOpen(true)
    }
  }

  const hasUnconfirmed =
    Boolean(dataNotifications?.notifications.unconfirmed) || Boolean(dataActionCounts?.actionCounts.unresolved)

  const className = `${classes.root} ${hasUnconfirmed ? classes.unconfirmed : ""}`

  return (
    <Box className={className} id="date-navigator-bar">
      <Container maxWidth={false}>
        <RowBox justifyContent="space-between">
          <Box>
            <IconButton
              aria-label="Navigate to yesterday"
              color="primary"
              size="small"
              onClick={() => onDateNavigate(-1)}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Box>
          <Button color="primary" variant="text" onClick={() => setOpen(true)} onKeyPress={handleEnter}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <EventIcon className={classes.icon} />
              {!isToday && isDayMode && <FormatDate value={momentDate} />}
              {isMonthMode && <>{moment.tz(moment(momentDate), location.timeZone).format("MMMM YYYY")}</>}
              {isToday && isDayMode && (
                <>
                  <Trans>Today</Trans>
                  <span className={classes.time}>
                    {isDifferentTimezone && <Clock timezone={location.timeZone} ticking format="h:mma z" />}
                    {!isDifferentTimezone && <Clock timezone={location.timeZone} ticking format="h:mma" />}
                  </span>
                </>
              )}
              {isThisWeek && <>This week</>}
              {isWeekMode && !isThisWeek && (
                <RowBox>
                  <FormatDateCompact value={moment(date).startOf("isoWeek")} />
                  <Box mx={0.5}>to</Box>
                  <FormatDateCompact value={moment(date).endOf("isoWeek")} />
                </RowBox>
              )}
            </Box>
          </Button>
          <Box>
            <IconButton
              size="small"
              color="primary"
              aria-label="Navigate to tomorrow"
              onClick={() => onDateNavigate(1)}
            >
              <ChevronRightIcon />
            </IconButton>
          </Box>
        </RowBox>

        <DatePicker
          open={open}
          variant="dialog"
          value={momentDate}
          onChange={(value) => handlePickedDate(value)}
          onClose={() => setOpen(false)}
          TextFieldComponent={() => null}
          clearLabel={isWeekMode ? t`This week` : isMonthMode ? t`This month` : t`Today`}
          clearable
          cancelLabel={t`Cancel`}
        />
      </Container>
    </Box>
  )
}

export { DateNavigatorBar }
