import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip } from "@material-ui/core"
import { t } from "@lingui/macro"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const CategoryLabelList = ({ activeFilter, onFilterClick, options, disabled }) => {
  const classes = useStyles()

  return (
    <RowBox p={2} gap={1} className={classes.root} flexWrap="wrap">
      {options?.map(({ id, label }) => (
        <Chip
          key={id}
          size="small"
          label={t`${label}`}
          color={activeFilter === id ? "primary" : "default"}
          onClick={() => onFilterClick(id)}
          clickable={!disabled}
        />
      ))}
    </RowBox>
  )
}

export default CategoryLabelList
