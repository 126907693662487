import React from "react"

const Icon = ({ primary = "#1A1B43", secondary = "url(#paint0_linear_1516_2977)" }) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 29C25.346 29 24 27.654 24 26C24 24.346 25.346 23 27 23C28.654 23 30 24.346 30 26C30 27.654 28.654 29 27 29ZM27 25C26.448 25 26 25.448 26 26C26 26.552 26.448 27 27 27C27.552 27 28 26.552 28 26C28 25.448 27.552 25 27 25Z"
      fill={secondary}
    />
    <path
      d="M27 21C25.346 21 24 19.654 24 18V6C24 4.346 25.346 3 27 3C28.654 3 30 4.346 30 6V18C30 19.654 28.654 21 27 21ZM27 5C26.448 5 26 5.449 26 6V18C26 18.552 26.448 19 27 19C27.552 19 28 18.552 28 18V6C28 5.449 27.552 5 27 5Z"
      fill={secondary}
    />
    <path
      d="M21.938 14V9C21.938 8.448 21.491 8 20.938 8H13.859L13.534 5.85C13.46 5.361 13.04 5 12.545 5H4V4C4 3.448 3.552 3 3 3C2.448 3 2 3.448 2 4V28C2 28.553 2.448 29 3 29C3.552 29 4 28.553 4 28V19H11.748L12.073 21.149C12.147 21.638 12.567 22 13.062 22H21C21.267 22 21.522 21.894 21.71 21.704C21.898 21.515 22.002 21.258 22 20.991L21.938 14ZM13.921 20L13.596 17.851C13.522 17.362 13.102 17 12.607 17H4V7H11.686L12.011 9.15C12.085 9.639 12.505 10 13 10H19.938L19.991 20H13.921Z"
      fill={primary}
    />
    <defs>
      <linearGradient id="paint0_linear_1516_2977" x1="30" y1="29" x2="30" y2="3" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FD9317" />
        <stop offset="1" stopColor="#F63B3B" />
      </linearGradient>
    </defs>
  </svg>
)

export default Icon
