import React from "react"
import { Box, IconButton, TextField, makeStyles } from "@material-ui/core"
import { t } from "@lingui/macro"
import { Icon, Uploader, UploadingImagesList, UploadingList } from ".."
import { RowBox, FlexBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    padding: theme.spacing(1),
  },
  formUploading: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    marginLeft: theme.spacing(0.5),
  },
  scrollContainer: {
    overflowX: "auto",
    overflowY: "hidden",
    width: "100%",
  },
  imageListWrapper: {
    "& .MuiGrid-container": {
      flexWrap: "nowrap",
      width: "max-content",
    },
    "& .MuiGrid-item": {
      width: 120, // Fixed width for each item
      flexShrink: 0,
    },
  },
}))

const NoteForm = ({
  text,
  onTextChange,
  onFocus,
  onEnter,
  hasNativeCamera,
  step,
  onDeviceUploaded,
  onItemUploaded,
  uploads,
  uploading,
  onRemoveUpload,
  onAdd,
  isValid,
  isEditing,
  onClearEditing,
  containerClassName,
}) => {
  const classes = useStyles()

  const handleDeviceUploaded = (item) => {
    onDeviceUploaded && onDeviceUploaded(item)
  }

  const handleItemUploaded = (item) => {
    onItemUploaded && onItemUploaded(item)
  }

  const handleRemoveUpload = (item) => {
    onRemoveUpload && onRemoveUpload(item)
  }

  const handleAdd = (event) => {
    onAdd && onAdd(event)
  }

  const handleFocus = (event) => {
    onFocus && onFocus(event)
  }

  const handleEnter = (event) => {
    onEnter && onEnter(event)
  }

  const handleTextChange = (event) => {
    onTextChange && onTextChange(event.target.value)
  }

  return (
    <>
      <RowBox className={containerClassName || classes.form}>
        <FlexBox mr={1} flexGrow={1}>
          <TextField
            size="small"
            placeholder={t`Note`}
            variant="outlined"
            value={text}
            onChange={handleTextChange}
            onKeyPress={handleEnter}
            onFocus={handleFocus}
            data-cy="AddNoteFooterButton-note"
            fullWidth
            autoFocus
          />
        </FlexBox>
        {hasNativeCamera && (
          <Uploader
            reference={`${step.id}_note`}
            icon={<Icon name="camera" />}
            cameraOnly
            onDeviceUploaded={handleDeviceUploaded}
            size="small"
          />
        )}
        <Uploader onItemUploaded={handleItemUploaded} size="small" />
        <IconButton
          onClick={handleAdd}
          color="primary"
          className={classes.submit}
          data-cy="AddNoteFooterButton-add"
          disabled={!isValid}
          size="small"
        >
          <Icon name="done" />
        </IconButton>
        {isEditing && (
          <IconButton onClick={onClearEditing} size="small">
            <Icon name="clear" />
          </IconButton>
        )}
      </RowBox>
      {uploads.length > 0 && (
        <Box pb={1} px={1} className={classes.formUploading}>
          <Box className={classes.scrollContainer}>
            <Box className={classes.imageListWrapper}>
              <UploadingImagesList
                uploaded={uploads}
                uploading={uploading}
                height={80}
                onRemoveUpload={handleRemoveUpload}
                gridItemProps={{ xs: "auto" }}
                collapseTimeout={0}
              />
            </Box>
          </Box>
          <UploadingList uploaded={uploads} uploading={uploading} onRemoveUpload={handleRemoveUpload} images={false} />
        </Box>
      )}
    </>
  )
}

export default NoteForm
