import { makeVar } from "@apollo/client"
import { useEffect } from "react"
import { useAuth } from "../../services"
import {
  useMutationPauseTimesheet,
  useMutationResumeTimesheet,
  useMutationStartTimesheet,
  useQueryTimesheet,
  useQueryTimesheetGroups,
} from "."
import { toId, useMountAwareReactiveVar } from "../../utils"
import { TIMESHEET_STATUS } from "../enums"

const { NOT_SCHEDULED, SCHEDULED } = TIMESHEET_STATUS

const pollInterval = 1000 * 10

const punchClockStateVar = makeVar({ show: false })

const usePunchClockState = () => {
  const state = useMountAwareReactiveVar(punchClockStateVar)

  const setShow = (show) => {
    if (show === state.show) {
      return // No change
    }

    punchClockStateVar({ ...state, show })
  }

  return [state, { setShow }]
}

const shouldShow = (data, groupsData) =>
  data && groupsData && Boolean(data.timesheet || data.shift || groupsData.timesheetGroups?.length)

const usePunchClock = () => {
  const [{ show }, { setShow }] = usePunchClockState()
  const { location } = useAuth()
  const {
    data,
    refetch,
    loading: timesheetLoading,
    stopPolling,
    startPolling,
  } = useQueryTimesheet({
    variables: { location: toId(location) },
    pollInterval,
  })
  const { data: groupsData, loading: groupsLoading } = useQueryTimesheetGroups({
    variables: { location: toId(location) },
    pollInterval,
  })
  const [start, { loading: startLoading }] = useMutationStartTimesheet()
  const [pause, { loading: pauseLoading }] = useMutationPauseTimesheet()
  const [resume, { loading: resumeLoading }] = useMutationResumeTimesheet()

  const status =
    (data && groupsData && (data.timesheet ? data.timesheet.status : data.shift ? SCHEDULED : NOT_SCHEDULED)) ||
    NOT_SCHEDULED

  useEffect(() => {
    if (!data || !groupsData) {
      return
    }

    setShow(shouldShow(data, groupsData))
  }, [data, groupsData, setShow])

  const loading = timesheetLoading || startLoading || pauseLoading || resumeLoading || groupsLoading

  const timesheet = data?.timesheet
  const shift = data?.shift
  const nextShift = data?.nextShift

  return {
    loading,
    timesheetLoading,
    startLoading,
    pauseLoading,
    resumeLoading,
    groupsLoading,

    show,
    status,

    startPolling,
    stopPolling,

    timesheet,
    shift,
    nextShift,

    start,
    pause,
    resume,
    refetch,
  }
}

export { usePunchClock, usePunchClockState }
