import React, { useState, useEffect, Suspense } from "react"
import moment from "moment-timezone"
import { useParams } from "react-router-dom"
import pluralize from "pluralize"
import {
  CircularProgress,
  TextField,
  Box,
  makeStyles,
  IconButton,
  MenuItem,
  Divider,
  Grid,
  ListSubheader,
} from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { CancelOutlined as CancelOutlinedIcon } from "@material-ui/icons"
import { Trans, t } from "@lingui/macro"
import {
  UploaderDropZone,
  OutlinedSelect,
  ColumnBox,
  ExpansionPanel,
  DraftBlockquote,
  LoadingSpinner,
  DisplayImage,
} from ".."
import {
  useMutationRegisterUpload,
  useMutationCreateLabelCategory,
  useMutationCreateLabel,
  useMutationUpdateLabel,
  useQueryLabelCategories,
  useQueryLabels,
  useQueryLabelTemplates,
  LABEL_TEMPLATE_FIELD_TYPE,
  DEMO_PROFILE,
} from "../../data"
import { generateDefault } from "../../data/labels/generateDefault"
import { mapToIds, toId, deepRemoveTypenames, useFormUtils, useMountEffect, useDraft, deepOmit } from "../../utils"
import { uploadToS3PresignedUrl } from "../../utils/s3"
import { CreatorActions, CreatorMaster } from "../Creators"
import { RowBox } from "../Boxes"
import Checkbox from "../Checkbox"
import { useAuth } from "../../services"
import { Icon } from "../Icon"
import { useDemonstration } from "../../utils/useDemonstration"
import { LabelsWarning } from "./LabelsWarning"

const LabelsViewer = React.lazy(() => import("./LabelsViewer"))

const initialState = {
  title: "",
  category: "",
  template: "",
  loadedTemplate: "",
  readyValue: 1,
  readyUnit: "hours",
  discardValue: 2,
  discardUnit: "hours",
  discardEod: false,
  locations: ["all"],
  dynamicCreateFields: [],
  dynamicBarcodeFields: [],
  fields: [],
  image: null,
  defaultImage: true,
}

const initialCategoryState = {
  name: "",
  categoryLocations: "",
}
const useStyles = makeStyles((theme) => ({
  content: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 4,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  expansionIcon: {
    marginRight: theme.spacing(1),
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  imageFrame: {
    padding: theme.spacing(1),
    background: theme.palette.grey[50],
    borderRadius: 12,
    display: "flex",
    justifyContent: "center",
    width: "200px",
    height: "132px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  detailGrid: {
    display: "grid",
  },
  detailGridItem: {
    overflow: "hidden",
  },
  imageDisplay: {
    borderRadius: 8,
    width: "200px",
  },
  uploadFrame: {
    display: "flex",
    width: "200px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  uploadDisplay: {
    padding: theme.spacing(1),
    fontSize: 14,
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  backing: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: "8px",
    alignItems: "center",
  },
  remove: {
    position: "absolute",
    right: 10,
    top: 10,
    backgroundColor: theme.palette.common.white,
    padding: 2,
    zIndex: 999,
    "&:hover": {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}))

const LabelsItemCreator = ({ open, onCreated, onClose, edit, isInline }) => {
  const classes = useStyles()
  const { id } = useParams()
  const { isValid } = useFormUtils()
  const { principal, location, hasFeature, hasPermission, settings } = useAuth()
  const { isDemo } = useDemonstration()

  // Data querying and mutations
  const [createLabel, { loading: createLabelLoading }] = useMutationCreateLabel()
  const [updateLabel, { loading: updateLabelLoading }] = useMutationUpdateLabel()
  const [createLabelCategory] = useMutationCreateLabelCategory()
  const { data: labelCategoryData } = useQueryLabelCategories({
    variables: { filter: { locations: toId(location) } },
  })
  const { data: labelTemplateData, loading: labelTemplateDataLoading } = useQueryLabelTemplates({
    variables: { filter: { locations: toId(location) } },
  })
  const { data: labelsData, loading: labelsDataLoading } = useQueryLabels({
    variables: { filter: { location: toId(location) } },
  })
  const [registerUpload] = useMutationRegisterUpload()
  const [loading, setLoading] = useState(false)

  // Label values
  const [title, setTitle] = useState(initialState.title)
  const [category, setCategory] = useState(initialState.category)
  const [template, setTemplate] = useState(initialState.template)
  const [categoryName, setCategoryName] = useState(initialCategoryState.name)
  const [categoryLocations, setCategoryLocations] = useState(initialCategoryState.categoryLocations)
  const [locations, setLocations] = useState(initialState.locations)
  const [fields, setFields] = useState(initialState.fields)
  const [image, setImage] = useState(initialState.image)
  const [defaultImage, setDefaultImage] = useState(initialState.defaultImage)

  // Ready and discard times are set to base defaults even if not used
  const [readyUnit, setReadyUnit] = useState(initialState.readyUnit)
  const [readyValue, setReadyValue] = useState(initialState.readyValue)
  const [discardUnit, setDiscardUnit] = useState(initialState.discardUnit)
  const [discardValue, setDiscardValue] = useState(initialState.discardValue)
  const [discardEod, setDiscardEod] = useState(initialState.discardEod)

  // labelData is the data that is loaded when EDITING an item, loadedTemplate refers to the template that is being shown in the viewer (i.e. the currently selected template)
  const [labelData, setLabelData] = useState(null)
  const [loadedTemplate, setLoadedTemplate] = useState(initialState.loadedTemplate)
  const [draft, setDraft, removeDraft, draftUntouched] = useDraft("labels_item_creator", null)

  // Label image upload states
  // eslint-disable-next-line no-unused-vars
  const [uploading, setUploading] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [upload, setUpload] = useState([])
  const [isUploading, setIsUploading] = useState(false)

  // Some areas of the form are optionally shown depending on the template being used
  const [hasReadyFields, setHasReadyFields] = useState(false)
  const [hasDiscardFields, setHasDiscardFields] = useState(false)
  const [hasDynamicCreateFields, setHasDynamicCreateFields] = useState(false)
  const [hasBarcodeFields, setHasBarcodeFields] = useState(false)

  // Get user details, location and current time for the template
  const { firstName, lastName } = principal
  const [currentTime] = useState(moment())
  const [prepTime] = useState(moment())
  const [readyTime, setReadyTime] = useState(moment().add(readyValue, readyUnit))
  const [discardTime, setDiscardTime] = useState(moment().add(discardValue, discardUnit))
  const [dynamicCreateFields, setDynamicCreateFields] = useState([])
  const [dynamicBarcodeFields, setDynamicBarcodeFields] = useState([])

  const isLabelsDemo = isDemo(DEMO_PROFILE.LABELS)

  const currentLocation = location.name
  const dynamicData = {
    firstName,
    lastName,
    currentLocation,
    currentTime,
    prepTime,
    readyTime,
    discardTime,
    title,
    fields,
  }

  const isEdit = edit
  const showDraft = !isEdit && !!draft && draftUntouched
  const [expanded, setExpanded] = useState(false)

  const validValue = /^[0-9\b]+$/

  const expansionClasses = {
    icon: classes.expansionIcon,
  }

  const hasAll = hasPermission("label_update_all")

  useEffect(() => {
    if (!hasAll) {
      setLocations(toId(location))
      setCategoryLocations(toId(location))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When editing a label, filter the data to get the label to edit
  useEffect(() => {
    if (labelsData && id && edit) {
      const result = labelsData.labels.find((c) => c.id === id)
      if (result) {
        setTitle(result.title)
        setCategory(result.category.id)
        setCategoryName(result.categoryName)
        setLocations(result.locations.length ? mapToIds(result.locations) : ["all"])
        setTemplate(result.template.id)
        setReadyValue(result.ready.readyValue)
        setReadyUnit(result.ready.readyUnit)
        setDiscardValue(result.discard.discardValue)
        setDiscardUnit(result.discard.discardUnit)
        setDiscardEod(result.discard.discardEod)
        setImage(result.image)
        setDefaultImage(result.defaultImage)

        // Deep clone required to make "content" object editable
        const copy = JSON.parse(JSON.stringify(result.fields))
        setFields(copy)
      }
      setLabelData(result)
    }
  }, [labelsData, id, edit])

  // When a READY or DISCARD value changes, recalculate the readyTime and discardTime as moment.js formats
  useEffect(() => {
    setReadyTime(moment().add(readyValue, readyUnit))

    // Default the discard value and unit to "1 day" if EOD is selected
    if (discardEod) {
      setDiscardTime(moment().endOf("day"))
      setDiscardValue(1)
      setDiscardUnit("days")
      setDraft((prev) => ({
        ...prev,
        discardValue,
        discardUnit,
      }))
    } else {
      setDiscardTime(moment().add(discardValue, discardUnit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyValue, readyUnit, discardValue, discardUnit, discardEod])

  useMountEffect(() => {
    if (!edit && draft) {
      draft.title && setTitle(draft.title)
      draft.category && setCategory(draft.category)
      draft.categoryName && setCategoryName(draft.categoryName)
      draft.categoryLocations && setCategoryLocations(draft.categoryLocations)
      draft.locations && setLocations(draft.locations)
      draft.template && setTemplate(draft.template)
      draft.image && setImage(draft.image)
      draft.defaultImage && setDefaultImage(draft.defaultImage)
      draft.ready && setReadyValue(draft.readyValue) && setReadyUnit(draft.readyUnit)
      draft.discard &&
        setDiscardValue(draft.discardValue) &&
        setDiscardUnit(draft.discardUnit) &&
        setDiscardEod(draft.discardEod)
      draft.fields && setFields(draft.fields)
      draft.dynamicCreateFields && setDynamicCreateFields(draft.dynamicCreateFields)
      draft.dynamicBarcodeFields && setDynamicBarcodeFields(draft.dynamicBarcodeFields)
    }
  })

  // Does this template require datetime input fields for READY or DISCARD or dynamic fields for DYNAMIC_ONCREATE fields?
  useEffect(() => {
    if (!labelTemplateDataLoading && labelTemplateData && loadedTemplate) {
      setHasReadyFields(false)
      setHasDiscardFields(false)
      setHasDynamicCreateFields(false)
      setHasBarcodeFields(false)

      for (const item of loadedTemplate.fields) {
        if (item.content.type === LABEL_TEMPLATE_FIELD_TYPE.READY_DATETIME) {
          setHasReadyFields(true)
        } else if (item.content.type === LABEL_TEMPLATE_FIELD_TYPE.DISCARD_DATETIME) {
          setHasDiscardFields(true)
        } else if (item.content.type === LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE) {
          setHasDynamicCreateFields(true)
        } else if (item.content.type === LABEL_TEMPLATE_FIELD_TYPE.BARCODE) {
          setHasBarcodeFields(true)
        }
      }
      handleDynamicFields(loadedTemplate)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedTemplate, labelTemplateDataLoading, labelTemplateData])

  useEffect(() => {
    if (labelTemplateData?.labelTemplates && !labelTemplateDataLoading) {
      const templateFilter = labelTemplateData.labelTemplates.filter((item) => toId(item.id) === template)
      setLoadedTemplate(templateFilter[0])
      setExpanded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template, labelTemplateDataLoading, labelsDataLoading])

  // For each of the dynamic fields that this template might have, create state for it and make sure that state updates flow through to the LabelsViewer component
  const handleDynamicFields = (labelTemplate) => {
    const filteredFields = labelTemplate.fields.filter(
      (field) => field.content.type === LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE,
    )
    setDynamicCreateFields(filteredFields)

    const filteredBarcodeFields = labelTemplate.fields.filter(
      (field) => field.content.type === LABEL_TEMPLATE_FIELD_TYPE.BARCODE,
    )
    setDynamicBarcodeFields(filteredBarcodeFields)

    // We also need to clear out the "fields" state and repopulate it the template has changed.
    const fieldArray = []
    filteredFields.forEach((field) => {
      const object = {
        id: fields.find((item) => item.fieldId === field.id)?.id || null,
        fieldId: field.id,
        type: LABEL_TEMPLATE_FIELD_TYPE.DYNAMIC_TEXT_ONCREATE,
        text: fields.find((item) => item.fieldId === field.id)?.text || field.content.dynamicText,
      }
      fieldArray.push(object)
    })

    filteredBarcodeFields.forEach((field) => {
      const object = {
        id: fields.find((item) => item.fieldId === field.id)?.id || null,
        fieldId: field.id,
        type: LABEL_TEMPLATE_FIELD_TYPE.BARCODE,
        format: field.content.barcodeFormat,
        text: fields.find((item) => item.fieldId === field.id)?.text || field.content.dynamicText,
      }
      fieldArray.push(object)
    })

    setFields(fieldArray)
  }

  const handleToggleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const validateNumbers = (value) => {
    if (validValue.test(value)) {
      return value
    }
    return 1
  }

  const handleClose = (event, isCancel = true) => {
    if (isEdit) {
      removeDraft()
    }
    handleResetState()
    onClose && onClose(isCancel)
  }

  const handleCreated = (item) => {
    removeDraft()
    onCreated && onCreated(item)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (formValid()) {
      setLoading(true)
      // If a new category was created, set the newCategory variable to the ID of the new category
      let newCategory = category
      if (category === "new") {
        const result = await createLabelCategory({
          variables: {
            input: {
              name: categoryName,
              locations:
                categoryLocations?.includes("all") && hasPermission("label_update_all") ? [] : categoryLocations,
            },
          },
        })
        if (result.data) {
          newCategory = result.data.createLabelCategory.id
        }
      }

      // Capture a PNG snapshot of the label if no image has been uploaded. This serves as a backup image - when loading and displaying images, check the value of "defaultImage" to determine how to display it
      let newImage = image
      if (defaultImage) {
        // Generate image from canvas
        const result = await generateDefault(title)

        // Register upload
        const uploadDefault = await registerUpload({
          variables: {
            fileName: result.fileName,
            fileSize: result.fileSize,
          },
        })

        // Assemble as file object
        const uploadFileInfo = new File([result.fileData], result.fileName, {
          type: "image/png",
        })

        // Upload to s3
        await uploadToS3PresignedUrl(uploadDefault.data.registerUpload.signedUploadUrl, uploadFileInfo)

        newImage = uploadDefault.data.registerUpload.upload.id
      }

      const variables = {
        input: {
          title,
          template,
          category: newCategory,
          locations: locations.includes("all") && hasPermission("label_update_all") ? [] : toId(locations),
          ready: { readyValue, readyUnit },
          discard: { discardValue, discardUnit, discardEod },
          fields: deepOmit(deepRemoveTypenames(fields), "[format]"),
          image: toId(newImage, true),
          defaultImage,
        },
      }
      if (isEdit) {
        await updateLabel({ variables: { id, ...variables } })
        removeDraft()
        setLoading(false)
        handleClose(e, false)
      } else {
        const result = await createLabel({ variables })
        setLoading(false)
        if (result.data) {
          handleCreated(result.data.createLabel)
        }
      }
    }
  }

  const handleChange = (set, name, newValue) => {
    set(newValue)
    setDraft((prev) => ({
      ...prev,
      [name]: newValue,
    }))
  }

  const handleDiscardDraft = () => {
    removeDraft()
    handleResetState()
  }

  const handleResetState = () => {
    setTitle(initialState.title)
    setCategory(initialState.category)
    setCategoryName(initialCategoryState.name)
    setCategoryLocations(initialCategoryState.categoryLocations)
    setTemplate(initialState.template)
    setLoadedTemplate(initialState.loadedTemplate)
    setFields(initialState.fields)
    setLocations(initialState.locations)
    setImage(initialState.image)
    setDefaultImage(initialState.defaultImage)
    setHasReadyFields(false)
    setReadyUnit(initialState.readyUnit)
    setReadyValue(initialState.readyValue)
    setHasDiscardFields(false)
    setDiscardUnit(initialState.discardUnit)
    setDiscardValue(initialState.discardValue)
    setDiscardEod(initialState.discardEod)
    setHasDynamicCreateFields(false)
    setDynamicCreateFields(initialState.dynamicCreateFields)
    setHasBarcodeFields(false)
    setDynamicBarcodeFields(initialState.dynamicBarcodeFields)
    setFields(initialState.fields)
  }

  const handleGlobalAvailability = (event) => {
    if (event.target.value === "all") {
      setLocations([])
    } else {
      setLocations(toId(location))
    }
  }

  const handleCategoryLocationsChanged = (event) => {
    handleChange(setCategoryLocations, "categoryLocations", event.target.value)
  }

  const handleBeforeUpload = (items) => {
    setIsUploading(true)
    setUploading(() => items)
  }
  const handleUploadProgress = (items) => {
    setUploading(() => items)
  }
  const handleRemoveUpload = () => {
    setImage(null)
    setDefaultImage(true)
  }
  const handleAfterUpload = (items) => {
    setImage(items[0].upload)
    setDefaultImage(false)
  }
  const handleNotUploading = () => {
    setIsUploading(false)
  }

  // Retrieves the appropriate value for a specific dynamic field
  const handleGetDynamicFieldValue = (field) => {
    const fieldValue = fields?.find((item) => item.fieldId === field.id)
    if (fieldValue) {
      if (
        (fieldValue.text === "" || fieldValue.text === undefined) &&
        field.content.type !== LABEL_TEMPLATE_FIELD_TYPE.BARCODE
      ) {
        return field.content.dynamicText
      }
      return fieldValue.text
    }
    return field.content.dynamicText
  }

  // Handle any updates made in the label to the dynamic fields in the template
  const handleDynamicFieldChange = (index, fieldId, value) => {
    const updatedFields = [...fields]
    updatedFields[index].text = value
    setFields(updatedFields)
  }

  // Eventual setup for QR code validation. Check URL validaity and ability to generate a code
  // const isValidUrl = (value) => {
  //   try {
  //     const url = new URL(value)
  //     return url.protocol !== ""
  //   } catch (_) {
  //     return false
  //   }
  // }

  const getErrorDynamicField = (fieldId) => {
    const obj = {
      error: false,
      helperText: "",
    }
    const field = fields?.find((item) => item.fieldId === fieldId)
    if (field?.type === LABEL_TEMPLATE_FIELD_TYPE.BARCODE) {
      if (field?.text?.length === 0) {
        obj.error = false
        obj.helperText = "Please enter the value for this barcode"
      }
    }

    return obj
  }

  const handleDynamicFieldFocus = (event, field) => {
    if (handleGetDynamicFieldValue(field)) {
      event.target.select()
    }
  }

  // TODO add validation here for any dynamic fields, ready and discard times
  const formValid = () => {
    return (
      (category === "new" ? isValid(categoryName) && isValid(categoryLocations) : isValid(category)) &&
      isValid(title, category, template)
    )
  }

  const isFormValid = formValid()
  const hasLabelsWarning = hasFeature("labels_warning")

  const { loaded, total } = upload

  const submitLoading = createLabelLoading || updateLabelLoading

  const organisationName = settings.organisation.name

  const localCategories = labelCategoryData?.labelCategories.filter((cat) => cat.locations.length > 0)
  const globalCategories = labelCategoryData?.labelCategories.filter((cat) => cat.locations.length === 0)

  // Check if the selected category is a global category by seeing how many locations it has
  const isSelectedGlobalCategory =
    labelCategoryData?.labelCategories.find((cat) => cat.id === category)?.locations.length === 0

  const form = (
    <>
      <DraftBlockquote show={showDraft} subject={t`Label`} onDiscard={handleDiscardDraft} />

      <Box mb={3}>
        <Box className={classes.headerBox}>
          <Box className={classes.imageFrame}>
            {image && !defaultImage ? (
              <ColumnBox className={classes.backing}>
                {!!handleRemoveUpload && (
                  <IconButton onClick={handleRemoveUpload} disabled={!image} className={classes.remove}>
                    <CancelOutlinedIcon />
                  </IconButton>
                )}
                {!image && (
                  <ColumnBox className={classes.progress} alignItems="center" justifyContent="center">
                    <CircularProgress value={Math.round((loaded / total) * 100)} size={32} />
                  </ColumnBox>
                )}
                {image && (
                  <DisplayImage
                    upload={image}
                    width={300}
                    fit="cover"
                    alt="Label image"
                    className={classes.imageDisplay}
                  />
                )}
              </ColumnBox>
            ) : (
              <ColumnBox className={classes.uploadFrame}>
                <UploaderDropZone
                  onBeforeUpload={handleBeforeUpload}
                  onProgress={handleUploadProgress}
                  onAfterUpload={handleAfterUpload}
                  onError={handleNotUploading}
                  documents={false}
                  prompt={t`Drag & drop image here or click to select`}
                  className={classes.uploadDisplay}
                  disabled={isUploading}
                />
              </ColumnBox>
            )}
          </Box>

          <Grid className={classes.detailGrid} container direction="column" spacing={2}>
            <Grid className={classes.detailGridItem} item>
              <TextField
                variant="outlined"
                fullWidth
                id="title"
                label={t`Label name`}
                name="title"
                value={title}
                onChange={(event) => handleChange(setTitle, "title", event.target.value)}
                required
                data-cy="TextField-title"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Divider />

      <Box mt={2}>
        <OutlinedSelect
          label={t`Category`}
          value={category}
          native={false}
          onChange={(event) => handleChange(setCategory, "category", event.target.value)}
          required
          data-cy="OutlinedSelect-category"
        >
          <MenuItem value="new" data-cy="MenuItem-category-new">
            <RowBox>
              <Icon name="add" />
              <Box ml={1}>
                <Trans>New category...</Trans>
              </Box>
            </RowBox>
          </MenuItem>
          <Divider />
          {hasPermission("label_update") && (
            <ListSubheader onClickCapture={(e) => e.stopPropagation()}>
              <Trans>{organisationName} categories</Trans>
            </ListSubheader>
          )}
          {hasPermission("label_update") &&
            globalCategories?.map(({ id: labelCategoryId, name: labelCategoryName }) => (
              <MenuItem key={labelCategoryId} value={labelCategoryId}>
                <RowBox>
                  <Box>{labelCategoryName}</Box>
                </RowBox>
              </MenuItem>
            ))}

          <ListSubheader onClickCapture={(e) => e.stopPropagation()}>
            <Trans>{location.name} categories</Trans>
          </ListSubheader>

          {localCategories?.map(({ id: labelCategoryId, name: labelCategoryName }) => (
            <MenuItem key={labelCategoryId} value={labelCategoryId}>
              <RowBox>
                <Box>{labelCategoryName}</Box>
              </RowBox>
            </MenuItem>
          ))}
        </OutlinedSelect>
      </Box>

      {category === "new" && (
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                id="category"
                label={t`New category name`}
                name="category"
                value={categoryName}
                onChange={(event) => handleChange(setCategoryName, "categoryName", event.target.value)}
                autoFocus
                required
                data-cy="TextField-category-name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedSelect
                label={t`New category location`}
                native={false}
                value={categoryLocations}
                onChange={handleCategoryLocationsChanged}
              >
                {hasPermission("label_update_all") && <MenuItem value="all">All locations</MenuItem>}
                <MenuItem value={location.id}>{location.name}</MenuItem>
              </OutlinedSelect>
            </Grid>
          </Grid>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
        </Box>
      )}

      {isSelectedGlobalCategory && hasPermission("label_update_all") && (
        <Box mt={2}>
          <Grid container>
            <Grid item xs={12}>
              <OutlinedSelect
                label={t`Label availability`}
                native={false}
                value={locations.includes("all") || locations.length === 0 ? "all" : toId(location.id)}
                onChange={handleGlobalAvailability}
              >
                <MenuItem value="all">At all {organisationName} locations</MenuItem>
                <MenuItem value={location.id}>At {location.name} only</MenuItem>
              </OutlinedSelect>
            </Grid>
          </Grid>
        </Box>
      )}

      {!labelTemplateDataLoading && (
        <Box mt={2} mb={2}>
          <OutlinedSelect
            label={t`Template`}
            fullWidth
            name="template"
            id="template"
            value={loadedTemplate?.id || template}
            native={false}
            onChange={(event) => handleChange(setTemplate, "template", event.target.value)}
            data-cy="OutlinedSelect-template"
          >
            {labelTemplateData.labelTemplates.map(({ id: labelTemplateId, title: labelTemplateTitle }) => (
              <MenuItem key={labelTemplateId} value={labelTemplateId}>
                <RowBox>
                  <Box>{labelTemplateTitle}</Box>
                </RowBox>
              </MenuItem>
            ))}
          </OutlinedSelect>
        </Box>
      )}

      {hasLabelsWarning && !labelTemplateDataLoading && (
        <LabelsWarning template={template} labelsTemplate={labelTemplateData.labelTemplates} />
      )}

      {loadedTemplate && !labelTemplateDataLoading && (
        <>
          <Box mt={2} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Suspense fallback={<LoadingSpinner />}>
                  <LabelsViewer
                    templateData={loadedTemplate}
                    labelData={isEdit ? labelData : draft}
                    dynamicData={dynamicData}
                  />
                </Suspense>
              </Grid>
            </Grid>
          </Box>

          {(hasReadyFields || hasDiscardFields || hasDynamicCreateFields || hasBarcodeFields) && (
            <Box mt={2} mb={1}>
              <Alert icon={false} severity="info">
                <Trans>
                  <AlertTitle>This template contains editable fields</AlertTitle>
                  You can edit the values for these fields below.
                </Trans>
              </Alert>
            </Box>
          )}

          {/* If the label has a READY field, the value and unit will be set here */}
          {hasReadyFields && (
            <Box mt={1} mb={1}>
              <ExpansionPanel
                {...{
                  className: classes.content,
                }}
                icon={<Icon name="clock" />}
                title={t`Ready Time`}
                expanded={expanded === "ready"}
                onChange={handleToggleExpanded("ready")}
                description={`${readyValue} ${pluralize(readyUnit.slice(0, -1), readyValue)} after preparation time`}
                classes={expansionClasses}
              >
                <ColumnBox width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="readyvalue"
                        aria-label="readyvalue"
                        name="readyvalue"
                        value={readyValue}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 3,
                        }}
                        onChange={(event) =>
                          handleChange(setReadyValue, "readyValue", validateNumbers(parseInt(event.target.value)))
                        }
                        onClick={(event) => event.target.select()}
                        onFocus={(event) => event.target.select()}
                        data-cy="TextField-ready-time"
                      />
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <OutlinedSelect
                        id="readyunit"
                        name="readyunit"
                        aria-label="readyunit"
                        value={readyUnit}
                        native={false}
                        onChange={(event) => handleChange(setReadyUnit, "readyUnit", event.target.value)}
                        data-cy="OutlinedSelect-ready-unit"
                      >
                        <MenuItem value="minutes">Minute(s)</MenuItem>
                        <MenuItem value="hours">Hour(s)</MenuItem>
                        <MenuItem value="days">Day(s)</MenuItem>
                        <MenuItem value="weeks">Week(s)</MenuItem>
                        <MenuItem value="months">Month(s)</MenuItem>
                      </OutlinedSelect>
                    </Grid>
                  </Grid>
                </ColumnBox>
              </ExpansionPanel>
            </Box>
          )}

          {/* If the label has a DISCARD field, the value and unit will be set here */}
          {hasDiscardFields && (
            <Box mt={1} mb={1}>
              <ExpansionPanel
                {...{
                  className: classes.content,
                }}
                icon={<Icon name="clock" />}
                title={t`Discard Time`}
                expanded={expanded === "discard"}
                onChange={handleToggleExpanded("discard")}
                description={
                  discardEod
                    ? "At end of day"
                    : `${discardValue} ${pluralize(discardUnit.slice(0, -1), discardValue)} after preparation time`
                }
                classes={expansionClasses}
              >
                <ColumnBox width="100%">
                  <Grid container spacing={2}>
                    <Grid item xs={3} sm={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="discardvalue"
                        name="discardvalue"
                        aria-label="discardvalue"
                        value={discardValue}
                        disabled={discardEod}
                        inputProps={{
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                          maxLength: 3,
                        }}
                        onChange={(event) =>
                          handleChange(setDiscardValue, "discardValue", validateNumbers(parseInt(event.target.value)))
                        }
                        onClick={(event) => event.target.select()}
                        onFocus={(event) => event.target.select()}
                        data-cy="TextField-discard-time"
                      />
                    </Grid>
                    <Grid item xs={9} sm={10}>
                      <OutlinedSelect
                        id="discardunit"
                        name="discardunit"
                        aria-label="discardunit"
                        value={discardUnit}
                        native={false}
                        disabled={discardEod}
                        onChange={(event) => handleChange(setDiscardUnit, "discardUnit", event.target.value)}
                        data-cy="OutlinedSelect-ready-unit"
                      >
                        <MenuItem value="minutes">Minute(s)</MenuItem>
                        <MenuItem value="hours">Hour(s)</MenuItem>
                        <MenuItem value="days">Day(s)</MenuItem>
                        <MenuItem value="weeks">Week(s)</MenuItem>
                        <MenuItem value="months">Month(s)</MenuItem>
                      </OutlinedSelect>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={10}>
                      <Checkbox
                        type="label"
                        color="primary"
                        label={t`Discard at end of day`}
                        checked={discardEod}
                        onChange={(event) => handleChange(setDiscardEod, "discardEod", event.target.checked)}
                      />
                    </Grid>
                  </Grid>
                </ColumnBox>
              </ExpansionPanel>
            </Box>
          )}

          {/* This label may have dynamic fields that are entered at time of CREATE/EDIT. If so, display them here */}
          {(hasDynamicCreateFields || hasBarcodeFields) && (
            <Box mt={1} mb={1}>
              <ExpansionPanel
                {...{
                  className: classes.content,
                }}
                icon={<Icon name="dynamic" />}
                title={t`Dynamic Fields`}
                expanded={expanded === "dynamic"}
                onChange={handleToggleExpanded("dynamic")}
                description={`${dynamicCreateFields.length + dynamicBarcodeFields.length} fields`}
                classes={expansionClasses}
              >
                <>
                  {[...dynamicCreateFields, ...dynamicBarcodeFields].length > 0 && fields && (
                    <ColumnBox width="100%">
                      <Grid container spacing={2}>
                        {[...dynamicCreateFields, ...dynamicBarcodeFields].map((field, index) => (
                          <React.Fragment key={field.id}>
                            <Grid item xs={12} sm={12}>
                              <TextField
                                variant="outlined"
                                fullWidth
                                id={field.id}
                                label={field.content.dynamicName}
                                name={field.content.dynamicName}
                                value={handleGetDynamicFieldValue(field)}
                                onChange={(e) => handleDynamicFieldChange(index, field.id, e.target.value)}
                                data-cy="TextField-dynamic"
                                onClick={(event) => handleDynamicFieldFocus(event, field)}
                                onFocus={(event) => handleDynamicFieldFocus(event, field)}
                                error={getErrorDynamicField(field.id).error}
                                helperText={getErrorDynamicField(field.id).helperText}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </ColumnBox>
                  )}
                </>
              </ExpansionPanel>
            </Box>
          )}
        </>
      )}

      {!loadedTemplate && labelTemplateDataLoading && (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <LoadingSpinner />
        </Box>
      )}

      <CreatorActions
        id="LabelsItemCreator-CreatorActions"
        subject={t`Label`}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || submitLoading || isLabelsDemo}
        submitLoading={submitLoading || loading}
      />
    </>
  )

  return (
    <CreatorMaster
      id="LabelsItemCreator"
      open={open}
      subject={t`Label`}
      form={form}
      isEdit={isEdit}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { LabelsItemCreator }
