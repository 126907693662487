import React, { useMemo, useRef, useState } from "react"
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core"
import useAsyncEffect from "use-async-effect"
import { Autocomplete } from "@material-ui/lab"
import { RowBox } from "../Boxes"
import { Caption, FieldSectionHeading } from "../Headings"
import { Icon } from "../Icon"
import { toId, useDebounce } from "../../utils"
import { useMutationIntegrationSetLocationGroupAttribute, useQueryGroups, useQueryLocations } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { useQueryIntegrationLocationGroupAttributeValues } from "../../data/integrations/useQueryIntegration"
import { OutlinedSelect } from "../OutlinedSelect"

const useAttributeListItemStyles = makeStyles((theme) => ({
  avatar: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
    alignItems: "flex-start",
  },
  text: {
    padding: 4,
  },
}))

const LocationGroupAttributeListItem = ({ integration, location, group, attribute, onChange }) => {
  const mounted = useRef(false)
  const classes = useAttributeListItemStyles()
  const { data, loading } = useQueryIntegrationLocationGroupAttributeValues({
    variables: { id: toId(integration), location: toId(location), key: attribute.key },
    skip: !attribute.dataSource,
  })
  const [setAttribute] = useMutationIntegrationSetLocationGroupAttribute()
  const [value, setValue] = useState(
    group?.attributes?.find(
      (item) =>
        item.key === attribute.key &&
        toId(item.integration) === toId(integration) &&
        toId(location) === toId(item.location),
    )?.value || "",
  )
  const debouncedValue = useDebounce(value, 300)

  useAsyncEffect(async () => {
    if (!mounted.current) {
      mounted.current = true
      return
    }
    await setAttribute({
      variables: {
        id: toId(integration),
        location: toId(location),
        group: toId(group),
        key: attribute.key,
        value: debouncedValue.toString(),
      },
    })
    onChange && onChange()
  }, [debouncedValue, attribute])

  const handleChange = async (_, newValue) => {
    setValue(newValue?.key || "")
  }

  const optionValue = useMemo(
    () => data?.integration?.locationGroupAttributeValues.find((item) => item.key === value) || null,
    [data, value],
  )

  return (
    <ListItem disableGutters alignItems="flex-start">
      <ListItemAvatar classes={{ root: classes.avatar }}>
        <RowBox>
          <Icon name="integration-group" />
        </RowBox>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ component: "div" }}
        primary={
          <RowBox alignContent="center">
            <Grid container alignContent="center">
              <Grid item xs={6}>
                <FieldSectionHeading mb={0}>
                  <Tooltip title={toId(group)}>
                    <span>{group.name}</span>
                  </Tooltip>
                </FieldSectionHeading>
                <Caption mb={0}>{attribute.description}</Caption>
              </Grid>
              <Grid item xs={6}>
                {attribute.dataSource && (
                  <Autocomplete
                    value={optionValue}
                    autoHighlight
                    getOptionLabel={(option) => `${option.value} (#${option.key})`}
                    getOptionSelected={(option) => option.key === optionValue.key}
                    options={data?.integration?.locationGroupAttributeValues || []}
                    loading={loading}
                    disabled={loading}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={attribute.name}
                        variant="outlined"
                        placeholder="Not set"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <LoadingSpinner size={24} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {!attribute.dataSource && (
                  <TextField
                    label={attribute.name}
                    variant="outlined"
                    placeholder="Not set"
                    fullWidth
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                  />
                )}
              </Grid>
            </Grid>
          </RowBox>
        }
      />
    </ListItem>
  )
}

const IntegrationLocationGroupConfiguration = ({ integration }) => {
  const { data: locationsData } = useQueryLocations()
  const { data: groupsData } = useQueryGroups()
  const [location, setLocation] = useState("")
  const {
    integrationProvider: { name, attributes },
  } = integration

  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  }

  const locationItem = location && locationsData?.locations.find((item) => toId(item) === toId(location))

  return (
    <Box mt={2}>
      <OutlinedSelect value={location} label="Location" native={false} onChange={handleLocationChange}>
        {locationsData?.locations.map((item) => (
          <MenuItem key={toId(item)} value={toId(item)}>
            {item.name}
          </MenuItem>
        ))}
      </OutlinedSelect>

      {locationItem && (
        <Box m={1}>
          <Caption>
            The following {name} attributes are mapped to your Operandio areas of work for the selected location.
          </Caption>

          <List key={toId(locationItem)} disablePadding>
            {groupsData?.groups.map((group) => (
              <React.Fragment key={toId(group)}>
                {attributes.locationGroup.map((attribute, index) => (
                  <React.Fragment key={attribute.key}>
                    {index > 0 && <Divider />}
                    <LocationGroupAttributeListItem
                      integration={integration}
                      location={locationItem}
                      group={group}
                      attribute={attribute}
                    />
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}

export { IntegrationLocationGroupConfiguration }
