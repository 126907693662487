import { getViewIcon } from "../../areas/account/Views/utils"

export const getNavItems = ({
  location,
  langArea,
  areaData,
  readReports,
  readTemplates,
  readUsers,
  adminTraining,
  userLocation,
  featureDependencies,
}) => {
  const getActive = (path) => () => location.pathname.startsWith(path)

  const getAreaNavItems = () => {
    if (!areaData) return []
    if (areaData && Object.entries(areaData).length > 0) {
      return areaData?.areas?.list?.map((area) => {
        const areaUrl = `/area/${area.url}`

        return {
          text: area.name,
          icon: getViewIcon(area?.icon),
          to: areaUrl,
          requires: "area_read",
          selected: getActive(`${areaUrl}/`), // ! Hack solution. We need to improve getActive function.
          beta: true,
        }
      })
    }
  }

  return {
    mainItems: [
      {
        section: "Main",
        items: [
          {
            text: langArea.dashboard,
            icon: "dashboard-dark",
            to: "/dashboard",
            selected: getActive("/dashboard"),
            disabled: !userLocation,
          },
          {
            text: langArea.jobs,
            icon: "jobs-dark",
            to: "/jobs",
            selected: getActive("/jobs"),
            disabled: !userLocation,
          },
          {
            text: langArea.knowledge,
            icon: "knowledge-dark",
            to: "/knowledge",
            selected: getActive("/knowledge"),
          },
          {
            text: langArea.hub,
            icon: "hub-dark",
            to: "/hub/all",
            selected: () => location.pathname.startsWith("/hub/") && !location.pathname.startsWith("/hub/action"),
          },
          {
            text: langArea.actions,
            icon: "actions-dark",
            to: "/hub/actions/all",
            selected: getActive("/hub/action"),
          },
          {
            text: langArea.labels,
            icon: "labels-dark",
            to: "/labels",
            feature: "labels",
            selected: getActive("/labels"),
          },
          {
            text: langArea.assets,
            icon: "assets-dark",
            to: "/assets",
            requires: "asset_read",
            feature: "assets",
            selected: getActive("/assets"),
          },
          {
            text: langArea.suppliers,
            icon: "suppliers-dark",
            to: "/suppliers",
            requires: "supplier_read",
            feature: "suppliers",
            selected: getActive("/suppliers"),
          },
          {
            text: langArea.sensors,
            icon: "sensors-dark",
            to: "/sensors",
            requires: "sensor_read",
            feature: "sensors",
            selected: getActive("/sensors"),
          },
          {
            text: langArea.foodItems,
            icon: "food-items-dark",
            to: "/food-items",
            requires: "food_item_read",
            feature: "food",
            selected: getActive("/food-items"),
          },
          {
            text: langArea.prep,
            icon: "prep-dark",
            to: "/prep",
            requires: "prep_read",
            feature: featureDependencies.prep,
            selected: getActive("/prep"),
          },
          {
            text: langArea.timers,
            icon: "timers-dark",
            to: "/timers",
            requires: "timer_read",
            feature: featureDependencies.timers,
            selected: getActive("/timers"),
          },
        ],
      },
      {
        section: "Managers Hub",
        requires: readTemplates || readUsers || adminTraining,
        items: [
          {
            text: langArea.templates,
            icon: "templates-dark",
            to: "/templates",
            requires: readTemplates,
            selected: getActive("/templates"),
          },
          {
            text: langArea.people,
            icon: "people-dark",
            to: "/people",
            requires: readUsers,
            selected: getActive("/people"),
          },
          {
            text: langArea.training,
            icon: "training-accreditation-dark",
            to: "/training",
            requires: adminTraining,
            feature: "training",
            selected: getActive("/training"),
          },
        ],
      },
      {
        section: "Reporting & Views",
        requires: readReports,
        items: [
          {
            text: langArea.reports,
            icon: "reports-dark",
            to: "/reports",
            requires: readReports,
            selected: getActive("/reports"),
          },
          ...getAreaNavItems(),
        ],
      },
    ],
    bottomItems: [
      {
        text: "Maximize",
        expandedText: "Minimize",
        icon: "menu-open",
        expandedIcon: "menu-close",
        id: "nav-expand",
      },
    ],
  }
}
