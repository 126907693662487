import React from "react"
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  makeStyles,
} from "@material-ui/core"
import { t, Trans } from "@lingui/macro"
import SearchInput from "../SearchInput"
import { jobSort } from "../JobList"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  select: {
    minWidth: 250,
  },
}))

const JobsSearch = ({ onTextChange, onSortChange, textValue, sortValue, textRight }) => {
  const theme = useTheme()
  const classes = useStyles()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))

  const handleFilterTextChange = (filterText) => {
    if (onTextChange) onTextChange(filterText)
  }

  const handleSortChange = ({ target: { value } }) => {
    if (onSortChange) onSortChange(value)
  }

  return (
    <RowBox>
      <Grid container direction="row" spacing={1}>
        <Grid item sm xs={12}>
          <Box display="flex" alignItems="center" flexDirection="row">
            <Box flexGrow={1}>
              <SearchInput
                placeholder={t`Search jobs for this day`}
                initialValue={textValue}
                debounce={300}
                onChange={handleFilterTextChange}
                boxProps={{ pl: 0, mb: 0 }}
              />
            </Box>
          </Box>
        </Grid>
        {!xs && (
          <>
            <Grid item xs={12} sm="auto">
              <FormControl variant="outlined" className={classes.select} fullWidth>
                <InputLabel>
                  <Trans>Sort by</Trans>
                </InputLabel>
                <Select className={classes.root} value={sortValue} onChange={handleSortChange} label="Sort by">
                  {jobSort.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
      {textRight && (
        <Grid item xs>
          <Box>{textRight}</Box>
        </Grid>
      )}
    </RowBox>
  )
}

export { JobsSearch }
