import React from "react"
import {
  Box,
  Tabs,
  Tab,
  Divider,
  Hidden,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Trans, t } from "@lingui/macro"
import { Switch, MobileSubNavigation } from ".."
import { useAuth } from "../../services"
import { jobFiltersStateVar, useJobFiltersStore } from "../../data"
import { AssignedToPickers } from "../AssignedToPickers/AssignedToPickers"

const useStyles = makeStyles((theme) => ({
  filter: {
    width: ({ fullWidth }) => (fullWidth ? "100%" : 322),
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "24px",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  assignedTo: {
    borderBottom: "none",
  },
}))

const handleSet = (prop, value) => {
  jobFiltersStateVar({
    ...jobFiltersStateVar(),
    [prop]: value,
  })
}

const Filters = ({ classes }) => {
  const { jobsFiltersStore } = useJobFiltersStore()
  const { tab, hideCompleted, hideFuture, hideHidden, isFuture } = jobsFiltersStore || {}

  if (!jobsFiltersStore) {
    return null
  }

  return (
    <Box mb={0}>
      {tab === "others" && (
        <Box pt={3}>
          <AssignedToPickers classes={classes} />
        </Box>
      )}
      <Box pb={2} pt={2}>
        <Grid container direction="column">
          <Grid xs={12} sm={12} md={12} item>
            <Switch
              label={<Trans>Show completed</Trans>}
              checked={!hideCompleted}
              onChange={() => handleSet("hideCompleted", !hideCompleted)}
              disabled={isFuture}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} item>
            <Switch
              label={<Trans>Show not yet available</Trans>}
              checked={!hideFuture}
              onChange={() => handleSet("hideFuture", !hideFuture)}
              disabled={isFuture}
            />
          </Grid>
          <Grid xs={12} sm={12} md={12} item>
            <Switch
              label={<Trans>Show hidden</Trans>}
              checked={!hideHidden}
              onChange={() => handleSet("hideHidden", !hideHidden)}
              disabled={isFuture}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

const JobsFilters = ({ open, onClose, fullWidth }) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { jobsFiltersStore } = useJobFiltersStore()
  const {
    settings: { deviceGroups },
  } = useAuth()
  const { tab } = jobsFiltersStore || {}

  const classes = useStyles({ fullWidth, xs })

  const handleToggleFilterDrawer = () => {
    onClose && onClose()
  }

  const hasDeviceGroups = deviceGroups?.length

  if (!jobsFiltersStore) {
    return null
  }

  return (
    <>
      <Hidden mdDown>
        <Box className={classes.filter}>
          <Box pb={0}>
            <Typography variant="h1" className={classes.title}>
              <Trans>Filter jobs</Trans>
            </Typography>
            {!hasDeviceGroups && (
              <Tabs
                variant="fullWidth"
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newTab) => handleSet("tab", newTab)}
                aria-label="Job filtering tabs"
                classes={{ indicator: classes.tabsIndicator }}
              >
                <Tab label={<Trans>Assigned to me</Trans>} value="me" classes={{ root: classes.tabRoot }} />
                <Tab label={<Trans>Assigned to others</Trans>} value="others" classes={{ root: classes.tabRoot }} />
              </Tabs>
            )}
          </Box>
          {!hasDeviceGroups && <Divider />}
          <Filters classes={classes} />
        </Box>
      </Hidden>

      <MobileSubNavigation title={<Trans>Filter jobs</Trans>} open={open} onClose={handleToggleFilterDrawer}>
        {!hasDeviceGroups && (
          <RadioGroup value={tab} onChange={(e) => handleSet("tab", e.target.value)} row className={classes.radioTabs}>
            <Box display="flex" flexDirection="column" p={1}>
              <FormControlLabel label={t`Assigned to me`} value="me" control={<Radio color="primary" />} />
              <FormControlLabel label={t`Assigned to others`} value="others" control={<Radio color="primary" />} />
            </Box>
          </RadioGroup>
        )}
        {!hasDeviceGroups && <Divider />}
        <Filters classes={classes} p={2} />
      </MobileSubNavigation>
    </>
  )
}

export { JobsFilters }
