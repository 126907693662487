import React from "react"
import { Box, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ModuleHeading } from "../Headings"
import { ErrorBoundary } from "../ErrorBoundary"

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: ({ sm }) => (sm ? theme.spacing(1) : theme.spacing(2)),
  },
}))

const DashboardModule = ({ title: { text, unconfirmed }, children, adminOnly = false, cy }) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles({ sm })

  return (
    <ErrorBoundary>
      <Box mb={sm ? 3 : 5} data-cy={cy} position="relative">
        {Boolean(text) && (
          <ModuleHeading unconfirmed={unconfirmed} adminOnly={adminOnly} noMargin className={classes.root}>
            {text}
          </ModuleHeading>
        )}
        {children}
      </Box>
    </ErrorBoundary>
  )
}

export { DashboardModule }
