import { useState, useCallback, useEffect } from "react"

// Suppress reCAPTCHA timeout errors in development
if (process.env.NODE_ENV === "development") {
  // Override window.setTimeout to catch reCAPTCHA timeouts
  const originalSetTimeout = window.setTimeout
  window.setTimeout = (handler, timeout, ...args) => {
    try {
      const stack = new Error().stack || ""
      // Only block timeouts that are error-related and contain specific patterns
      if (stack.includes("recaptcha") && timeout > 10000) {
        // Only affect long timeouts
        return -1
      }
    } catch (e) {
      // If anything goes wrong, use original setTimeout
    }
    return originalSetTimeout(handler, timeout, ...args)
  }
}

export const useRecaptcha = () => {
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [recaptchaError, setRecaptchaError] = useState(false)

  const handleRecaptchaChange = useCallback((token) => {
    setRecaptchaError(false)
    setRecaptchaToken(token)
  }, [])

  const handleRecaptchaReset = useCallback(() => {
    setRecaptchaToken(null)
  }, [])

  const handleRecaptchaError = useCallback(() => {
    setRecaptchaError(true)
    setRecaptchaToken(null)
  }, [])

  // Cleanup any reCAPTCHA related timeouts on unmount
  useEffect(() => {
    return () => {
      try {
        // Clear any potential reCAPTCHA timeouts
        const frame = document.querySelector('iframe[src*="recaptcha"]')
        if (frame) {
          frame.remove()
        }
      } catch (e) {
        // Ignore cleanup errors
      }
    }
  }, [])

  return {
    recaptchaToken,
    recaptchaError,
    handleRecaptchaChange,
    handleRecaptchaReset,
    handleRecaptchaError,
  }
}
