import React, { useState, useRef, useCallback } from "react"
import { Box, Drawer, List, makeStyles, Divider, Button, useMediaQuery } from "@material-ui/core"
import { NavLink, useLocation } from "react-router-dom"
import { t } from "@lingui/macro"
import Config from "react-global-configuration"
import { useAuth } from "../../services"
import { useMutationUpdatePreferences, useQuerySettings } from "../../data"
import { useQueryAreas } from "../../data/areas/useQueryAreas"
import { Icon } from "../Icon"
import { RequirePermissions } from "../Permissions/RequirePermissions"
import { getNavItems } from "./NavItems"
import { RowBox } from "../Boxes"
import { mapToIds, toId } from "../../utils"
import { NewActionButton } from "../NewActionButton"
import { usePunchClockState } from "../../data/timesheets/punchClockStateVar"

const useStyles = makeStyles((theme) => {
  const { header, punchClock, leftNavigation } = theme.dimensions

  return {
    container: {
      minWidth: leftNavigation.drawer.collapsed,
      width: ({ isPinned, isHoverMode }) =>
        isHoverMode
          ? leftNavigation.drawer.collapsed
          : isPinned
            ? leftNavigation.drawer.expanded
            : leftNavigation.drawer.collapsed,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      position: "relative",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0)",
      zIndex: 1290,
      opacity: ({ isHoverMode, expanded }) => (isHoverMode && expanded ? 1 : 0),
      visibility: ({ isHoverMode, expanded }) => (isHoverMode && expanded ? "visible" : "hidden"),
      transition: theme.transitions.create(["opacity", "visibility"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawer: {
      width: ({ expanded }) => (expanded ? leftNavigation.drawer.expanded : leftNavigation.drawer.collapsed),
      flexShrink: 0,
      whiteSpace: "nowrap",
      position: "fixed",
      zIndex: ({ isHoverMode }) => (isHoverMode ? 1300 : 1250),
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaper: {
      width: ({ expanded }) => (expanded ? leftNavigation.drawer.expanded : leftNavigation.drawer.collapsed),
      border: 0,
      borderRight: ({ isHoverMode }) => (isHoverMode ? 0 : `1px solid ${theme.palette.grey[300]}`),
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      padding: theme.spacing(2),
      borderTop: 0,
      height: ({ hasPunchClock }) => `calc(100vh - ${header.height + (hasPunchClock ? punchClock.height : 0)}px)`,
      display: "flex",
      flexDirection: "column",
      position: "sticky",
      top: header.height,
      boxShadow: ({ isHoverMode, expanded }) => (isHoverMode && expanded ? theme.shadows[4] : "none"),
      backgroundColor: theme.palette.background.paper,
    },
    toggleButton: {
      position: "absolute",
      right: -12,
      bottom: 100,
      backgroundColor: theme.palette.background.paper,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
    },
    navItem: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.primary,
      textDecoration: "none",
      borderRadius: 8,
      "&:hover": {
        background: theme.palette.action.hover,
        transition: "all 0.2s ease",
      },
      "&:focus-visible": {
        background: theme.palette.action.focus,
        outline: "none",
      },
      "&.active": {
        backgroundColor: theme.palette.action.selected,
      },
    },
    iconBox: {
      alignItems: "center",
      justifyContent: "flex-start",
      display: "flex",
      flex: 1,
      gap: theme.spacing(1.5),
      width: ({ expanded }) => (expanded ? "100%" : 36),
      transition: theme.transitions.create(["width", "justify-content"]),
      position: "relative",
      margin: "0 auto",
      height: leftNavigation.item.height,
      padding: 6,
    },
    icon: {
      width: leftNavigation.item.iconSize,
      minWidth: leftNavigation.item.iconSize,
      display: "flex",
    },
    label: {
      opacity: ({ expanded }) => (expanded ? 1 : 0),
      transition: theme.transitions.create("opacity"),
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      color: "#1A1B43",
      fontSize: 16,
      fontWeight: 600,
      overflowY: "auto",
      flex: 1,
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      "&::-webkit-scrollbar": {
        display: "none",
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
    },
    sectionDivider: {
      borderColor: theme.palette.grey[300],
      height: 1,
      width: "100%",
    },
    sectionLabel: {
      color: theme.palette.text.secondary,
      fontSize: 12,
      fontWeight: 500,
      padding: theme.spacing(0, 2, 0, 1),
      opacity: ({ expanded }) => (expanded ? 1 : 0),
      transition: theme.transitions.create("opacity"),
    },
    dividerSection: {
      paddingRight: ({ expanded }) => (expanded ? theme.spacing(4) : theme.spacing(0)),
      maxWidth: leftNavigation.drawer.expanded,
      position: "relative",
      alignItems: "center",
      overflow: "hidden",
      padding: theme.spacing(1, 0),
      height: leftNavigation.item.height,
    },
    bottomActions: {
      marginTop: "auto",
      paddingTop: theme.spacing(1),
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      width: ({ expanded }) => (expanded ? "100%" : leftNavigation.item.height),
    },
    bottomNavItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      color: theme.palette.text.secondary,
      textDecoration: "none",
      borderRadius: 8,
      cursor: "pointer",
      "&:hover": {
        background: theme.palette.action.hover,
        transition: "all 0.2s ease",
      },
      "&:focus-visible": {
        background: theme.palette.action.focus,
        outline: "none",
        boxShadow: "none",
      },
      height: leftNavigation.item.height,
      width: ({ expanded }) => (expanded ? "100%" : leftNavigation.item.width),
      minWidth: 36,
      transition: "0.2s all",
    },
    bottomLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary,
      whiteSpace: "nowrap",
      opacity: ({ expanded }) => (expanded ? 1 : 0),
      marginLeft: theme.spacing(0.5),
    },
    mainItems: {
      flex: 1,
      width: ({ expanded }) => (expanded ? "100%" : leftNavigation.item.width),
      transition: theme.transitions.create("width"),
      gap: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
  }
})

export const LeftNavigation = () => {
  const md = useMediaQuery((theme) => theme.breakpoints.down("md"))
  const {
    permissionGroups: { readReports, readTemplates, readUsers, adminTraining },
    featureDependencies,
    location: userLocation,
    principal: { groups: usersGroups },
    settings: { preferences },
    hasFeature,
    hasPermission,
  } = useAuth()
  const [{ show: hasPunchClock }] = usePunchClockState()
  const [updatePreferences] = useMutationUpdatePreferences()
  const initialPinned = !md && preferences?.pinPrimaryNavigation
  const [expanded, setExpanded] = useState(initialPinned)
  const [isPinned, setIsPinned] = useState(initialPinned)
  const [isHovered, setIsHovered] = useState(false)
  const [isHoverMode, setIsHoverMode] = useState(false)
  const classes = useStyles({
    expanded: expanded || (isHovered && !isPinned) || (isHoverMode && expanded),
    isPinned,
    isHoverMode,
    hasPunchClock,
  })
  const location = useLocation()
  const { lang } = useQuerySettings()
  const langArea = lang.area
  const { data: areaData } = useQueryAreas({
    variables: {
      filter: {
        locations: [toId(userLocation)],
        groups: mapToIds(usersGroups),
      },
    },
  })
  const { clientKiosk: kiosk, clientDevice: device } = Config.get()
  const isDevice = kiosk || device
  const shouldUseHoverMode = md || isDevice
  const startX = useRef(null)

  const handleTouchStart = (e) => {
    startX.current = e.touches[0].clientX
  }

  const handleTouchMove = (e) => {
    const currentX = e.touches[0].clientX
    const diff = currentX - startX.current

    if (!expanded && !isHoverMode && diff > 30) {
      setIsHovered(true)
    } else if (isHovered && !isHoverMode && diff < -30) {
      setIsHovered(false)
    }
  }

  const handleMouseEnter = useCallback(() => {
    if (isDevice && !isHoverMode) {
      return
    }

    if (!isPinned) {
      setIsHovered(true)
    }
  }, [isPinned, isDevice, isHoverMode])

  const handleMouseLeave = useCallback(() => {
    if (!isPinned && !isHoverMode) {
      setIsHovered(false)
    }
  }, [isPinned, isHoverMode])

  const handleToggle = useCallback(() => {
    const newPinnedState = !isPinned

    if (shouldUseHoverMode) {
      // For mobile or device, use hover mode instead of pinned mode
      setIsHoverMode(newPinnedState)
    }

    setIsPinned(newPinnedState)
    setExpanded(newPinnedState)
    updatePreferences({
      variables: {
        input: {
          pinPrimaryNavigation: newPinnedState,
        },
      },
    })
  }, [isPinned, shouldUseHoverMode, updatePreferences])

  const handleCloseHoverMenu = useCallback(() => {
    if (isHoverMode) {
      setExpanded(false)
      setIsHoverMode(false)
      setIsPinned(false)
    }
  }, [isHoverMode])

  const { mainItems, bottomItems } = getNavItems({
    location,
    langArea,
    areaData,
    hasFeature,
    hasPermission,
    readReports,
    readTemplates,
    readUsers,
    adminTraining,
    userLocation,
    featureDependencies,
  })

  const modifiedBottomItems = bottomItems.map((item) => ({
    ...item,
    text: expanded || isPinned || isHoverMode ? t`Minimize` : t`Keep open`,
    expandedText: expanded || isPinned || isHoverMode ? t`Minimize` : t`Keep open`,
    icon: isPinned || isHoverMode ? "menu-close" : "menu-open",
    expandedIcon: isPinned || isHoverMode ? "menu-close" : "menu-open",
  }))

  return (
    <>
      <Box className={classes.overlay} onClick={handleCloseHoverMenu} />
      <Box className={classes.container}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
        >
          <NewActionButton expanded={expanded || (isHovered && !isPinned) || (isHoverMode && expanded)} />

          <List className={classes.list}>
            <Box className={classes.mainItems}>
              {mainItems.map(
                (section, index) =>
                  (!section.requires || hasPermission(section.requires)) && (
                    <React.Fragment key={section.section}>
                      {index > 0 && (
                        <RowBox className={classes.dividerSection}>
                          {(expanded || (isHovered && !isPinned) || (isHoverMode && expanded)) && (
                            <Box className={classes.sectionLabel}>{section.section}</Box>
                          )}
                          <Divider className={classes.sectionDivider} />
                        </RowBox>
                      )}
                      {section.items.map((item) => {
                        if (item.requires && !hasPermission(item.requires)) return null
                        if (item.feature && !hasFeature(item.feature)) return null

                        // Ensure is active is a function
                        const isActive = typeof item.selected === "function" ? item.selected : () => false

                        return (
                          <RequirePermissions key={item.to} requires={item.requires} operator={item.operator}>
                            <NavLink
                              to={item.to}
                              className={classes.navItem}
                              activeClassName="active"
                              disabled={item.disabled}
                              isActive={isActive}
                            >
                              <Box className={classes.iconBox}>
                                <Icon name={item.icon} className={classes.icon} />
                                {(expanded || (isHovered && !isPinned) || (isHoverMode && expanded)) && (
                                  <Box className={classes.label}>{item.text}</Box>
                                )}
                              </Box>
                            </NavLink>
                          </RequirePermissions>
                        )
                      })}
                    </React.Fragment>
                  ),
              )}
            </Box>
          </List>

          <Box className={classes.bottomActions}>
            {modifiedBottomItems.map((item) => (
              <Button key={item.id} onClick={handleToggle} className={classes.bottomNavItem}>
                <Icon name={item.expandedIcon} className={classes.icon} />
                {(expanded || (isHovered && !isPinned) || (isHoverMode && expanded)) && (
                  <Box className={classes.bottomLabel}>{md ? t`Minimize` : item.text}</Box>
                )}
              </Button>
            ))}
          </Box>
        </Drawer>
      </Box>
    </>
  )
}
