import { Box, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  root: {
    animationName: "opacityPulse",
    animationIterationCount: "infinite",
    animationDuration: "1s",
  },
}))

const PulsingTimeSeparator = () => {
  const classes = useStyles()
  return (
    <Box component="span" className={classes.root}>
      :
    </Box>
  )
}

export { PulsingTimeSeparator }
