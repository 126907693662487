import { useQuery, gql } from "@apollo/client"

const sharedTextFields = `
  fontHeight
  align
  upperCase
  textWrap
`

const LABELS_FIELDS = `
    id
    title
    category { id name }
    template { id title }
    locations { id }
    image { id key fileName fileGroup fileSize }
    defaultImage
`

// TODO can this be combined with the LABEL_TEMPLATE_FIELDS query to reduce duplication?
const LABEL_FIELDS = `
    id
    title
    category { id name locations { id } }
    ready { readyValue readyUnit }
    discard { discardValue discardUnit discardEod}
    fields { 
      ... on LabelDynamicTextOncreateField {
        id
        fieldId
        type
        text
      }
      ... on LabelDynamicTextOnprintField {
        id
        fieldId
        type
        text
      }
      ... on LabelBarcodeField {
        id
        fieldId
        type
        text
      }
     }
    template { 
      id 
      title 
      width 
      height 
      padding 
      offsetX
      offsetY
      fields {
        id
        key
        x
        y
        width
        height
        content {
          ... on LabelTemplateNameField { 
            type
            ${sharedTextFields}
          } 
          ... on LabelTemplateUserField {
            type
            ${sharedTextFields}
            userFormat
          }
          ... on LabelTemplateLocationField {
            type
            ${sharedTextFields}
          }
          ... on LabelTemplateCurrentDatetimeField {
            type
            ${sharedTextFields}
            dateFormat
          }
          ... on LabelTemplatePrepDatetimeField {
            type
            ${sharedTextFields}
            dateFormat
          }
          ... on LabelTemplateReadyDatetimeField {
            type
            ${sharedTextFields}
            dateFormat
          }
          ... on LabelTemplateDiscardDatetimeField {
            type
            ${sharedTextFields}
            dateFormat
          }
          ... on LabelTemplateDynamicTextOncreateField {
            type
            ${sharedTextFields}
            dynamicName
            dynamicText
          }
          ... on LabelTemplateDynamicTextOnprintField {
            type
            ${sharedTextFields}
            dynamicName
            dynamicText
          }
          ... on LabelTemplateStaticTextField {
            type
            ${sharedTextFields}
            staticText
          }
          ... on LabelTemplateBarcodeField {
            type
            ${sharedTextFields}
            dynamicName
            dynamicText
            barcodeFormat
          }
        }
      }
     }
    locations { id }
    image { id key fileName fileGroup fileSize }
    defaultImage
`

const LABELS_QUERY = gql`
  query Labels($filter: LabelFilterInput) {
    labels(filter: $filter) {
      ${LABEL_FIELDS}
    }
  }
`

const LABEL_QUERY = gql`
  query Labels($id: ID!) {
    label(id: $id) {
      ${LABEL_FIELDS}
    }
  }
`

const LABEL_CREATED_SUBSCRIPTION = gql`
  subscription
    labelCreated {
      labelCreated {
        ${LABEL_FIELDS}
      }
    }
`
const LABEL_UPDATED_SUBSCRIPTION = gql`
  subscription
    labelUpdated {
      labelUpdated {
        ${LABEL_FIELDS}
      }
    }
`

const subscribe = (result) => () => [
  result.subscribeToMore({
    document: LABEL_CREATED_SUBSCRIPTION,
    updateQuery: (prev, { subscriptionData }) => {
      if (!subscriptionData.data) return prev
      const newItem = subscriptionData.data.labelCreated

      // skip if already exists
      if (prev.labels.some((p) => p.id === newItem.id)) return prev

      return { ...prev, labels: [newItem, ...prev.labels] }
    },
    onError: (err) => console.log("[useQueryLabels][LABEL_CREATED_SUBSCRIPTION(1)]", err),
  }),
  result.subscribeToMore({
    document: LABEL_UPDATED_SUBSCRIPTION,
    onError: (err) => console.log("[useQueryLabels][LABEL_UPDATED_SUBSCRIPTION]", err),
  }),
]

const subscribeSingle = (result) => () => [
  result.subscribeToMore({
    document: LABEL_UPDATED_SUBSCRIPTION,
    onError: (err) => console.log("[useQueryLabels][LABEL_UPDATED_SUBSCRIPTION(2)]", err),
  }),
]

const useQueryLabels = (options) => {
  const result = useQuery(LABELS_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribe(result),
  }
}

const useQueryLabel = (options) => {
  const result = useQuery(LABEL_QUERY, {
    fetchPolicy: "cache-and-network",
    ...options,
  })

  return {
    ...result,
    subscribe: subscribeSingle(result),
  }
}

export { useQueryLabels, useQueryLabel, LABEL_FIELDS, LABELS_FIELDS, LABELS_QUERY }
