import Base from "./Base"
import Preview from "./Preview"

const config = {
  ...Preview,
  clientDevice: true,
  clientLinkBaseUri: "https://preview-app.operandio.com",

  auth: Preview.auth ? { ...Preview.auth } : { ...Base.auth },
}

export default config
