import React from "react"
import { Grid, useTheme, useMediaQuery, makeStyles } from "@material-ui/core"
import { GroupedVirtuoso } from "react-virtuoso"
import { t } from "@lingui/macro"
import { SectionTitle, TimersItem } from ".."
import { TIMER_STATUS } from "../../data"

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: theme.spacing(1.5),
  },
}))

const timerSort = {
  options: [
    {
      id: "totalSecondsOnElapsedAsc",
      name: "Time elapsed",
      by: "totalSecondsElapsed",
      order: "asc",
    },
    {
      id: "totalSecondsOnElapsedDesc",
      name: "Time remaining",
      by: "totalSecondsElapsed",
      order: "desc",
    },
  ],
}

const TimersItemList = ({ timers, foodItems, searchText, sort }) => {
  const theme = useTheme()
  const classes = useStyles()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.only("sm"))

  const itemsPerRow = sm ? 3 : xs ? 2 : 4

  const sortOption = sort ? timerSort.options.find((option) => option.id === sort) : null

  const searchTextLower = searchText?.toLowerCase().trim() || null

  const groupedItems = [
    {
      name: t`Active Timers`,
      items:
        timers
          ?.filter(
            (item) =>
              ([TIMER_STATUS.ACTIVE, TIMER_STATUS.PAUSED].includes(item.status) && !searchTextLower) ||
              item.name.toLowerCase().includes(searchTextLower) ||
              item.foodItem?.name.toLowerCase().includes(searchTextLower),
          )
          .sort((a, b) => {
            if (sortOption) {
              return sortOption.order === "asc"
                ? a[sortOption.by] - b[sortOption.by]
                : b[sortOption.by] - a[sortOption.by]
            }
            return 0
          })
          .map((item) => ({ ...item, activeTimer: true })) || [],
    },
    {
      name: t`Inactive Timers`,
      items: foodItems?.filter((item) => !searchTextLower || item.name.toLowerCase().includes(searchTextLower)) || [],
    },
  ].filter((group) => group.items.length > 0)

  return (
    <GroupedVirtuoso
      useWindowScroll
      groupCounts={groupedItems.map((group) => {
        const _items = group.items
        const rows = _items.reduce((acc, curr, i) => {
          if (i % itemsPerRow === 0) {
            acc.push([])
          }
          acc[acc.length - 1].push(curr)
          return acc
        }, [])
        group.rows = rows
        return rows.length
      })}
      groupContent={(index) => <SectionTitle boxProps={{ pb: 2 }}>{groupedItems[index].name}</SectionTitle>}
      itemContent={(index) => {
        let currentRowIndex = 0
        let groupIndex = 0

        for (let i = 0; i < groupedItems.length; i++) {
          const group = groupedItems[i]
          if (currentRowIndex + group.rows.length > index) {
            groupIndex = i
            break
          }
          currentRowIndex += group.rows.length
        }

        const rowIndex = index - currentRowIndex
        const row = groupedItems[groupIndex].rows[rowIndex]

        return (
          <Grid container direction="row" spacing={xs ? 2 : 3} className={classes.item}>
            {row?.map((item) => (
              <TimersItem key={item.id} item={item} />
            ))}
          </Grid>
        )
      }}
    />
  )
}

export { TimersItemList, timerSort }
