import { Box, Hidden, IconButton, TableCell, TableRow } from "@material-ui/core"
import { EditOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import React, { useRef, useState } from "react"
import { NavLink } from "react-router-dom"
import { Trans, t } from "@lingui/macro"
import { useAuth } from "../../services"
import { toId } from "../../utils"
import { TruncateNames } from "../DataDisplay/TruncateNames"
import { AuthorTools, ErrorBoundary, Icon, LoadingSpinner, NoItemsMessage, RowBox, DisplayImage } from ".."
import { useMutationDeleteFoodItem } from "../../data/food-items/useMutationFoodItem"
import { TableVirtualizer } from "../Lists/TableVirtualizer"

const imageSize = 60
const useStyles = makeStyles((theme) => ({
  root: theme.cards.tableCard,
  name: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: "20px",
  },
  tableCellLeft: {
    whiteSpace: "nowrap",
  },
  imageContainer: {
    width: imageSize,
    height: imageSize,
    objectFit: "cover",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const FoodItemTable = ({ items, loading, loaded, onLoadMore, hasDelete = false, isInline = false }) => {
  const { hasPermission } = useAuth()
  const classes = useStyles()

  const tableContainerRef = useRef(null)

  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteFoodItem] = useMutationDeleteFoodItem()

  const handleDelete = async (foodItem) => {
    setDeleteLoading(toId(foodItem))
    await deleteFoodItem({ variables: { id: toId(foodItem) } })
    setDeleteLoading(false)
  }

  const handleLoadMore = () => {
    onLoadMore && onLoadMore()
  }

  const hasEditPermission = hasPermission("food_item_update")

  return (
    <>
      {loading && !loaded && (
        <Box display="flex" justifyContent="center">
          <LoadingSpinner size={60} />
        </Box>
      )}
      {loaded && (
        <div ref={tableContainerRef} style={{ overflow: "visible" }}>
          <TableVirtualizer
            useWindowScroll
            className={classes.root}
            items={items}
            endReached={handleLoadMore}
            fixedHeaderContent={() =>
              !isInline && (
                <TableRow>
                  <TableCell className={classes.tableCellLeft} width={imageSize}>
                    &nbsp;
                  </TableCell>
                  <TableCell className={classes.tableCellLeft}>
                    <Trans>Name</Trans>
                  </TableCell>
                  <TableCell className={classes.tableCellLeft}>
                    <Trans>Locations</Trans>
                  </TableCell>
                  <TableCell className={classes.tableCellLeft} />
                </TableRow>
              )
            }
            itemContent={(_, foodItem) => {
              return (
                <ErrorBoundary key={toId(foodItem)}>
                  <TableCell>
                    {foodItem.image && (
                      <NavLink to={`/food-items/${toId(foodItem)}`}>
                        <DisplayImage
                          className={classes.imageContainer}
                          fit="cover"
                          upload={foodItem.image}
                          height={imageSize}
                        />
                      </NavLink>
                    )}
                  </TableCell>
                  <TableCell>
                    <NavLink to={`/food-items/${toId(foodItem)}`}>
                      <Box display="flex" flexDirection="row">
                        <Box ml={1}>
                          <Box className={classes.name}>{foodItem.name}</Box>
                        </Box>
                      </Box>
                    </NavLink>
                  </TableCell>
                  {!isInline && (
                    <TableCell>
                      <TruncateNames
                        names={foodItem.locations?.map((group) => group.name)}
                        max={3}
                        emptyText={t`All locations`}
                      />
                    </TableCell>
                  )}
                  <TableCell>
                    <RowBox justifyContent="flex-end">
                      <IconButton
                        component={NavLink}
                        to={`/food-items/${toId(foodItem)}`}
                        data-cy={`food-item-view-${toId(foodItem)}`}
                      >
                        <Icon name="view" />
                      </IconButton>
                      {hasEditPermission && (
                        <Hidden xsDown>
                          <IconButton
                            component={NavLink}
                            to={`/food-items/${toId(foodItem)}/edit`}
                            data-cy={`food-item-edit-${toId(foodItem)}`}
                            disabled={!hasEditPermission}
                          >
                            <EditOutlined />
                          </IconButton>
                          {hasDelete && (
                            <AuthorTools
                              item={foodItem}
                              admins={{ delete: true }}
                              subject={t`food item`}
                              onDelete={() => handleDelete(foodItem)}
                              loading={deleteLoading === toId(foodItem)}
                            />
                          )}
                        </Hidden>
                      )}
                    </RowBox>
                  </TableCell>
                </ErrorBoundary>
              )
            }}
          />

          {!loading && items && !items.length && (
            <NoItemsMessage>
              <Trans>No matching items</Trans>
            </NoItemsMessage>
          )}
        </div>
      )}
    </>
  )
}

export { FoodItemTable }
