import { gql } from "@apollo/client"

const SEARCH_FIELDS = gql`
  fragment SearchFields on SearchResult {
    id
    title
    scheduleName
    processName
    displayName
    type
    date
    score
    organisation
    createdAt
    updatedAt
  }
`

const JOB_SEARCH_FIELDS = gql`
  fragment JobSearchFields on JobSearchResult {
    id
    title
    scheduleName
    processName
    displayName
    type
    date
    score
    organisation
    createdAt
    updatedAt
    status {
      completed
      submitted
      steps {
        completedAt
        responseType
      }
    }
    dueAt
    isOverdue
  }
`

const ACTION_SEARCH_FIELDS = gql`
  fragment ActionSearchFields on ActionSearchResult {
    id
    title
    scheduleName
    processName
    displayName
    type
    date
    score
    organisation
    createdAt
    updatedAt
    priority
    dueAt
    status
  }
`

export { SEARCH_FIELDS, JOB_SEARCH_FIELDS, ACTION_SEARCH_FIELDS }
