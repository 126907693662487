import React, { forwardRef, useEffect, useState } from "react"
import { Paper, Box, Divider, makeStyles, TextField } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import arrayMove from "array-move"
import pluralize from "pluralize"
import { Trans, t } from "@lingui/macro"
import { useMutationCreateTrainingCourse, useMutationUpdateTrainingCourse } from "../../data"
import { mapToIds, toId } from "../../utils"
import { TrainingModuleSearch, Checkbox } from ".."
import { RowBox } from "../Boxes"
import { CreatorActions, CreatorMaster } from "../Creators"
import { Caption, FieldSectionHeading } from "../Headings"
import { AdornmentIcon, Icon } from "../Icon"
import { DragHandle, SortableList } from "../Sortable"
import { DeleteConfirmIconButton, ShowMore } from "../Buttons"
import TagsField from "../TextField/TagsField"

const useArticleStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 8,
  },
  drag: {
    cursor: "pointer",
    color: theme.palette.text.secondary,
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  title: {
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: "500",
    color: theme.palette.text.primary,
  },
  caption: {
    fontSize: 12,
    lineHeight: "14px",
    color: theme.palette.text.secondary,
  },
}))

const TrainingModuleListItem = forwardRef(function TrainingModule({ itemIndex, item, onRemoveItem }, ref) {
  const classes = useArticleStyles()
  const { name, knowledges } = item

  const handleRemove = () => {
    onRemoveItem && onRemoveItem(itemIndex)
  }

  return (
    <Box ref={ref} className={classes.root}>
      {itemIndex > 0 && <Divider />}
      <RowBox p={2} py={2} alignItems="center">
        <DragHandle icon="drag" className={classes.drag} />
        <AdornmentIcon name="training-module" className={classes.icon} />
        <Box ml={1} mr="auto">
          <Box className={classes.title}>{name}</Box>
          <Box className={classes.caption}>{pluralize("article", knowledges.length, true)}</Box>
        </Box>
        <DeleteConfirmIconButton onDelete={handleRemove} />
      </RowBox>
    </Box>
  )
})

const TrainingCourseCreator = ({ edit, onClose }) => {
  const [createTrainingCourse, { loading: createTrainingLoading }] = useMutationCreateTrainingCourse()
  const [updateTrainingCourse, { loading: updateTrainingLoading }] = useMutationUpdateTrainingCourse()
  const [name, setName] = useState("")
  const [tags, setTags] = useState([])
  const [dueDays, setDueDays] = useState("")
  const [modules, setModules] = useState([])
  const [showUserScore, setShowUserScore] = useState(false)

  useEffect(() => {
    if (edit) {
      setName(edit.name)
      setDueDays(`${edit.dueDays || ""}`)
      setModules(edit.modules.map((module) => ({ id: toId(module), name: module.name, knowledges: module.knowledges })))
      setShowUserScore(edit.showUserScore)
      setTags(edit.tags)
    }
  }, [edit])

  const handleSubmit = async () => {
    const variables = {
      name,
      dueDays: dueDays ? Number(dueDays) : null,
      modules: mapToIds(modules),
      showUserScore,
      tags: mapToIds(tags),
    }
    if (edit) {
      await updateTrainingCourse({
        variables: {
          id: toId(edit),
          ...variables,
        },
      })
    } else {
      await createTrainingCourse({
        variables,
      })
    }
    onClose && onClose()
  }

  const handleNameChange = (event) => {
    setName(event.target.value)
  }

  const handleTagChange = (options) => {
    setTags(options)
  }

  const handleDueDaysChange = (event) => {
    setDueDays(event.target.value)
  }

  const handleAddModule = (item) => {
    if (modules.some((module) => toId(module) === toId(item))) {
      return
    }
    setModules((prev) => [...prev, { ...item }])
  }

  const handleRemoveModule = (index) => {
    setModules((state) => {
      state.splice(index, 1)
      return [...state]
    })
  }

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setModules((state) => {
      const next = arrayMove(state, oldIndex, newIndex)
      return [...next]
    })
  }

  const handleClose = () => {
    onClose && onClose()
  }

  const formValid = () => Boolean(name && modules.length)

  const isFormValid = formValid()

  const loading = createTrainingLoading || updateTrainingLoading

  const form = (
    <>
      <Box mb={3}>
        <Alert severity="info">
          <AlertTitle>
            <Trans>What are training courses?</Trans>
          </AlertTitle>
          <Box>
            <Trans>
              Courses combine several Modules and their Knowledge Base articles to form a course of content that your
              staff can complete online.
            </Trans>
          </Box>
          <ShowMore text={t`More Information`}>
            <Box my={2}>
              <Trans>
                You can assign Courses to your staff individually or in bulk in the <Trans>People</Trans> area.
              </Trans>
            </Box>
            <Box>
              <Trans>Staff members are then guided through the Knowledge Base articles you added to each module.</Trans>
            </Box>
          </ShowMore>
        </Alert>
      </Box>

      <Box mb={2}>
        <TextField
          label={t`Course name`}
          value={name}
          variant="outlined"
          fullWidth
          required
          onChange={handleNameChange}
          data-cy="TextField-name"
        />
      </Box>

      <Box mb={2}>
        <TagsField
          disabled={loading}
          value={tags ?? []}
          label="Tags"
          onChange={(_, options) => handleTagChange(options)}
        />
      </Box>

      <Divider />

      <FieldSectionHeading mt={2}>
        <Trans>Add training modules to your course</Trans>
      </FieldSectionHeading>

      <Box mb={3}>
        <TrainingModuleSearch placeholder={t`Start typing a module name...`} onItemSelected={handleAddModule} />
      </Box>

      {Boolean(modules.length) && (
        <>
          <FieldSectionHeading mb={0.5}>Modules</FieldSectionHeading>
          <Caption mb={1.5}>
            <Trans>List your modules in the order you want them to be completed</Trans>
          </Caption>
          <Box mb={3}>
            <Paper elevation={0}>
              <SortableList
                items={modules}
                itemComponent={TrainingModuleListItem}
                itemProps={{
                  onRemoveItem: handleRemoveModule,
                }}
                onSortEnd={handleSortEnd}
                useDragHandle
                disablePadding
              />
            </Paper>
          </Box>
          <Divider />

          <FieldSectionHeading mt={2} mb={0.5}>
            <Trans>This course should be completed within (optional)</Trans>
          </FieldSectionHeading>
          <Caption mb={1.5}>
            <Trans>
              The course will become overdue if not completed during the allocated timeframe. Leave blank for no set
              timeframe for completion.
            </Trans>
          </Caption>
          <RowBox mb={2}>
            <TextField
              type="number"
              placeholder={t`Unlimited`}
              value={dueDays}
              onChange={handleDueDaysChange}
              variant="outlined"
              inputProps={{ "data-cy": "TextField-dueDays", min: 0 }}
              style={{ width: 130 }}
              size="small"
            />
            <Box ml={1}>day(s)</Box>
          </RowBox>

          <Box mb={3}>
            <Divider />
            <FieldSectionHeading mt={2} mb={0.5}>
              <Trans>Scoring</Trans>
            </FieldSectionHeading>

            <Checkbox
              type="label"
              color="primary"
              label={t`Show user score`}
              checked={showUserScore}
              onChange={() => setShowUserScore(!showUserScore)}
            />

            <Caption mb={1.5}>
              <Trans>
                Users will be able to see their score in the completion email and their <Trans>Your Training</Trans>{" "}
                area.
              </Trans>
            </Caption>
          </Box>

          <Box mb={3}>
            <Alert icon={<Icon name="group" />} severity="info">
              <AlertTitle>
                <Trans>Assigning your course to staff</Trans>
              </AlertTitle>
              <Trans>
                When ready, save your course and head to the{" "}
                <strong>
                  <Trans>People</Trans>
                </strong>{" "}
                area. Assign it to individual staff through the <strong>Training courses</strong> tab in their profile,
                or assign to multiple staff at once using the{" "}
                <strong>
                  <Trans>Bulk actions</Trans>
                </strong>{" "}
                facility.
                <br />
                <br />
                You may also choose this training course when creating new people in the system.
              </Trans>
            </Alert>
          </Box>
        </>
      )}

      <CreatorActions
        id="TrainingCourseCreator-CreatorActions"
        subject={t`Course`}
        onClose={handleClose}
        onSubmit={handleSubmit}
        disableSubmit={!isFormValid || loading}
        submitLoading={loading}
      />
    </>
  )

  return (
    <CreatorMaster
      id="training-course-creator-dialog"
      open
      subject={t`Course`}
      form={form}
      isEdit={Boolean(edit)}
      isInline={false}
      onClose={handleClose}
    />
  )
}

export { TrainingCourseCreator }
