import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { Trans, t } from "@lingui/macro"
import ProcessCreator from "./ProcessCreator"
import { CreatorMaster } from "../Creators"
import { PROCESS_TYPE, useLazyQueryProcess } from "../../data"
import { NoItemsMessage } from "../Lists"

const useStyles = makeStyles((theme) => ({
  actions: {
    marginRight: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
}))

const TemplateCreator = ({ template: id, onClose, displayInline, setResult = null, onSuccess = null }) => {
  const classes = useStyles()
  const [load, { data, loading }] = useLazyQueryProcess()

  useEffect(() => {
    if (id) {
      load({ variables: { id } })
    }
  }, [id, load])

  const handleClose = (isCancel) => {
    onClose(isCancel)
  }

  const template = data?.process || null

  if (!id) {
    return null
  }

  let form
  let subject

  if (template) {
    subject = template.type === PROCESS_TYPE.AUDIT ? t`Audit` : t`Process`
    form = (
      <ProcessCreator
        onClose={handleClose}
        isInline={displayInline}
        classes={classes}
        edit={template}
        setResult={setResult}
        onSuccess={onSuccess}
      />
    )
  } else if (!loading) {
    form = (
      <NoItemsMessage>
        <Trans>Template not found</Trans>
      </NoItemsMessage>
    )
  }

  return (
    <>
      <CreatorMaster
        open={!!id}
        subject={subject}
        form={form}
        loading={loading}
        isEdit
        isInline={displayInline}
        onClose={handleClose}
      />
    </>
  )
}

export { TemplateCreator }
