import React from "react"
import { Grid, Box, Chip, Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useLocation } from "react-router-dom"
import { t } from "@lingui/macro"
import { AuthorTools, DisplayImage } from ".."
import { useAuth } from "../../services"
import { mapToIds } from "../../utils"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    flex: 1,
    cursor: "pointer",
    display: "flex",
  },
  paper: {
    "&:hover": theme.cards.hoverCard,
    "&:focus": theme.cards.hoverCard,
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  labelGrid: {
    display: "flex",
  },
  titleBox: {
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: "64px",
    maxHeight: "80px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  title: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 1.25,
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",
    overflow: "hidden",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
  imageFrame: {
    position: "relative",
  },
  labelChip: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 100,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "80%",
  },
  labelImage: {
    backgroundColor: theme.palette.background.dark,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "140px",
    [theme.breakpoints.down("sm")]: {
      height: "130px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "120px",
    },
  },
  defaultImagePadding: {
    padding: theme.spacing(2),
  },
  defaultImage: {
    borderRadius: 4,
    maxWidth: "100%",
    maxHeight: "100%",
  },
  customImage: {
    width: "100%",
    objectFit: "cover",
    minHeight: "100%",
  },
}))

const requiredPermissions = ["label_update", "label_update_all"]

const LabelsItem = ({ category, categoryChip, item, onEdit, onDelete }) => {
  const classes = useStyles()
  const routeLocation = useLocation()
  const {
    hasPermission,
    location,
    settings: { locations: userLocations },
  } = useAuth()
  const { id, title } = item

  const LabelAuthorTools = () => {
    const isGlobalCategory = category?.locations?.length === 0
    const isLocalCategory = category?.locations?.length > 0

    const userLocationIds = mapToIds(userLocations)
    const categoryLocationIds = mapToIds(category.locations)

    const canEditGlobal =
      hasPermission("label_update_all") ||
      (hasPermission("label_update") && item.locations.some((loc) => loc.id === location.id))
    const canEditLocal =
      hasPermission("label_update_all") ||
      (hasPermission("label_update") &&
        categoryLocationIds.some((loc) => userLocationIds.includes(loc)) &&
        item.locations.some((loc) => loc.id === location.id))

    // Need label update permissions firstly
    if (!hasPermission("label_update") && !hasPermission("label_update_all")) {
      return null
    }

    // If the item is in a global category, the user must have label_update and the item must be in a location that the user has access to
    if (isGlobalCategory && !canEditGlobal) {
      return null
    }

    // If the label is in a local category, the user must have label_update and have access to the category location. Plus the label location must be the same as the current location
    if (isLocalCategory && !canEditLocal) {
      return null
    }

    // Users can only edit local labels at their location
    if (isLocalCategory && !canEditLocal) {
      return null
    }
    return (
      <Box ml="auto">
        <AuthorTools
          item={item}
          subject={t`label`}
          admins={{ edit: requiredPermissions, delete: requiredPermissions }}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </Box>
    )
  }

  return (
    <Grid item lg={3} md={3} sm={4} xs={6} className={classes.labelGrid}>
      <Box
        component={NavLink}
        to={{ pathname: `/labels/item/${id}/print`, state: { background: routeLocation } }}
        className={classes.root}
      >
        <Paper elevation={0} className={classes.paper}>
          <Box className={classes.imageFrame}>
            {categoryChip && <Chip label={category.name} size="small" className={classes.labelChip} />}
            {item.defaultImage ? (
              <Box className={`${classes.labelImage} ${classes.defaultImagePadding}`}>
                <DisplayImage className={classes.defaultImage} fit="inside" upload={item.image} height={200} />
              </Box>
            ) : (
              <Box className={classes.labelImage}>
                <DisplayImage
                  className={classes.customImage}
                  fit="cover"
                  upload={item.image}
                  width={300}
                  height={200}
                />
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row" className={classes.titleBox}>
            <Box display="flex" flexDirection="row" flexGrow={1}>
              <Box display="flex">
                <Typography variant="h4" className={classes.title}>
                  {title}
                </Typography>
              </Box>
            </Box>
            <LabelAuthorTools />
          </Box>
        </Paper>
      </Box>
    </Grid>
  )
}

export { LabelsItem }
