import { useQuery, gql } from "@apollo/client"
import { PUBLIC_JOB_FIELDS } from "./fragments/publicJobFieldsFragment"

const PUBLIC_JOB_QUERY = gql`
  query PublicJob($process: ID!, $schedule: ID!) {
    publicJob(process: $process, schedule: $schedule) {
      ...PublicJobFields
    }
  }
  ${PUBLIC_JOB_FIELDS}
`

const useQueryPublicJob = (options) =>
  useQuery(PUBLIC_JOB_QUERY, {
    ...options,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  })

export { useQueryPublicJob }
