import moment from "moment"

const secondsOf4hrs = 4 * 60 * 60
const secondsOf2hrs = 2 * 60 * 60

const useTimerUtils = () => {
  const totalSecondsElapsed = ({ startedAt, endedAt, breaks }) => {
    if (!startedAt) {
      return 0
    }

    const endTime = endedAt ? moment(endedAt) : moment()

    const totalSecondsOnBreak = breaks.reduce((acc, { pausedAt, resumedAt }) => {
      if (!pausedAt) return acc
      if (resumedAt) {
        return acc + moment(resumedAt).diff(moment(pausedAt), "seconds")
      }
      if (endedAt) {
        return acc + moment(endedAt).diff(moment(pausedAt), "seconds")
      }
      return acc + moment().diff(moment(pausedAt), "seconds")
    }, 0)

    const _totalSecondsOnElapsed = endTime.diff(moment(startedAt), "seconds") - totalSecondsOnBreak

    if (_totalSecondsOnElapsed <= 0) {
      return 0
    }
    return _totalSecondsOnElapsed
  }

  return { totalSecondsElapsed }
}

export { useTimerUtils, secondsOf2hrs, secondsOf4hrs }
