import React from "react"
import { makeStyles, Box } from "@material-ui/core"
import { ModuleHeading } from "../Headings"
import { DisplayImage, MentionOutput } from ".."
import { RowBox } from "../Boxes"

const imageSize = 120
const useStyles = makeStyles((theme) => ({
  detail: theme.cards.viewerCard,
  detailInner: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  locations: {
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: "16px",
  },
  description: {
    marginTop: 4,
    color: theme.palette.text.primary,
    fontSize: 14,
    lineHeight: "16px",
  },
  imageContainer: {
    width: imageSize,
    height: imageSize,
    objectFit: "cover",
    marginBottom: theme.spacing(2),
    borderRadius: 4,
  },
  wrapper: {
    padding: theme.spacing(3),
  },
}))

const FoodItemPanel = ({ layout = "portrait", foodItem: { name, locations, description, image }, ...rest }) => {
  const classes = useStyles({ layout })

  return (
    <Box className={classes.detail} {...rest}>
      <RowBox gap={image ? 2 : 0} alignItems="flex-start" className={image ? classes.wrapper : ""}>
        {image && <DisplayImage className={classes.imageContainer} fit="cover" upload={image} height={imageSize} />}

        <Box className={[classes.detailInner, !image ? classes.wrapper : ""]}>
          <ModuleHeading noMargin>{name}</ModuleHeading>
          {locations && locations.length > 0 && (
            <Box className={classes.locations}>{locations.map((location) => location.name).join(", ")}</Box>
          )}
          {description && (
            <Box className={classes.description}>
              <MentionOutput content={description} />
            </Box>
          )}
        </Box>
      </RowBox>
    </Box>
  )
}

export { FoodItemPanel }
