import React, { useState } from "react"
import { t, Trans } from "@lingui/macro"
import { Box, Button, makeStyles, TextField, Typography, useTheme } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { FieldSectionHeading } from "../Headings"
import { CreatorMaster } from "../Creators"
import { useMutationStartTimer, useQueryFoodItem } from "../../data"
import { LoadingSpinner } from "../LoadingSpinner"
import { useAuth } from "../../services"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 326,
  },
  readout: {
    color: ({ display: { color } }) => color || theme.palette.info.main,
    textAlign: "center",
    padding: theme.spacing(2),
    backgroundColor: ({ display: { backgroundColor } }) => backgroundColor || theme.palette.info.background,
    borderRadius: 8,
    border: ({ display: { color } }) => `2px solid ${color || theme.palette.info.main}`,
  },
  value: {
    fontSize: 56,
    fontWeight: 700,
    lineHeight: "100%",
  },
  message: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: "25px",
  },
  jumboButtons: {
    width: "100%",
    height: "56px",
    display: "flex",
    flexShrink: 0,
  },
}))

const initialTimer = {
  name: "",
}

const TimersItemCreator = ({ open, onClose, isInline }) => {
  const theme = useTheme()
  const { id } = useParams()
  const { location } = useAuth()

  const display = {
    message: "Timer ready",
    color: theme.palette.success.main,
    backgroundColor: theme.palette.success.background,
  }

  const classes = useStyles({ display })

  const { data: dataFoodItem, loading: foodItemLoading } = useQueryFoodItem({
    variables: {
      id,
    },
  })

  const [startTimer, { loading: startTimerLoading }] = useMutationStartTimer()

  const [name, setName] = useState(initialTimer.name)

  const handleClose = () => {
    onClose && onClose()
  }

  const handleChange = (set, value) => {
    set(value)
  }

  const handleStartTimer = async () => {
    await startTimer({
      variables: {
        input: {
          name,
          foodItem: dataFoodItem?.foodItem?.id,
          location: toId(location),
        },
      },
    })
    handleClose()
  }
  const submitLoading = startTimerLoading

  const title = t`Start timer`

  const isValid = name.length > 0

  const form = (
    <>
      <FieldSectionHeading>{dataFoodItem?.foodItem?.name}</FieldSectionHeading>
      <Box mb={2}>
        <TextField
          variant="outlined"
          fullWidth
          id="name"
          label={t`Name`}
          name="name"
          value={name}
          onChange={(event) => handleChange(setName, event.target.value)}
          data-cy="TextField-name"
          required
        />
      </Box>
      <Box className={classes.readout} mb={2}>
        <Box className={classes.value}>0:00</Box>
        <Box className={classes.message}>{display.message}</Box>
      </Box>
      <Box mb={2}>
        <Typography>
          <Trans>Start this timer when this item has been removed from fridge.</Trans>
        </Typography>
      </Box>
      <Box mb={2} display="flex" flexDirection="column" gridGap={8}>
        <Button
          variant="contained"
          color="primary"
          className={classes.jumboButtons}
          onClick={handleStartTimer}
          disabled={submitLoading || !isValid}
        >
          {submitLoading && <LoadingSpinner size="24px" delay={false} />}
          {!submitLoading && <Trans>Start timer</Trans>}
        </Button>
        <Button color="primary" className={classes.jumboButtons} onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
      </Box>
    </>
  )

  return (
    <CreatorMaster
      loading={foodItemLoading}
      maxWidth="xs"
      id="TimersItemCreator"
      open={open}
      title={title}
      form={form}
      isInline={isInline}
      onClose={handleClose}
    />
  )
}

export { TimersItemCreator }
