import React, { useState } from "react"
import Config from "react-global-configuration"
import * as Sentry from "@sentry/browser"
import { Box, Button, Container, makeStyles, Typography } from "@material-ui/core"
import { useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { useApolloClient } from "@apollo/client"
import { LoadingSpinner, PaperBox, PinInput } from "../../components"
import { useFormUtils } from "../../utils"
import { PublicContainer } from "./PublicContainer"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    padding: 0,
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    marginBottom: theme.spacing(5),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    textAlign: "center",
  },
  pin: {
    display: "flex",
    justifySelf: "center",
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  errorText: {
    fontSize: 8,
    color: theme.palette.error.main,
  },
  errorAvatar: {
    backgroundColor: theme.palette.error.light,
  },
}))

const TabletPin = () => {
  const classes = useStyles()
  const { pinLength } = Config.get("auth")
  const { isValid } = useFormUtils()
  const client = useApolloClient()
  const { updatePin } = useAuth(client)
  const history = useHistory()
  const [pin, setPin] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (formValid()) {
      setLoading(true)
      try {
        await updatePin(pin)
        history.push("/")
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)
        setLoading(false)
      }
    }
  }

  const formValid = () => isValid(pin) && pin.length === pinLength

  const isFormValid = formValid()

  return (
    <PublicContainer>
      <PaperBox p={4}>
        <Container component="main" className={classes.root}>
          <div className={classes.wrapper}>
            <>
              <Typography align="center">
                <Trans>
                  An administrator has requested that you set a new tablet PIN.
                  <br />
                  <br />
                  Please choose a new 4-digit PIN to continue using the app.
                </Trans>
              </Typography>

              <Box mt={2} mb={3}>
                <PinInput
                  alignItems="center"
                  length={pinLength}
                  onChange={setPin}
                  className={classes.pin}
                  password
                  margin="normal"
                  allowShow
                  showLoading={false}
                  disabled={loading}
                />
              </Box>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
                size="large"
                disabled={loading || !isFormValid}
              >
                {loading && <LoadingSpinner size="30px" delay={false} />}
                {!loading && <Trans>Save</Trans>}
              </Button>
            </>
          </div>
        </Container>
      </PaperBox>
    </PublicContainer>
  )
}

export { TabletPin }
