import { useMutation, gql } from "@apollo/client"
import { TRAINING_COURSE_FIELDS } from "./useQueryTrainingCourses"

const UPDATE_TRAINING_COURSE_MUTATION = gql`
  mutation UpdateTrainingCourse(
    $id: ID!
    $name: String!
    $description: String
    $dueDays: Int
    $modules: [ID!]!
    $showUserScore: Boolean!
    $tags: [ID!]
  ) {
    updateTrainingCourse(
      id: $id
      name: $name
      dueDays: $dueDays
      description: $description
      modules: $modules
      showUserScore: $showUserScore
      tags: $tags
    ) {
      ${TRAINING_COURSE_FIELDS}
    }
  }
`

const useMutationUpdateTrainingCourse = () => useMutation(UPDATE_TRAINING_COURSE_MUTATION)

export { useMutationUpdateTrainingCourse }
