import { makeVar } from "@apollo/client"
import { useRef, useEffect, useCallback } from "react"
import { useMountAwareReactiveVar } from "../../utils"

const searchStateVar = makeVar({
  focused: false,
  searchText: "",
})

const useSearchState = (options) => {
  const effectiveOptions = {
    debounce: 300,
    ...(options || {}),
  }
  const { debounce } = effectiveOptions

  const state = useMountAwareReactiveVar(searchStateVar)
  const timeoutRef = useRef(null)

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  // Sets search text immediately without debouncing
  const setSearchTextImmediate = (searchText) => {
    const trimmedSearchText = searchText || ""

    if (trimmedSearchText === state.searchText) {
      return
    }

    searchStateVar({
      ...state,
      searchText: trimmedSearchText,
    })
  }

  // Sets search text with debouncing
  const setSearchText = useCallback(
    (searchText) => {
      const trimmedSearchText = searchText || ""

      if (trimmedSearchText === state.searchText) {
        return
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        searchStateVar({
          ...state,
          searchText: trimmedSearchText,
        })
      }, debounce)
    },
    [state, debounce],
  )

  const clearSearch = () => {
    searchStateVar({
      ...state,
      focused: true,
      searchText: "",
    })
  }

  const clearAll = () => {
    searchStateVar({
      ...state,
      focused: false,
      searchText: "",
    })
  }

  const setFocused = (focused) => {
    searchStateVar({
      ...state,
      focused,
    })
  }

  return {
    setSearchText,
    setSearchTextImmediate,
    setFocused,
    clearSearch,
    clearAll,

    searchText: state.searchText,
    focused: state.focused,
  }
}

export { searchStateVar, useSearchState }
