import React, { useState, useEffect } from "react"
import { TextField, InputAdornment, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import SearchIcon from "@material-ui/icons/Search"
import { Clear as ClearIcon } from "@material-ui/icons"
import { t } from "@lingui/macro"
import { useMountEffect } from "../../utils"
import { FlexBox, LoadingSpinner } from ".."

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    fontSize: 16,
    color: theme.palette.text.primary,
    marginBottom: ({ mb }) => (mb ? theme.spacing(mb) : 0),
  },
}))

const baseBoxProps = {
  pl: 0,
  pr: 0,
  mb: 2,
}

const SearchInput = ({
  onChange,
  initialValue,
  placeholder = t`Search`,
  debounce = false,
  loading = false,
  cy = "SearchInput",
  inputProps = {},
  boxProps = {},
  ...rest
}) => {
  const { mb, pl, pr } = { ...baseBoxProps, ...boxProps }
  const { clearButton: clearButtonClass, ...classes } = useStyles({ mb })
  const [searchText, setSearchText] = useState("")
  const [sentText, setSentText] = useState("")

  useEffect(() => {
    if (debounce && sentText !== searchText) {
      const timer = setTimeout(() => {
        setSentText(searchText)
        onChange(searchText)
      }, debounce)
      return () => clearTimeout(timer)
    }
  })

  useMountEffect(() => {
    if (initialValue) {
      setSearchText(initialValue)
    }
  })

  const handleChange = (event) => {
    setSearchText(event.target.value)
    if (onChange && !debounce) {
      onChange(event.target.value, event)
    }
  }

  const handleClear = (event) => {
    setSearchText("")
    if (onChange) {
      onChange("", event)
    }
  }

  const displayLoading = loading && searchText

  return (
    <FlexBox pl={pl} pr={pr} {...boxProps}>
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={searchText}
        onChange={handleChange}
        fullWidth
        className={classes.root}
        InputProps={{
          "data-cy": cy,
          startAdornment: (
            <InputAdornment position="start">
              {!displayLoading && <SearchIcon />}
              {displayLoading && <LoadingSpinner size="24px" opacity={1} delay={false} />}
            </InputAdornment>
          ),
          endAdornment: searchText ? (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClear} className={clearButtonClass}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ) : null,
          ...inputProps,
        }}
        {...rest}
      />
    </FlexBox>
  )
}

export default SearchInput
