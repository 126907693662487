import React, { useState, useEffect } from "react"
import { Container, Box, useTheme, useMediaQuery, Fab } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { ErrorBoundary } from "../ErrorBoundary"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: ({ split }) => (split ? "flex" : "block"),
    flexGrow: 1,
    flexDirection: "column",
    flex: 1,
    background: theme.palette.grey[25],
    minHeight: "100vh",
  },
  split: {
    flexGrow: 1,
    background: theme.palette.grey[25],
    minHeight: "100vh",
  },
  sidebar: {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    height: "100%",
    position: "relative",
    display: ({ md }) => (md ? "none" : "block"),
  },
  sidebarContent: {
    position: "sticky",
    top: ({ hasPunchClock }) => `${20 + theme.dimensions.header.height + (hasPunchClock ? 60 : 0)}px`,
    height: ({ hasPunchClock }) =>
      `calc(100vh - ${theme.dimensions.header.height}px ${hasPunchClock ? "- 48px" : ""} - ${theme.spacing(6)}px)`,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  main: {
    width: "100%",
  },
  filters: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  content: {
    paddingTop: ({ pt }) => pt,
    flexGrow: ({ split }) => (split ? 1 : "none"),
    paddingLeft: 0,
    paddingRight: 0,
  },
  contained: {
    maxWidth: ({ containerSize }) =>
      containerSize ? theme.breakpoints.values[containerSize] : theme.breakpoints.values.md,
    marginRight: "auto",
    marginLeft: "auto",
  },
  tabs: {
    position: "sticky",
    top: 60,
    zIndex: 1000,
  },
  backToTop: {
    position: "fixed",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 1000,
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))

const Content = ({
  split,
  children,
  pt: ptInput,
  disableGutters = false,
  start = false,
  sideBar = null,
  sideBarMinWidth = 260,
  tabs = null,
  filters = null,
  full = false,
  containerSize = "md",
  hasPunchClock = false,
  backToTop = null,
}) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const [showBackToTop, setShowBackToTop] = useState(false)
  const pt =
    typeof ptInput !== "undefined"
      ? theme.spacing(ptInput)
      : xs
        ? theme.spacing(2)
        : md
          ? theme.spacing(2)
          : theme.spacing(5)
  const classes = useStyles({ split, pt, hasPunchClock, md, containerSize })

  useEffect(() => {
    if (!backToTop?.show) return

    const handleScroll = () => {
      setShowBackToTop(window.pageYOffset > (backToTop.offset || 300))
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [backToTop])

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <ErrorBoundary>
      <Box className={classes.root} justifyContent="center" height="100%">
        <Box className={split ? classes.split : null} id={start ? "content" : ""} height="100%">
          {tabs && (
            <Box className={classes.tabs} width="100%" bgcolor="background.paper">
              {tabs}
            </Box>
          )}
          <RowBox alignItems="flex-start" height="100%" width="100%">
            {sideBar && (
              <Box px={3} py={3} className={classes.sidebar}>
                <Box className={classes.sidebarContent} minWidth={sideBarMinWidth}>
                  {sideBar}
                </Box>
              </Box>
            )}
            <Box className={classes.main}>
              {filters && (
                <Box py={2} px={xs ? 2 : md ? 3 : 4} className={classes.filters}>
                  {filters}
                </Box>
              )}
              <Container maxWidth={false} className={classes.content} disableGutters={disableGutters}>
                <Box px={xs ? 2 : md ? 3 : 4} className={full ? null : classes.contained}>
                  {children || <span />}
                </Box>
              </Container>
            </Box>
          </RowBox>
        </Box>
        {backToTop?.show && showBackToTop && !xs && (
          <Fab aria-label="scroll back to top" className={classes.backToTop} onClick={handleBackToTop}>
            <Icon name="up" color="primary" />
          </Fab>
        )}
      </Box>
    </ErrorBoundary>
  )
}

export default Content
