import React, { useState } from "react"
import { Box, Button, Grid, TextField } from "@material-ui/core"
import { Caption } from "../Headings"
import { Icon } from "../Icon"
import { toId, useFormUtils } from "../../utils"
import { LoadingSpinner } from "../LoadingSpinner"
import { IntegrationProviderAttributeOutlinedSelect } from "../OutlinedSelect/IntegrationProviderAttributeOutlinedSelect"
import { IntegrationAttributeAutocompleteComponent } from "./IntegrationAttributeAutocompleteComponent"

const IntegrationMappingAddAttributeForm = ({ integration, source, components: { Destination }, loading, onAdd }) => {
  const { isValid } = useFormUtils()
  const [key, setKey] = useState("")
  const [value, setValue] = useState("")
  const [destination, setDestination] = useState("")

  const handleAdd = () => {
    onAdd && onAdd({ key, value, destination })
    setKey("")
    setValue("")
    setDestination("")
  }

  const handleValueChange = (newValue) => {
    setValue(newValue?.key || "")
  }

  const handleDestinationChange = (newDestination) => {
    setDestination(newDestination)
  }

  const integrationProviderAttribute = integration.integrationProvider.attributes[source].find(
    (item) => toId(item.key) === key,
  )

  const formValid = isValid(destination, key, value)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <IntegrationProviderAttributeOutlinedSelect
            label="Attribute"
            integration={integration}
            source={source}
            value={key}
            onChange={(event) => setKey(event.target.value)}
            fullWidth
            required
            disabled={loading}
          />
        </Grid>
        <Grid item xs={4}>
          {integrationProviderAttribute && integrationProviderAttribute.dataSource ? (
            <IntegrationAttributeAutocompleteComponent
              type="group"
              integration={integration}
              attribute={integrationProviderAttribute}
              value={value}
              onChange={handleValueChange}
              loading={loading}
            />
          ) : (
            <TextField
              label={integrationProviderAttribute?.name || "Value"}
              variant="outlined"
              placeholder="Not set"
              fullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={!integrationProviderAttribute || loading}
              required
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <Destination onChange={handleDestinationChange} value={destination} loading={loading} />
        </Grid>
      </Grid>
      {integrationProviderAttribute && <Caption mt={1}>{integrationProviderAttribute.description}</Caption>}
      <Box mt={2}>
        <Button variant="contained" color="primary" fullWidth onClick={handleAdd} disabled={!formValid || loading}>
          {!loading && (
            <>
              <Icon name="add" /> Add mapping
            </>
          )}
          {loading && <LoadingSpinner size="24px" />}
        </Button>
      </Box>
    </>
  )
}

export { IntegrationMappingAddAttributeForm }
