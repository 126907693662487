import React, { useState, useEffect } from "react"
import { Typography } from "@material-ui/core"
import { t, Trans } from "@lingui/macro"
import { ColumnBox, FieldSectionHeading, Icon, PinInput, useSnackbar } from ".."

const VerifyMultiFactorAuthentication = ({ pinInputRef, onVerifyPasscode }) => {
  const snackbar = useSnackbar()
  const [clearPin, setClearPin] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (clearPin) setClearPin(false)
  }, [clearPin])

  const handleVerifyPasscode = async (totpPasscode) => {
    if (!totpPasscode || totpPasscode.length !== 6) {
      setClearPin(true)
      snackbar.showMessage({ message: t`Invalid verification code. Please try again.`, icon: <Icon name="security" /> })
      return
    }

    setLoading(true)

    try {
      await onVerifyPasscode(totpPasscode)
    } catch (error) {
      setClearPin(true)
      snackbar.showMessage({ message: t`Failed to verify code. Please try again.`, icon: <Icon name="security" /> })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <FieldSectionHeading>
        <Trans>Two-Factor Authentication Required</Trans>
      </FieldSectionHeading>

      <ColumnBox gap={3} alignItems="center">
        <Typography>
          <Trans>Enter the 6-digit verification code from your authenticator app.</Trans>
        </Typography>

        <PinInput
          inputRef={pinInputRef}
          length={6}
          onChange={handleVerifyPasscode}
          clear={clearPin}
          justifyContent="center"
          disableComplexityCheck
          autoFocus
          disabled={loading}
        />
      </ColumnBox>
    </>
  )
}

export { VerifyMultiFactorAuthentication }
