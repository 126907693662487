import React, { useMemo } from "react"
import { TextField } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { LoadingSpinner } from "../LoadingSpinner"
import { useQueryIntegrationDataSourceValues } from "../../data/integrations/useQueryIntegration"

const IntegrationAttributeAutocompleteComponent = ({ type, integration, location, attribute, value, onChange }) => {
  const { loading, values: dataSourceValues } = useQueryIntegrationDataSourceValues({
    integration,
    location,
    attribute,
    type,
  })

  const values = useMemo(() => {
    const results = [...(dataSourceValues || [])]

    if (value && !results.find((item) => item.key === value)) {
      results.push({ key: value, value })
    }

    return results
  }, [dataSourceValues, value])

  const handleChange = async (_, newValue) => {
    onChange && onChange(newValue)
  }

  const optionValue = useMemo(() => values?.find((item) => item.key === value) || null, [values, value])

  if (!attribute.dataSource) {
    throw new Error("Attribute is missing data source, choose another component")
  }

  return (
    <Autocomplete
      value={optionValue}
      autoHighlight
      getOptionLabel={(option) => `${option.value} (#${option.key})`}
      getOptionSelected={(option) => option.key === optionValue.key}
      options={values || []}
      loading={loading}
      disabled={loading}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={attribute.name}
          variant="outlined"
          placeholder="Not set"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <LoadingSpinner size={24} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export { IntegrationAttributeAutocompleteComponent }
