import { gql, useQuery } from "@apollo/client"
import { JOB_NOTES_FIELDS } from "./fragments/jobNotesFieldsFragment"

const JOB_NOTES_QUERY = gql`
  query JobStep($job: ID!) {
    job(id: $job) {
      notes {
        ...JobNotesFields
      }
    }
  }
  ${JOB_NOTES_FIELDS}
`

const jobStepNoteTypePolicies = {
  root: {},
  scoped: {
    JobProcessStepNote: {
      fields: {
        edited: {
          read(_, { readField }) {
            const createdAt = readField("createdAt")
            const updatedAt = readField("updatedAt")

            if (!createdAt || !updatedAt) {
              throw new Error("createdAt and updatedAt must be requested to use edited field")
            }

            return createdAt !== updatedAt
          },
        },
      },
    },
  },
}

const useQueryJobNotes = (options) => useQuery(JOB_NOTES_QUERY, options)

export { useQueryJobNotes, jobStepNoteTypePolicies }
