import { Dialog, DialogTitle, DialogContent, Box, Button, IconButton } from "@material-ui/core"
import { QRCodeSVG } from "qrcode.react"
import QRCode from "qrcode"
import { makeStyles } from "@material-ui/styles"
import { Link } from "@material-ui/icons"
import { Trans, t } from "@lingui/macro"
import { useTheme } from "@material-ui/core/styles"
import { disableBackdropClick } from "../../utils"
import { DialogTitleCloser } from "../Creators/DialogTitleCloser"
import { ProcessSuccessBlockquote } from "../Blockquotes"
import { RowBox } from "../Boxes"
import { Icon } from "../Icon"
import { useSnackbar } from ".."
import { TruncateNames } from "../DataDisplay/TruncateNames"

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 326,
  },
  schedules: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 8,
    width: "100%",
  },
  schedule: {
    padding: theme.spacing(1, 2),
    alignItems: "center",
  },
  scheduleDivider: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  description: {
    fontSize: 12,
    color: theme.palette.text.secondary,
    wordBreak: "break-all",
    overflowWrap: "break-word",
  },
  qrCode: {
    width: 54,
    height: 54,
  },
}))

export const ProcessSuccessDialog = (props) => {
  const { result, onClose } = props
  const classes = useStyles()
  const snackbar = useSnackbar()
  const theme = useTheme()

  const getQRCodeLink = (schedule) => {
    return `${window.location.origin}/form/${result?.id}/${schedule.id}`
  }

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
    snackbar.showMessage({ message: t`Link copied to clipboard` })
  }

  const handleDownloadQRCode = (schedule) => {
    const link = getQRCodeLink(schedule)
    QRCode.toDataURL(link, { width: 512, height: 512 })
      .then((url) => {
        const a = document.createElement("a")
        a.href = url
        a.download = `${schedule.name}.png`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch((err) => {
        snackbar.showMessage({ message: t`Error downloading QR code`, color: theme.palette.error.main })
      })
  }

  return (
    <Dialog
      open
      onClose={disableBackdropClick(onClose)}
      classes={{ paper: classes.dialogPaper }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Trans>Get access links</Trans>
        <DialogTitleCloser onClose={onClose} />
      </DialogTitle>
      <DialogContent>
        <ProcessSuccessBlockquote show />

        {/* QR Codes by Schedules */}
        {result?.schedules?.length > 0 && (
          <Box className={classes.schedules} mb={3}>
            {result?.schedules?.map((schedule, index) => {
              const locations = schedule.locations.map((location) => location.name)
              return (
                <RowBox
                  className={`${classes.schedule} ${index !== result?.schedules?.length - 1 && classes.scheduleDivider}`}
                  key={schedule.id}
                  gap={1}
                >
                  {/* QR Code */}
                  <Box className={classes.qrCode}>
                    <QRCodeSVG value={getQRCodeLink(schedule)} size={54} />
                  </Box>

                  {/* Title and Description */}
                  <Box flex={1}>
                    <Box className={classes.title}>
                      <Trans>{schedule.name}</Trans>
                    </Box>

                    <Box className={classes.description}>
                      <Trans>{TruncateNames({ names: locations })}</Trans>
                    </Box>

                    <Box className={classes.description}>
                      <Trans>{getQRCodeLink(schedule)}</Trans>
                    </Box>
                  </Box>

                  <RowBox gap={1}>
                    {/* Download icon */}
                    <IconButton size="small" onClick={() => handleDownloadQRCode(schedule)}>
                      <Icon name="download" />
                    </IconButton>

                    {/* Copy link icon */}
                    <IconButton size="small" onClick={() => handleCopyLink(getQRCodeLink(schedule))}>
                      <Link />
                    </IconButton>
                  </RowBox>
                </RowBox>
              )
            })}
          </Box>
        )}

        <Box mb={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
