import React from "react"
import { Box, Typography, Breadcrumbs, useTheme, useMediaQuery } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { NavLink, useHistory } from "react-router-dom"
import { AuthorTools } from "../Author"
import { useAuth } from "../../services"
import { RowBox } from "../Boxes"

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    fontWeight: "600",
    lineHeight: "30px",
    alignSelf: "center",
  },
}))

const LabelsCategory = ({ kiosk, device, category, onEdit, onDelete }) => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()
  const { name } = category
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const { hasPermission, location } = useAuth()

  const handleDelete = (event) => {
    onDelete && onDelete(event)
    history.push("/labels")
  }

  const canUpdateGlobalCategory = category?.locations?.length === 0 && hasPermission("label_update_all")
  const canUpdateLocalCategory =
    category?.locations?.some((loc) => loc.id === location.id) && hasPermission("label_update")

  return (
    <>
      <RowBox mb={1}>
        <Typography variant="h3" className={classes.title}>
          {name}
        </Typography>
        <Box ml="auto">
          <AuthorTools
            item={category}
            author={category.author.id}
            admins={{
              edit: canUpdateGlobalCategory || canUpdateLocalCategory,
              delete: canUpdateGlobalCategory || canUpdateLocalCategory,
            }}
            onEdit={onEdit}
            onDelete={category.numberOfLabels ? null : handleDelete}
            subject="category"
          />
        </Box>
      </RowBox>
      {!kiosk && !device && (
        <Box mb={xs ? 2 : 3}>
          <Breadcrumbs>
            <NavLink to="/labels">All labels</NavLink>
            <Box>{name}</Box>
          </Breadcrumbs>
        </Box>
      )}
    </>
  )
}

export { LabelsCategory }
