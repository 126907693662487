import React from "react"
import moment from "moment"

export default ({ value, format = "Do MMMM YYYY" }) => {
  try {
    const date = moment(value)

    return (
      <span className="format-date" title={moment(date).local().format()}>
        {value && date.format(format)}
      </span>
    )
  } catch (ex) {
    return <span>Data invalid.</span>
  }
}
