import React from "react"
import { Box, List, Hidden } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { useParams, useLocation } from "react-router-dom"
import { Trans } from "@lingui/macro"
import { SubNavigationListItem, SubNavigation, TextDivider } from ".."
import { useAuth } from "../../services"
import { useQueryLabelCategories } from "../../data"
import { toId } from "../../utils"

const useStyles = makeStyles((theme) => ({
  horizontalNavContainer: {
    zIndex: 200,
    display: "inline-grid",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
  },
  horizontalNavItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    overflowX: "auto",
    "& li": {
      display: "inline-table",
      minWidth: "100px",
      borderRadius: theme.spacing(0.5),
      "& a": {
        justifyContent: "space-around",
        borderRadius: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginBottom: 0,
      },
    },
  },
}))

const LabelsSubNav = ({ variant }) => {
  const classes = useStyles()
  const { location: userLocation } = useAuth()
  const { data } = useQueryLabelCategories({
    variables: {
      filter: {
        locations: [toId(userLocation)],
      },
    },
    pollInterval: 30000,
  })
  const { id, type } = useParams()
  const location = useLocation()

  const localCategories = data?.labelCategories.filter((cat) => cat.locations.length > 0)
  const globalCategories = data?.labelCategories.filter((cat) => cat.locations.length === 0)

  // Lists the categories in this organisation
  const categoriesNav = data?.labelCategories && (
    <List>
      <SubNavigationListItem text="All" to="/labels" active={location.pathname === "/labels"} />

      {localCategories?.length > 0 && (
        <Box my={1}>
          <TextDivider />
        </Box>
      )}

      {localCategories?.map(({ id: catId, name }) => (
        <SubNavigationListItem key={catId} to={`/labels/category/${catId}`} text={name} active={id === catId} />
      ))}

      {localCategories?.length > 0 && (
        <Box my={1}>
          <TextDivider />
        </Box>
      )}

      {globalCategories?.map(({ id: catId, name }) => (
        <SubNavigationListItem key={catId} to={`/labels/category/${catId}`} text={name} active={id === catId} />
      ))}
    </List>
  )

  // Link through to label templates
  const libraryNav = data?.labelCategories && (
    <List>
      <SubNavigationListItem to="/labels/templates" active={type === "templates"} text={<Trans>All templates</Trans>} />
    </List>
  )

  // Show on left hand side in desktop views
  if (variant === "vertical") {
    return (
      <Hidden smDown>
        <SubNavigation title={<Trans>Categories</Trans>} loading={!categoriesNav}>
          {categoriesNav}
        </SubNavigation>
        {libraryNav && (
          <SubNavigation title={<Trans>Template library</Trans>} loading={!libraryNav}>
            {libraryNav}
          </SubNavigation>
        )}
      </Hidden>
    )
  }

  // Show as horizontal scroller on mobile and kiosk
  if (variant === "horizontal" && categoriesNav) {
    return (
      <Box display="flex" alignItems="center" className={classes.horizontalNavContainer} flexDirection="row" mb={1}>
        <List className={classes.horizontalNavItem}>
          <SubNavigationListItem
            variant="horizontal"
            text={<Trans>All</Trans>}
            to="/labels"
            active={location.pathname === "/labels"}
          />
          {localCategories?.map(({ id: catId, name }) => (
            <SubNavigationListItem
              variant="horizontal"
              to={`/labels/category/${catId}`}
              key={catId}
              text={name}
              value={catId}
              active={id === catId}
            />
          ))}
          {globalCategories?.map(({ id: catId, name }) => (
            <SubNavigationListItem
              variant="horizontal"
              to={`/labels/category/${catId}`}
              key={catId}
              text={name}
              value={catId}
              active={id === catId}
            />
          ))}
        </List>
      </Box>
    )
  }
  return null
}

export { LabelsSubNav }
