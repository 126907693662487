import { gql, useQuery, useSubscription } from "@apollo/client"

import moment from "moment"
import { DOWNLOAD_FIELDS } from "./fragments/downloadFieldsFragment"
import { toId } from "../../utils"
import { DOWNLOAD_STATUS } from "../enums"
import { useAuth } from "../../services"

export const DOWNLOADS_QUERY = gql`
  query Downloads($limit: Int, $offset: Int, $filter: DownloadFilterInput) {
    downloads {
      list(limit: $limit, offset: $offset) {
        ...DownloadFields
      }
      count
      countOfInProgress @client
    }
  }
  ${DOWNLOAD_FIELDS}
`

const DOWNLOAD_CREATED_SUBSCRIPTION = gql`
  subscription DownloadCreated {
    downloadCreated {
      ...DownloadFields
    }
  }
  ${DOWNLOAD_FIELDS}
`

const DOWNLOAD_UPDATED_SUBSCRIPTION = gql`
  subscription DownloadUpdated {
    downloadUpdated {
      ...DownloadFields
    }
  }
  ${DOWNLOAD_FIELDS}
`

export const downloadsTypePolicies = {
  scoped: {
    DownloadsQuery: {
      merge(prev, next) {
        return {
          ...(prev || {}),
          ...next,
        }
      },
      fields: {
        list: {
          keyArgs: ["limit", "offset"],
        },
        countOfInProgress: {
          read: (_, { cache, readField, variables }) => {
            // Get list of download refs
            const listRefs = readField({
              fieldName: "list",
              args: {
                ...variables,
              },
            })

            if (!listRefs) {
              return 0
            }

            // Read downloads
            const list = listRefs.map(({ __ref }) =>
              cache.readFragment({
                id: __ref,
                fragment: DOWNLOAD_FIELDS,
              }),
            )
            if (!list) {
              return 0
            }

            // Count in progress downloads
            return list.filter((item) => item.status === DOWNLOAD_STATUS.IN_PROGRESS && !item.isOverdue).length
          },
        },
      },
    },
    Download: {
      fields: {
        isOverdue: {
          read: (_, { readField }) => {
            const status = readField("status")
            const overdueAt = readField("overdueAt")
            const completedAt = readField("completedAt")

            if (!overdueAt) {
              return false
            }

            if (status !== DOWNLOAD_STATUS.IN_PROGRESS || completedAt) {
              return false
            }

            return moment(overdueAt).isBefore(moment())
          },
        },
      },
    },
  },
}

export const useQueryDownloads = (options = {}) => {
  const { hasPermission } = useAuth()
  const { variables, ...otherOptions } = options

  // Skip if user doesn't have required permission
  const skip = !hasPermission("download_read") || options?.skip

  const result = useQuery(DOWNLOADS_QUERY, {
    skip,
    variables: {
      ...(variables || {}),
    },
    ...(otherOptions || {}),
  })

  return {
    ...result,
    subscribe: skip
      ? () => []
      : () => [
          result.subscribeToMore({
            document: DOWNLOAD_CREATED_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }

              // Get new download
              const newDownload = subscriptionData.data.downloadCreated

              // Clone previous state
              const next = JSON.parse(JSON.stringify(prev || {}))

              // Add new download to list if not already present
              const download = next.downloads?.list.find((item) => toId(item) === toId(newDownload))
              if (!download) {
                next.downloads?.list.unshift(newDownload)
              }

              return next
            },
            onError: (error) => console.log("[useQueryDownloads][DOWNLOADS_CREATED_SUBSCRIPTION]", error),
          }),
          result.subscribeToMore({
            document: DOWNLOAD_UPDATED_SUBSCRIPTION,
            onError: (error) => console.log("[useQueryDownloads][DOWNLOAD_UPDATED_SUBSCRIPTION]", error),
          }),
        ],
  }
}

export const useSubscriptionDownloadUpdated = () => useSubscription(DOWNLOAD_UPDATED_SUBSCRIPTION)
