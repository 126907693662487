import React, { useState } from "react"
import { Typography, useTheme, useMediaQuery, Box, TextField, IconButton } from "@material-ui/core"
import { Menu as MenuIcon, FilterList } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { useAuth } from "../../services"
import { FlexBox, RowBox } from "../Boxes"
import { AdminOnlyLabel } from "./AdminOnlyLabel"

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: ({ noMargin, xs }) => (noMargin ? 0 : xs ? theme.spacing(1) : theme.spacing(2)),
  },
  title: {
    fontSize: ({ sm }) => (sm ? 20 : 24),
    fontWeight: "600",
    lineHeight: ({ sm }) => (sm ? "24px" : "28px"),
    "&&:hover": {
      backgroundColor: ({ canUpdate }) => (canUpdate ? theme.palette.action.hover : null),
      cursor: "text",
    },
  },
  titleWarning: {
    color: theme.palette.error.main,
  },
  adminOnly: {},
}))

const ModuleHeading = ({
  id,
  children,
  value,
  unconfirmed,
  className,
  noMargin,
  editable,
  editLabel,
  onEdited,
  adminOnly,
  subNav = false,
  onSubnav,
  filter = false,
  onFilter,
  rowBoxProps = {},
}) => {
  const theme = useTheme()
  const { hasPermission } = useAuth()
  const xs = useMediaQuery(theme.breakpoints.down("xs"))
  const sm = useMediaQuery(theme.breakpoints.down("sm"))
  const md = useMediaQuery(theme.breakpoints.down("md"))
  const canUpdate = editable && hasPermission(editable)
  const classes = useStyles({ noMargin, xs, sm, canUpdate })

  const [editValue, setEditValue] = useState(false)
  const [newValue, setNewValue] = useState("")

  const handleEdit = () => {
    if (!canUpdate) return
    setNewValue(value)
    setEditValue(true)
  }

  const handleValueChange = (event) => {
    setNewValue(event.target.value)
  }

  const handleSubNav = () => {
    onSubnav && onSubnav()
  }

  const handleFilter = () => {
    onFilter && onFilter()
  }

  const handleEdited = () => {
    onEdited && onEdited(newValue)
    setEditValue(false)
  }

  const handleEditKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEdited()
    }
  }

  return (
    <>
      {!editValue && (
        <RowBox className={classes.wrapper} {...rowBoxProps}>
          {subNav && md && (
            <IconButton onClick={handleSubNav} edge="start">
              <MenuIcon />
            </IconButton>
          )}
          <Box width="100%">
            <Typography
              id={id}
              variant="h2"
              className={`${classes.title} ${unconfirmed ? classes.titleWarning : ""} ${className}`}
              onClick={handleEdit}
            >
              {children || value}
            </Typography>
          </Box>
          {adminOnly && (
            <FlexBox ml="auto" className={classes.adminOnly} alignSelf="flex-end">
              <AdminOnlyLabel />
            </FlexBox>
          )}
          {filter && xs && (
            <FlexBox ml="auto" alignSelf="flex-end">
              <IconButton edge="end" title="Filters" onClick={handleFilter}>
                <FilterList />
              </IconButton>
            </FlexBox>
          )}
        </RowBox>
      )}
      {editValue && (
        <RowBox mb={1} flexGrow={1}>
          <TextField
            id={id}
            label={editLabel}
            variant="outlined"
            value={newValue}
            onChange={handleValueChange}
            fullWidth
            required
            autoFocus
            onBlur={handleEdited}
            onKeyDown={handleEditKeyDown}
          />
        </RowBox>
      )}
    </>
  )
}

export default ModuleHeading
