import { useMutation, gql } from "@apollo/client"

const UPDATE_TABLET_PIN_MUTATION = gql`
  mutation UpdateTabletPin($input: UpdateTabletPinInput!) {
    me {
      updateTabletPin(input: $input)
    }
  }
`

const useMutationUpdateTabletPin = () =>
  useMutation(UPDATE_TABLET_PIN_MUTATION, {
    errorPolicy: "all",
  })

export { useMutationUpdateTabletPin }
