import React, { useState } from "react"
import useMeasure from "react-use-measure"
import {
  makeStyles,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Collapse,
} from "@material-ui/core"
import { KeyboardArrowDown } from "@material-ui/icons"
import { t } from "@lingui/macro"
import { Icon } from "../Icon"
import { useAuth } from "../../services"

const useStyles = makeStyles((theme) => ({
  popover: {
    left: ({ mobile }) => (mobile ? "24px !important" : "auto"),
    width: ({ mobile }) => (mobile ? "calc(100vw - 48px)" : 300),
    // Transform the top a bit to make it look better. Changed to use margin as transforms seem to interfere with the popover animation
    marginTop: ({ mobile }) => (mobile ? theme.spacing(-2) : null),
    marginLeft: ({ mobile }) => (mobile ? 0 : theme.spacing(1)),
    borderRadius: theme.spacing(2),
  },
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(8, 8, 33, 0.6)",
    zIndex: 1300,
    cursor: "pointer",
    transition: "0.2s all",
  },
  menuList: {
    padding: theme.spacing(2),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menuItemIcon: {
    width: 24,
    minWidth: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  menuItemText: {
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  viewMoreButton: {
    display: "flex",
    fontWeight: 600,
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  viewMoreIcon: {
    transform: ({ showMore }) => (showMore ? "rotate(180deg)" : null),
    transformOrigin: "center",
    transition: "0.2s all",
  },
}))

export const NewActionMenu = ({ anchorEl, open, onClose, onItemClick, mobile, pinItems }) => {
  const [showMore, setShowMore] = useState(false)
  const { permissionGroups, hasFeature, hasPermission } = useAuth()

  // const today = moment().format("YYYY-MM-DD")
  const [popperRef] = useMeasure()

  const MENU_ITEMS = [
    {
      id: "adhoc-job",
      icon: "adhoc-dark",
      text: "Run an ad hoc job",
      href: "/dashboard/adhoc-job/new",
      requires: permissionGroups.createJobs,
    },
    {
      id: "action",
      icon: "actions-dark",
      text: "Raise an action",
      href: "/hub/actions/unresolved/new",
      requires: permissionGroups.createActions,
    },
    {
      id: "article",
      icon: "knowledge-dark",
      text: "Create a new article",
      href: "/knowledge/article/new",
      requires: permissionGroups.createKnowledge,
    },
    {
      id: "post",
      icon: "hub-dark",
      text: "Create a new post",
      href: "/hub/all/new",
      requires: permissionGroups.createPost,
    },
    {
      id: "label",
      icon: "labels-dark",
      text: "Create a new label",
      href: "/labels/item/new",
      requires: permissionGroups.createLabels,
      feature: "labels",
    },
    {
      id: "job-template",
      icon: "templates-dark",
      text: "Create a new job template",
      href: "/templates/active/new/audit",
      requires: permissionGroups.createAudits,
    },
    {
      id: "asset",
      icon: "assets-dark",
      text: "Create a new asset",
      href: "/assets/new",
      requires: permissionGroups.createAssets,
      feature: "assets",
    },
    {
      id: "supplier",
      icon: "suppliers-dark",
      text: "Create a new supplier",
      href: "/suppliers/new",
      requires: permissionGroups.createSuppliers,
      feature: "suppliers",
    },
    {
      id: "food-item",
      icon: "food-items-dark",
      text: "Create a new food item",
      href: "/food-items/new",
      requires: permissionGroups.createFoodItems,
      feature: "food",
    },
    // {
    //   id: "prep-list",
    //   icon: "prep-dark",
    //   text: "Create a prep list",
    //   href: `/prep/list/${today}`,
    //   requires: permissionGroups.createPrep,
    //   feature: featureDependencies.prep,
    // },
    // Disabled as not currently working, need to set up a route-based dialog or have some other way of opening it
  ]

  const classes = useStyles({ mobile, showMore })

  const handleMenuItemClick = (href) => {
    onItemClick?.(href)
    onClose()
  }

  const toggleShowMore = (e) => {
    e.stopPropagation()
    setShowMore(!showMore)
  }

  const renderMenuItem = (item) => {
    if (item.requires && !hasPermission(item.requires)) return null
    if (item.feature && !hasFeature(item.feature)) return null

    return (
      <ListItem key={item.id} button onClick={() => handleMenuItemClick(item.href)} className={classes.menuItem}>
        <ListItemIcon className={classes.menuItemIcon}>
          <Icon name={item.icon} />
        </ListItemIcon>
        <ListItemText primary={t`${item.text}`} className={classes.menuItemText} />
      </ListItem>
    )
  }

  return (
    <>
      {mobile && open && (
        <Box
          role="presentation"
          className={classes.backdrop}
          onClick={onClose}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setShowMore(false)
              onClose()
            }
          }}
          tabIndex={0}
        />
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setShowMore(false)
          onClose()
        }}
        anchorOrigin={{
          vertical: mobile ? "top" : "top",
          horizontal: mobile ? "center" : "right",
        }}
        transformOrigin={{
          vertical: mobile ? "bottom" : "top",
          horizontal: mobile ? "center" : "left",
        }}
        classes={{
          paper: classes.popover,
        }}
      >
        <List className={classes.menuList} ref={popperRef}>
          {mobile ? (
            <>
              {/* Main items always visible */}
              {MENU_ITEMS.filter((item) => pinItems?.includes(item.id)).map(renderMenuItem)}

              {/* Divider and View More button */}
              <Divider className={classes.divider} />

              <ListItem button onClick={toggleShowMore} className={classes.menuItem}>
                <ListItemText
                  primary={
                    <div className={classes.viewMoreButton}>
                      <span>{showMore ? t`View less` : t`View more`}</span>
                      <KeyboardArrowDown className={classes.viewMoreIcon} />
                    </div>
                  }
                />
              </ListItem>

              {/* Additional items shown when View More is clicked */}
              <Collapse in={showMore}>
                {MENU_ITEMS.filter((item) => !pinItems?.includes(item.id)).map(renderMenuItem)}
              </Collapse>
            </>
          ) : (
            // Desktop view - show all items
            MENU_ITEMS.map(renderMenuItem)
          )}
        </List>
      </Popover>
    </>
  )
}
