import React from "react"
import { makeStyles } from "@material-ui/styles"
import { Chip, Box, CircularProgress } from "@material-ui/core"
import { Trans } from "@lingui/macro"
import { Icon } from ".."
import { PROCESS_TYPE, JOB_STATUS } from "../../data"
import { getJobStatus } from "../../utils/useJobUtils"

const useStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: ({ todo }) => (todo ? theme.spacing(1.5) : theme.spacing(0.5)),
    paddingRight: ({ todo }) => (todo ? theme.spacing(1.5) : theme.spacing(1.5)),
  },
  statusIconFrame: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
  },
  statusIcon: {
    width: 18,
    height: 18,
  },
  inProgressRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.info.main,
  },
  awaitingSubmitRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.scheduled.main,
  },
  submittedRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.success.main,
  },
  overdueRoot: {
    color: theme.palette.text.white,
    backgroundColor: theme.palette.error.main,
  },
  labelFrame: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  progressFrame: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  circularProgressTop: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    position: "absolute",
  },
  circularProgressBottom: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    opacity: 0.4,
  },
  circularProgressCompleted: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    opacity: 1,
  },
  circularProgressPercent: {
    fontSize: 11,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: 24,
    height: 24,
  },
}))

const StatusProgress = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress
          size={24}
          variant="determinate"
          value={job.percentComplete}
          className={classes.circularProgressTop}
        />
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressBottom} />
        <Box className={classes.circularProgressPercent}>{job.percentComplete}</Box>
      </Box>
      <Trans>In progress</Trans>
    </Box>
  )
}

const StatusAwaitingSubmission = () => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressCompleted} />
        <Box className={classes.statusIconFrame}>
          <Icon className={classes.statusIcon} name="up" />
        </Box>
      </Box>
      <Trans>Awaiting submission</Trans>
    </Box>
  )
}

const StatusSubmitted = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressCompleted} />
        <Box className={classes.statusIconFrame}>
          <Icon className={classes.statusIcon} name="checkbox" />
        </Box>
      </Box>
      {job.type === PROCESS_TYPE.AUDIT ? <Trans>Submitted</Trans> : <Trans>Completed</Trans>}
    </Box>
  )
}

const StatusOverdue = ({ job }) => {
  const classes = useStyles()

  return (
    <Box className={classes.labelFrame}>
      <Box className={classes.progressFrame}>
        {job.percentComplete !== 0 && (
          <>
            <CircularProgress
              size={24}
              variant="determinate"
              value={job.percentComplete}
              className={classes.circularProgressTop}
            />
            <CircularProgress size={24} variant="determinate" value={100} className={classes.circularProgressBottom} />
            <Box className={classes.circularProgressPercent}>{job.percentComplete}</Box>
          </>
        )}
        {job.percentComplete === 0 && (
          <>
            <CircularProgress
              size={24}
              variant="determinate"
              value={100}
              className={classes.circularProgressCompleted}
            />
            <Box className={classes.statusIconFrame}>
              <Icon className={classes.statusIcon} name="overdue" />
            </Box>
          </>
        )}
      </Box>
      {job.percentComplete === 0 ? <Trans>Overdue</Trans> : <Trans>In progress</Trans>}
    </Box>
  )
}

const AuditStatusChip = ({ job, ...rest }) => {
  const classes = useStyles({
    todo: getJobStatus(job) === JOB_STATUS.TODO,
  })

  const status = getJobStatus(job)

  const statusConfig = {
    [JOB_STATUS.SUBMITTED]: {
      root: classes.submittedRoot,
      label: <StatusSubmitted job={job} />,
    },
    [JOB_STATUS.AWAITING_SUBMISSION]: {
      root: classes.awaitingSubmitRoot,
      label: <StatusAwaitingSubmission />,
    },
    [JOB_STATUS.COMPLETED]: {
      root: classes.submittedRoot,
      label: <StatusSubmitted job={job} />,
    },
    [JOB_STATUS.OVERDUE]: {
      root: classes.overdueRoot,
      label: <StatusOverdue job={job} />,
    },
    [JOB_STATUS.IN_PROGRESS]: {
      root: classes.inProgressRoot,
      label: <StatusProgress job={job} />,
    },
    [JOB_STATUS.TODO]: {
      label: <Trans context="Yet to be started">To do</Trans>,
    },
  }

  const { root, label } = statusConfig[status] || statusConfig[JOB_STATUS.TODO]

  return <Chip size="medium" classes={{ root, label: classes.label }} label={label} {...rest} />
}

export { AuditStatusChip }
